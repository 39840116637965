import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import React, { FC } from 'react';
import { object, string } from 'yup';
import { Textarea } from 'visits-style';

const StyledTextarea = styled(Textarea)`
  width: 90%;
  height: 160px;

  textarea {
    line-height: 1.5;
    resize: none;

    &:focus {
      border-color: midnightblue;
      box-shadow: none;
    }
  }
`;

const MailTemplate = styled.div`
  width: 90%;
  margin: 12px 0;
  color: #808080;
`;

type SendBackModalProps = {
  show: boolean;
  onCancel: () => void;
  onOk: (reason: string) => void;
};

const SendBackModal: FC<SendBackModalProps> = props => {
  const { show, onCancel, onOk } = props;
  const { values, isValid, dirty, handleChange, handleBlur } = useFormik<{
    reason: string;
  }>({
    initialValues: { reason: '' },
    validationSchema: object({ reason: string().required() }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="sendback-modal">
        差し戻し理由を入力してください。
        <br />
        入力した内容は以下のテンプレートでメール通知されます 。
        {/* TODO(miyabl): メールのテンプレートを表示する */}
        <MailTemplate>{'.....'}</MailTemplate>
        <StyledTextarea
          name="reason"
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="差し戻し理由"
        />
        <MailTemplate>{'.....'}</MailTemplate>
      </ModalContent>
      <ModalFooter>
        <ModalButton buttonTheme="cancel" onClick={onCancel}>
          キャンセル
        </ModalButton>
        <ModalButton
          disabled={!isValid || !dirty}
          onClick={() => onOk(values.reason)}
        >
          OK
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default SendBackModal;
