import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Textarea } from 'visits-style';
import { Button, GridArea } from 'components/atoms';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { getAdditionalStatusInfo } from 'utils/models/humanResourceMatter';
import { HumanResourceMatterStatus } from '..';

import useSaveMemo from './useSaveMemo';

const Layout = styled.div`
  display: grid;
  grid-template:
    '...  ....  ...'
    '...  head  ...' auto
    '...  ....  ...'
    '...  body  ...' 1fr
    '...  ....  ...'
    / 0 1fr 0;
  height: 100%;
`;

const Head = styled.div`
  grid-area: head;
  padding: 10px;
  background: midnightblue;
  color: white;
  font-size: 0.8rem;
`;

const Body = styled.div`
  display: grid;
  grid-area: body;
  grid-template:
    '...  ........... ...' 20px
    '...  status      ...' auto
    '...  ........... ...' 24px
    '...  staff-label ...' auto
    '...  ........... ...' 4px
    '...  staff-value ...' auto
    '...  ........... ...' 24px
    '...  memo-label  ...' auto
    '...  ........... ...' 4px
    '...  memo-form   ...' 1fr
    '...  ........... ...' 20px
    / 20px auto 20px;
`;

const Label = styled(GridArea)`
  color: #9698a4;
`;

const StatusArea = styled(GridArea)`
  display: flex;
  flex-direction: column;
`;

const StatusInfo = styled.div`
  margin-top: 4px;
`;

const StyledForm = styled.form`
  display: grid;
  grid-template:
    '...  ......  ...'
    '...  field   ...' 1fr
    '...  ......  ...' 12px
    '...  button  ...' auto
    '...  error   ...' auto
    '...  ......  ...'
    / 0 1fr 0;
  height: 100%;
`;

const MemoField = styled(Textarea)`
  grid-area: field;
  height: 100%;

  textarea {
    min-height: 228px;
    line-height: 1.5;

    &:focus {
      border-color: midnightblue;
      box-shadow: none;
    }
  }
`;

const SubmitMemoButton = styled(Button)`
  grid-area: button;
  justify-self: center;
  width: fit-content;
  padding: 8px 24px;
  font-size: 0.8rem;
`;

const ErrorMessage = styled.div`
  grid-area: error;
  margin-top: 12px;
  color: red;
  font-size: 0.8rem;
  text-align: center;
`;

type HumanResourceMatterManagementInfoProps = {
  matter: HumanResourceMatterForConsultant;
  applyLatest: (latest: HumanResourceMatterForConsultant) => void;
};

const HumanResourceMatterManagementInfo: FC<HumanResourceMatterManagementInfoProps> =
  ({ matter, applyLatest }) => {
    const statusInfo = getAdditionalStatusInfo(matter);
    const {
      formikContext: {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        dirty,
      },
      error,
    } = useSaveMemo(matter, applyLatest);

    return (
      <Layout>
        <Head>管理情報</Head>
        <Body>
          <Label area={'staff-label'}>担当者</Label>
          <Label area={'memo-label'}>担当者メモ</Label>
          <StatusArea area={'status'} data-testid="detail-status">
            <HumanResourceMatterStatus matter={matter} />
            {statusInfo.map(s => (
              <StatusInfo key={s}>{s}</StatusInfo>
            ))}
          </StatusArea>
          <GridArea area={'staff-value'}>{matter.staffEmail || '-'}</GridArea>
          <GridArea area={'memo-form'}>
            <StyledForm onSubmit={handleSubmit}>
              <MemoField
                name="memo"
                value={values.memo}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="担当者メモ"
                maxLength={1200}
              />
              <SubmitMemoButton type="submit" disabled={!isValid || !dirty}>
                メモを保存
              </SubmitMemoButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </StyledForm>
          </GridArea>
        </Body>
      </Layout>
    );
  };

export default HumanResourceMatterManagementInfo;
