import React, { FC, useMemo, useCallback } from 'react';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import styled from 'styled-components/macro';
import { getAdditionalStatusInfo } from 'utils/models/humanResourceMatter';
import { EmploymentTypeTag } from 'components/molecules';
import { IoIosStarOutline, IoIosStar } from 'react-icons/io';
import usePutFavorite from './usePutFavorite';
import { ErrorModal } from '../Modals';

const Head = styled.div`
  display: grid;
  grid-template:
    '...  ......  ... ......  ... ...... ...  ..... ...' 10px
    '...  status  ... id      ... info   ...  fav   ...' 1fr
    '...  ......  ... ......  ... ...... ...  ..... ...' 10px
    / 20px auto 12px auto 8px 1fr 4px auto 20px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background: #97a0b0;
  color: white;
`;

const HeaderInfo = styled.div`
  grid-area: info;
  align-self: center;
  justify-self: end;
`;

const Info = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

const FavoriteIcon = styled.div`
  display: flex;
  grid-area: fav;
  width: 28px;
  height: 28px;
  transition: background-color 0.2s ease;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: #7b8db0;
  }
`;

const StyledStarLine = styled(IoIosStarOutline)`
  margin: auto;
  fill: white;
`;

const StyledStarFill = styled(IoIosStar)`
  margin: auto;
  fill: #1968e5;
`;

const GridArea = styled.div<{ name: string }>`
  grid-area: ${({ name }) => name};
`;

type HumanResourceMatterHeadProps = {
  matter: HumanResourceMatterForConsultant;
  applyLatest: (latest: HumanResourceMatterForConsultant) => void;
  showAdditionInfo: boolean;
};

const HumanResourceMatterHead: FC<HumanResourceMatterHeadProps> = ({
  matter,
  applyLatest,
  showAdditionInfo,
}) => {
  const { profile } = matter;
  const info = useMemo(() => getAdditionalStatusInfo(matter), [matter]);
  const { error, putFavorite } = usePutFavorite(matter, applyLatest);

  const handleClickFavoriteIcon = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      putFavorite(!matter.favorite);
      e.stopPropagation();
    },
    [matter.favorite, putFavorite],
  );

  const innovatorId = profile?.innovatorId;

  return (
    <>
      <Head>
        <GridArea name="status">
          <EmploymentTypeTag matter={matter} />
        </GridArea>
        <GridArea name="id">{`会員番号: ${innovatorId || '-'}`}</GridArea>
        <HeaderInfo>
          {showAdditionInfo && info.map(i => <Info key={i}>{i}</Info>)}
        </HeaderInfo>
        <FavoriteIcon onClick={handleClickFavoriteIcon}>
          {matter.favorite ? (
            <StyledStarFill size={18} data-testid="favorite-icon-on" />
          ) : (
            <StyledStarLine size={18} data-testid="favorite-icon-off" />
          )}
        </FavoriteIcon>
      </Head>
      <ErrorModal error={error} />
    </>
  );
};

export default HumanResourceMatterHead;
