import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Contents = styled.section`
  width: 100%;
  max-width: 470px;
  margin: 50px auto;
  padding: ${theme.headerHeight + 60}px 20px 0 20px;
`;

const Container = styled.section`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const PublicRouteLayout: FC<{}> = ({ children }) => (
  <Layout>
    <Container>
      <Contents>{children}</Contents>
    </Container>
  </Layout>
);

export default PublicRouteLayout;
