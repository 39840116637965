import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { GridArea } from 'components/atoms';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import {
  CorporateAttribute,
  Fund,
  MemberRange,
  MissionCategory,
  Location,
} from 'proto/v1/apimodel/apimodel';

const Layout = styled.div`
  display: grid;
  grid-template:
    '...  ....  ...'
    '...  head  ...' auto
    '...  ....  ...'
    '...  body  ...' 1fr
    '...  ....  ...'
    / 0 1fr 0;
  height: 100%;
`;

const Head = styled.div`
  grid-area: head;
  padding: 10px;
  background: midnightblue;
  color: white;
  font-size: 0.8rem;
`;

const Body = styled.div`
  display: grid;
  grid-area: body;
  grid-template:
    '...  .......................... ...' 24px
    '...  corporate-attributes-label ...' auto
    '...  .......................... ...' 4px
    '...  corporate-attributes-value ...' auto
    '...  .......................... ...' 8px
    '...  categories-label           ...' auto
    '...  .......................... ...' 4px
    '...  categories-value           ...' auto
    '...  .......................... ...' 8px
    '...  locations-label            ...' auto
    '...  .......................... ...' 4px
    '...  locations-value            ...' auto
    '...  .......................... ...' 8px
    '...  member-ranges-label        ...' auto
    '...  .......................... ...' 4px
    '...  member-ranges-value        ...' auto
    '...  .......................... ...' 8px
    '...  funds-label                ...' auto
    '...  .......................... ...' 4px
    '...  funds-value                ...' auto
    '...  .......................... ...' 20px
    / 20px auto 20px;
`;

const Label = styled(GridArea)`
  color: #9698a4;
`;

const NO_VALUE = '-';

const formatCorporateAttributes = (
  corporateAttributes: CorporateAttribute[],
) => {
  if (!corporateAttributes.length) return NO_VALUE;
  return corporateAttributes
    .map(corporateAttribute => corporateAttribute.name)
    .join('、');
};

const formatCategories = (categories: MissionCategory[]) => {
  if (!categories.length) return NO_VALUE;
  return categories.map(category => category.name).join('、');
};

const formatMemberRanges = (memberRanges: MemberRange[]) => {
  if (!memberRanges.length) return NO_VALUE;
  return memberRanges.map(memberRange => memberRange.displayText).join('、');
};

const formatLocations = (locations: Location[]) => {
  if (!locations.length) return NO_VALUE;
  return locations
    .map(location =>
      location.japanPrefecture ? location.japanPrefecture.name : '海外',
    )
    .join('、');
};

const formatFunds = (funds: Fund[]) => {
  if (!funds.length) return NO_VALUE;
  return funds.map(fund => fund.displayText).join('、');
};

type HumanResourceMatterPublishSettingProps = {
  matter: HumanResourceMatterForConsultant;
};

const HumanResourceMatterPublishSetting: FC<HumanResourceMatterPublishSettingProps> =
  ({ matter }) => {
    return (
      <Layout data-testid="publish-setting">
        <Head>公開先設定</Head>
        <Body>
          <Label area="corporate-attributes-label">企業属性</Label>
          <GridArea
            area="corporate-attributes-value"
            data-testid="publish-setting-corporate-attributes"
          >
            {formatCorporateAttributes(matter.corporateAttributes)}
          </GridArea>
          <Label area="categories-label">事業領域</Label>
          <GridArea
            area="categories-value"
            data-testid="publish-setting-categories"
          >
            {formatCategories(matter.categories)}
          </GridArea>
          <Label area="locations-label">所在地</Label>
          <GridArea
            area="locations-value"
            data-testid="publish-setting-locations"
          >
            {formatLocations(matter.locations)}
          </GridArea>
          <Label area="member-ranges-label">従業員規模</Label>
          <GridArea
            area="member-ranges-value"
            data-testid="publish-setting-member-ranges"
          >
            {formatMemberRanges(matter.memberRanges)}
          </GridArea>
          <Label area="funds-label">資金調達額</Label>
          <GridArea area="funds-value" data-testid="publish-setting-funds">
            {formatFunds(matter.funds)}
          </GridArea>
        </Body>
      </Layout>
    );
  };

export default HumanResourceMatterPublishSetting;
