import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { formatFileSize, formatChatTimestamp } from 'utils/models/chat';
import { MessageDocument } from 'assets/svg';
import { ImArrowUpLeft } from 'react-icons/im';
import { SendingChatMessage as SendingChatMessageType } from 'types/chat';
import { LinkText } from 'components/atoms';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-left: auto;
`;

const Message = styled.div`
  box-sizing: border-box;
  max-width: 448px;
  margin-bottom: 8px;
  padding: 16px 12px;
  border: 0.5px solid #c4c4c4;
  border-radius: 15px 0 15px 15px;
  background: red;
  background: #ebebeb;
  font-size: 13px;
  line-height: 1.8;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const FileIcon = styled(MessageDocument)`
  margin-right: 12px;
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div``;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileName} {
      text-decoration: underline;
    }
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileSize} {
      text-decoration: underline;
    }
  }
`;

const SentAt = styled.div`
  color: #666;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
`;

const MessageBottom = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

const SendingIcon = styled(ImArrowUpLeft).attrs({ fill: '#666', size: 10 })`
  position: absolute;
  top: 50%;
  right: 104px;
  transform: translateY(-50%);
`;

type Props = {
  sendingChatMessage: SendingChatMessageType;
};

const renderMessage = (chatMessage: SendingChatMessageType) => {
  if (chatMessage.file) {
    return (
      <div>
        <FileMessage>
          <FileIcon />
          <div>
            <FileName>{chatMessage.message}</FileName>
            <FileSize>
              ファイルサイズ: {formatFileSize(chatMessage.file.size)}
            </FileSize>
          </div>
        </FileMessage>
        <MessageBottom>
          <SendingIcon />
          <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
        </MessageBottom>
      </div>
    );
  }

  return (
    <>
      <div>
        <Message>
          <LinkText>{chatMessage.message}</LinkText>
        </Message>
        <MessageBottom>
          <SendingIcon />
          <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
        </MessageBottom>
      </div>
    </>
  );
};

const SendingChatMessage: FC<Props> = props => {
  return (
    <>
      <Wrapper data-testid="sending-chat-message">
        {renderMessage(props.sendingChatMessage)}
      </Wrapper>
    </>
  );
};

export default SendingChatMessage;
