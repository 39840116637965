import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Spinner } from 'visits-style';
import theme from 'styles/theme';
import { formatDate } from 'utils/time';
import {
  // eslint-disable-next-line camelcase
  GetMatterContactedAccountsResponse_ContactedAccount as ContactedAccount,
} from 'proto/v1/consultantservice/consultantservice';
import { ErrorMessage, Button, GridArea } from 'components/atoms';

const Layout = styled.div`
  display: grid;
  grid-template:
    '...  ....  ...'
    '...  head  ...' auto
    '...  ....  ...'
    '...  body  ...' 1fr
    '...  ....  ...'
    / 0 1fr 0;
  height: 100%;
`;

const Head = styled.div`
  grid-area: head;
  padding: 10px;
  background: midnightblue;
  color: white;
  font-size: 0.8rem;
`;

const Body = styled.div`
  display: grid;
  grid-area: body;
`;

const ContactedAccountItems = styled.div`
  max-height: 514px;
  overflow-y: auto;
`;

const ContactedAccountItem = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-template:
    '...  .................. ..................  ...' 16px
    '...  name-label         name-value          ...'
    '...  .................. ..................  ...' 8px
    '...  company-label      company-value       ...'
    '...  .................. ..................  ...' 8px
    '...  contacted-at-label contacted-at-value  ...'
    '...  .................. ..................  ...' 16px
    / 12px 148px 1fr 12px;

  &:not(:first-child) {
    border-top: 1px solid ${theme.border};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const Label = styled(GridArea)`
  color: #9698a4;
`;

const NoContactedAccount = styled.p`
  padding: 16px 12px;
  font-size: 13px;
`;

const FetchNextError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
`;

const ReloadButton = styled(Button)`
  width: 100px;
  padding: 8px;
  font-size: 12px;
`;

const FetchNextErrorMessage = styled(ErrorMessage)`
  margin: 0;
  text-align: center;
`;

type HumanResourceMatterContactedAccountsProps = {
  contactedAccounts: ContactedAccount[] | null;
  isFetchingNextItems: boolean;
  error: string | undefined;
  onRetryNextFetch: () => void;
  listContainerRef: React.RefObject<HTMLDivElement>;
  onScroll: () => void;
};

const HumanResourceMatterContactedAccounts: FC<HumanResourceMatterContactedAccountsProps> =
  ({
    contactedAccounts,
    isFetchingNextItems,
    error,
    onRetryNextFetch,
    listContainerRef,
    onScroll,
  }) => {
    return (
      <Layout data-testid="contacted-accounts">
        <Head>コンタクトされたミッション</Head>
        <Body>
          {contactedAccounts && contactedAccounts.length > 0 ? (
            <ContactedAccountItems onScroll={onScroll} ref={listContainerRef}>
              {contactedAccounts.map((contactedAccount, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ContactedAccountItem key={index}>
                  <Label area="name-label">ミッション名</Label>
                  <GridArea
                    area="name-value"
                    data-testid="contacted-accounts-name"
                  >
                    {contactedAccount.mission?.name}
                  </GridArea>
                  <Label area="company-label">企業名</Label>
                  <GridArea
                    area="company-value"
                    data-testid="contacted-accounts-company"
                  >
                    {contactedAccount.mission?.companyName}
                  </GridArea>
                  <Label area="contacted-at-label">コンタクト日時</Label>
                  <GridArea
                    area="contacted-at-value"
                    data-testid="contacted-accounts-contacted-at"
                  >
                    {formatDate(
                      new Date(contactedAccount.contactedAt),
                      'yyyy/MM/dd HH:mm',
                    )}
                  </GridArea>
                </ContactedAccountItem>
              ))}

              {isFetchingNextItems && (
                <SpinnerContainer>
                  <Spinner size="32px" />
                </SpinnerContainer>
              )}
              {error && (
                <FetchNextError>
                  <FetchNextErrorMessage>{error}</FetchNextErrorMessage>
                  <ReloadButton color="primary" onClick={onRetryNextFetch}>
                    再読み込み
                  </ReloadButton>
                </FetchNextError>
              )}
            </ContactedAccountItems>
          ) : (
            <NoContactedAccount>
              コンタクトされたミッションはありません。
            </NoContactedAccount>
          )}
        </Body>
      </Layout>
    );
  };

export default HumanResourceMatterContactedAccounts;
