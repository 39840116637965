export const USER_NOT_FOUND_ERROR =
  'メールアドレスまたはパスワードが正しくありません';
export const INVALID_EMAIL_ERROR = '不正なメールアドレスの形式です。';
export const EMAIL_REQUIRED_ERROR = 'メールアドレスを入力してください';
export const PASSWORD_REQUIRED_ERROR = 'パスワードを入力してください';
export const UNKNOWN_ERROR =
  '問題が発生しています。運営事務局へお問い合わせください。';
export const EXPIRED_URL = 'URLの有効期間が終了しています';
export const WEAK_PASSWORD =
  '半角英数字8文字以上でパスワードを設定してください';
export const TIMEOUT_ERROR =
  'タイムアウトが発生しました。\n時間をおいて再度操作を実行してください。';
export const NETWORK_ERROR =
  'ネットワーク接続に失敗しました。\nネットワーク環境を確認し再度お試しください。';
