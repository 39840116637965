import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import {
  formatBirth,
  formatAcademicBackground,
  formatAddress,
  formatCertifications,
  formatName,
  formatPhoneNumber,
  formatPreferredOccupations,
  formatNewGraduatePreferredBusinessTypes,
  formatNewGraduatePastExperienceAchievements,
  formatWorkLocations,
  formatPeriods,
  formatDtt,
} from 'utils/models/humanResourceMatter';
import HumanResourceMatterProfileItem from './HumanResourceMatterProfileItem';

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
`;

const Output = styled.li`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListItem = styled.div`
  margin-bottom: 4px;
`;

const ListItemName = styled.p``;

const ListItemContent = styled.p`
  word-break: break-all;
`;

const Periods = styled.span`
  margin-left: 8px;
`;

const SelfIntroduction = styled.p`
  word-break: break-all;
`;

type HumanResourceMatterProfileProps = {
  matter: HumanResourceMatterForConsultant;
};

const HumanResourceMatterProfile: FC<HumanResourceMatterProfileProps> = ({
  matter: { profile, innovatorEmail },
}) => {
  return (
    <Profile>
      <HumanResourceMatterProfileItem
        label={'氏名'}
        value={formatName(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'所属大学'}
        value={formatAcademicBackground(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'生年月'}
        value={formatBirth(profile)}
      />
      <HumanResourceMatterProfileItem label={'Email'} value={innovatorEmail} />
      <HumanResourceMatterProfileItem
        label={'携帯電話番号'}
        value={formatPhoneNumber(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'自己PR'}
        value={profile?.selfIntroduction}
        renderItem={selfIntroduction => (
          <SelfIntroduction>{selfIntroduction}</SelfIntroduction>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'希望する働き方'}
        value={profile?.newGraduate?.preferredWorkStyle?.name}
      />
      <HumanResourceMatterProfileItem
        label={'希望勤務地'}
        value={formatWorkLocations(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'志望業種'}
        value={formatNewGraduatePreferredBusinessTypes(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'志望職種'}
        value={formatPreferredOccupations(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'これまでの経験・実績'}
        value={formatNewGraduatePastExperienceAchievements(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'インターン経験'}
        value={profile?.newGraduate?.internshipExperiences}
        renderItem={internshipExperiences => (
          <>
            {internshipExperiences.map((internshipExperience, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>
                {internshipExperience.companyName}
                <Periods>
                  {formatPeriods({
                    startYear: internshipExperience.startYear,
                    startMonth: internshipExperience.startMonth,
                    endYear: internshipExperience.endYear,
                    endMonth: internshipExperience.endMonth,
                  })}
                </Periods>
              </ListItem>
            ))}
          </>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'留学経験'}
        value={profile?.newGraduate?.studyAbroadExperiences}
        renderItem={studyAbroadExperiences => (
          <>
            {studyAbroadExperiences.map((studyAbroadExperience, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>
                {studyAbroadExperience.countryName}
                <Periods>
                  {formatPeriods({
                    startYear: studyAbroadExperience.startYear,
                    startMonth: studyAbroadExperience.startMonth,
                    endYear: studyAbroadExperience.endYear,
                    endMonth: studyAbroadExperience.endMonth,
                  })}
                </Periods>
              </ListItem>
            ))}
          </>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'クラブ・\nサークル経験'}
        value={profile?.newGraduate?.clubExperiences}
        renderItem={clubExperiences => (
          <>
            {clubExperiences.map((clubExperience, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>
                <ListItemName>{clubExperience.name}</ListItemName>
                <ListItemContent>{clubExperience.content}</ListItemContent>
              </ListItem>
            ))}
          </>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'ゼミ・研究'}
        value={profile?.newGraduate?.researchRooms}
        renderItem={researchRooms => (
          <>
            {researchRooms.map((researchRoom, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>
                <ListItemName>{researchRoom.name}</ListItemName>
                <ListItemContent>{researchRoom.content}</ListItemContent>
              </ListItem>
            ))}
          </>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'英語力'}
        value={profile?.englishSkill?.name}
      />
      <HumanResourceMatterProfileItem
        label={'保有資格'}
        value={formatCertifications(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'デザイン思考テスト'}
        value={formatDtt(profile)}
      />
      <HumanResourceMatterProfileItem
        label={'プログラミング経験'}
        value={profile?.newGraduate?.programmingExperiences}
        renderItem={programmingExperiences => (
          <div>
            {programmingExperiences.map((programmingExperience, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                {`${programmingExperience.languageName} / ${programmingExperience.level?.name}`}
              </div>
            ))}
          </div>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'アウトプット'}
        value={profile?.outputs}
        style={{ overflow: 'hidden' }}
        renderItem={outputs => (
          <ul>
            {outputs.map((output, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Output key={index}>
                <a href={output} target="_blank" rel="noopener noreferrer">
                  {output}
                </a>
              </Output>
            ))}
          </ul>
        )}
      />
      <HumanResourceMatterProfileItem
        label={'現住所'}
        value={formatAddress(profile)}
      />
    </Profile>
  );
};

export default HumanResourceMatterProfile;
