import React, { FC } from 'react';
import { ConsultantChatMessage } from 'proto/v1/apimodel/apimodel';
import styled from 'styled-components/macro';
import { formatChatTimestamp, formatFileSize } from 'utils/models/chat';
import { LinkText } from 'components/atoms';
import { MessageDocument, MessageDownload } from 'assets/svg';

const Wrapper = styled.div`
  width: fit-content;
  max-width: 448px;
  margin-bottom: 16px;
`;

const Message = styled.div`
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 16px 12px;
  border: 0.5px solid #c4c4c4;
  border-radius: 0 15px 15px 15px;
  background: #fff;
  color: #000;
  font-size: 13px;
  line-height: 1.8;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const FileIcon = styled(MessageDocument)`
  margin-right: 12px;
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div``;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;

  &:hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileName} {
      text-decoration: underline;
    }
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileSize} {
      text-decoration: underline;
    }
  }
`;

const DownloadIcon = styled(MessageDownload)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const DeletedMessage = styled.div`
  width: fit-content;
  margin-right: auto;
  padding: 3px 16px;
  border-radius: 12px;
  background-color: #dedede;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
`;

const SentAt = styled.div`
  color: #666;
  font-size: 12px;
  line-height: 17px;
`;

type Props = {
  chatMessage: ConsultantChatMessage;
  onDownloadFile: (chatMessage: ConsultantChatMessage) => Promise<void>;
};

const renderMessage: FC<Props> = ({ chatMessage, onDownloadFile }) => {
  if (chatMessage.deleted) {
    return <DeletedMessage>このメッセージは削除されました</DeletedMessage>;
  }

  if (chatMessage.file) {
    return (
      <>
        <FileMessage onClick={() => onDownloadFile(chatMessage)}>
          <FileIcon />
          <div style={{ marginRight: 4 }}>
            <FileName>{chatMessage.message}</FileName>
            <FileSize>
              ファイルサイズ: {formatFileSize(chatMessage.file.size)}
            </FileSize>
          </div>
          <DownloadIcon />
        </FileMessage>
        <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
      </>
    );
  }

  return (
    <>
      <Message>
        <LinkText>{chatMessage.message}</LinkText>
      </Message>
      <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
    </>
  );
};

const OpponentChatMessage: FC<Props> = props => {
  return (
    <Wrapper data-testid="opponent-chat-message">
      {renderMessage(props)}
    </Wrapper>
  );
};

export default OpponentChatMessage;
