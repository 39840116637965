import { combineReducers } from 'redux';
import accountReducer from './account';
import uiReducer from './ui';
import notificationReducer from './notification';

export default function craeteReducer() {
  return combineReducers({
    account: accountReducer,
    ui: uiReducer,
    notification: notificationReducer,
  });
}
