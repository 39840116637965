import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

const Wrapper = styled.ul`
  list-style: initial;
`;

const JobHistory = styled.li`
  margin-bottom: 4px;
`;

const JobName = styled.p`
  margin-bottom: 4px;
  font-weight: bold;
`;

const JobPosition = styled.p`
  margin-bottom: 4px;
`;

const JobHistoryItems = styled.ul`
  list-style-position: inside;
  list-style-type: circle;
`;

const JobHistoryItem = styled.li`
  margin-bottom: 4px;
`;

const formatJob = (job: CareerProfile['job'][0]) => {
  const { name, startYear, startMonth, endYear, endMonth } = job;
  const end = endYear && endMonth ? `${endYear}年${endMonth}月` : '現在';
  return `${name} (${startYear}年${startMonth}月 〜 ${end})`;
};

type JobHistoriesProps = {
  job: CareerProfile['job'];
};

const JobHistories: FC<JobHistoriesProps> = ({ job }) => {
  return (
    <Wrapper>
      {job.map((jobHistory, jobIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <JobHistory key={jobIndex}>
          <JobName>{formatJob(jobHistory)}</JobName>
          <JobPosition>{jobHistory.position?.name}</JobPosition>
          <JobHistoryItems>
            {jobHistory.occupations.map((occupation, occupationIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <JobHistoryItem key={occupationIndex}>
                {`${occupation.occupation?.name} ${
                  occupation.yearsOfExperience?.name
                } ${jobIndex === 0 && occupationIndex === 0 ? '(現職)' : ''}`}
              </JobHistoryItem>
            ))}
          </JobHistoryItems>
        </JobHistory>
      ))}
    </Wrapper>
  );
};

export default JobHistories;
