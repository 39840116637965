import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import api from 'api/consultant';

type Values = { memo: string };

const useSaveMemo = (
  matter: HumanResourceMatterForConsultant,
  applyLatest: (latest: HumanResourceMatterForConsultant) => void,
) => {
  const [initialValues, setInitialValues] = useState<Values>({
    memo: matter.memo,
  });
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (values: Values) => {
      setError(null);
      try {
        await api.putHumanResourceMatterMemo({
          matterId: matter.matterId,
          memo: values.memo,
        });
      } catch (e) {
        if (e instanceof Error) setError(e.message);
        return;
      }
      applyLatest({ ...matter, memo: values.memo });
      setInitialValues(values);
    },
    [matter, applyLatest],
  );

  const formikContext = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: object({
      memo: string(),
    }),
  });

  return { formikContext, error };
};

export default useSaveMemo;
