import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */

export const SET_USER_ID = 'account/SET_USER_ID';
export const SET_ACCOUNT_ID = 'account/SET_ACCOUNT_ID';
export const SET_AGENT_ID = 'account/SET_AGENT_ID';
export const SET_IS_LOGGED_IN = 'account/SET_IS_LOGGED_IN';
export const SET_EMAIL = 'account/SET_EMAIL';
export const CLEAR_ACCOUNT = 'account/CLEAR';

/*
 * Redux Actions
 */

export const AccountActions = {
  setUserId: (userId: string) => createAction(SET_USER_ID, userId),
  setAccountId: (accountId: string) => createAction(SET_ACCOUNT_ID, accountId),
  setConsultantId: (adminId: string) => createAction(SET_AGENT_ID, adminId),
  setIsLoggedIn: (isLoggedIn: boolean) =>
    createAction(SET_IS_LOGGED_IN, isLoggedIn),
  setEmail: (email: string) => createAction(SET_EMAIL, email),
  clear: () => createAction(CLEAR_ACCOUNT),
};
export type Actions = ActionsUnion<typeof AccountActions>;

/*
 * Selectors
 */
export const getAccountInfo = (state: any): AccountState => state.account;

export const getAdminId = createSelector(
  [getAccountInfo],
  account => account.consultantId,
);

export const getAccountId = createSelector(
  [getAccountInfo],
  account => account.accountId,
);

export const isAccountLoggedIn = createSelector(
  [getAccountInfo],
  account => account.isLoggedIn,
);

export const getEmail = createSelector(
  [getAccountInfo],
  account => account.email,
);

/*
 * Reducer
 */

export interface AccountState {
  readonly userId?: string;
  readonly accountId?: string;
  readonly consultantId?: string;
  readonly isLoggedIn?: boolean;
  readonly email?: string;
}

const initState: AccountState = {
  userId: '',
  accountId: '',
  consultantId: '',
  isLoggedIn: false,
  email: '',
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_USER_ID: {
      return { ...state, userId: action.payload };
    }
    case SET_ACCOUNT_ID: {
      return { ...state, accountId: action.payload };
    }
    case SET_AGENT_ID: {
      return { ...state, consultantId: action.payload };
    }
    case SET_IS_LOGGED_IN: {
      return { ...state, isLoggedIn: action.payload };
    }
    case SET_EMAIL: {
      return { ...state, email: action.payload };
    }
    case CLEAR_ACCOUNT: {
      return initState;
    }
    default:
      return state;
  }
}
