import React, { FC, SelectHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const StyledSelectbox = styled.select`
  height: 40px;
  margin: ${({ margin = '0 0 0 0' }: SelectboxProps) => margin};
  padding: 0 4px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 4px;
  color: ${theme.textPrimary};
  font-size: 14px;
  ${({ disabled }: SelectboxProps) =>
    disabled
      ? `
      background-color: ${theme.borderDefault};
      color: ${theme.textSecondary};
      `
      : `background-color: transparent;`}

  &:focus {
    box-shadow: none;
  }
`;

type OptionType = {
  [key: string]: any;
};
type SelectboxProps = SelectHTMLAttributes<HTMLSelectElement> & {
  margin?: string;
  options: OptionType[];
  idName: string;
  textName?: string;
  selectType?: 'default' | 'range';
};
const Selectbox: FC<SelectboxProps> = props => {
  const { options, idName, textName, selectType = 'default' } = props;
  return selectType === 'default' ? (
    <StyledSelectbox {...props}>
      {options.map(option => (
        <option key={option[idName]} value={option[idName]}>
          {textName ? option[textName] : option.name}
        </option>
      ))}
    </StyledSelectbox>
  ) : (
    <StyledSelectbox {...props}>
      {options.map(option => (
        <option key={option[idName]} value={option[idName]}>
          {`${option.lowerLimit}〜${option.upperLimit}${option.unitName}`}
        </option>
      ))}
    </StyledSelectbox>
  );
};

export default Selectbox;
