export enum HumanResourceMatterStatus {
  WaitingForApproval = '承認待ち',
  WaitingForApprovalOfUpdates = '承認待ち(更新)',
  Approved = '承認',
  Disapproved = '非承認',
  SendBack = '差し戻し',
  Unpublished = '非公開',
  Closed = 'クローズ',
}

export enum MatterStatusId {
  WaitingForApproval = 1,
  Disapproved = 2,
  SendBack = 3,
  Approved = 4,
  Unpublished = 5,
  Closed = 6,
}

export enum FilterName {
  All = 'すべて',
  WaitingForApproval = '承認待ち',
  Approved = '承認',
  Disapproved = '非承認',
  SendBack = '差し戻し',
  Unpublished = '非公開',
  Closed = 'クローズ',
}

export const STATUS_COLORS: Record<
  HumanResourceMatterStatus,
  { color: string; background: string; borderColor?: string }
> = {
  [HumanResourceMatterStatus.WaitingForApproval]: {
    color: 'white',
    background: '#808080',
  },
  [HumanResourceMatterStatus.WaitingForApprovalOfUpdates]: {
    color: 'white',
    background: '#808080',
  },
  [HumanResourceMatterStatus.Approved]: {
    color: 'white',
    background: '#0CA789',
  },
  [HumanResourceMatterStatus.Disapproved]: {
    color: 'white',
    background: '#F24726',
  },
  [HumanResourceMatterStatus.SendBack]: {
    color: 'white',
    background: '#414BB2',
  },
  [HumanResourceMatterStatus.Unpublished]: {
    color: '#808080',
    background: 'white',
    borderColor: '#808080',
  },
  [HumanResourceMatterStatus.Closed]: { color: 'white', background: '#FAC710' },
};

export const STATUS_DISPLAY_NAME = {
  [MatterStatusId.WaitingForApproval]: 'プロフィール承認待ち',
  [MatterStatusId.Approved]: 'プロフィール承認済み',
  [MatterStatusId.Disapproved]: 'プロフィール非承認',
  [MatterStatusId.SendBack]: 'プロフィール差し戻し中',
  [MatterStatusId.Unpublished]: 'プロフィール非公開',
  [MatterStatusId.Closed]: 'クローズ',
} as const;
