import React, { FC } from 'react';
import {
  Card,
  Badge,
  TextStyle,
  Stack,
  Button,
  Popover,
  ActionList,
} from '@shopify/polaris';
import {
  SCOUT_CONTRACT_STATUS_ID,
  SCOUT_CONTRACT_STATUS_BADGE_STATUS,
} from 'constants/scoutCompany';
import { ScoutCompany, ScoutContractStatus } from 'proto/v1/apimodel/apimodel';

type Props = {
  scoutCompany: ScoutCompany | undefined;
  scoutContractStatuses: ScoutContractStatus[];
  onToggleScoutContractStatusPopover: () => void;
  onCloseScoutContractStatusPopover: () => void;
  onSelectScoutContractStatus: (status: ScoutContractStatus) => void;
  onOpenEditScoutCompanyStaffNameModal: () => void;
  isActiveScoutContractStatusPopover: boolean;
};

const ScoutCompanyCard: FC<Props> = ({
  scoutCompany,
  scoutContractStatuses,
  onCloseScoutContractStatusPopover,
  onToggleScoutContractStatusPopover,
  onOpenEditScoutCompanyStaffNameModal,
  onSelectScoutContractStatus,
  isActiveScoutContractStatusPopover,
}) => {
  return (
    <Card title="スカウト機能利用状況">
      <Card.Section>
        <Badge
          status={
            SCOUT_CONTRACT_STATUS_BADGE_STATUS[
              scoutCompany?.scoutContractStatus?.id ??
                SCOUT_CONTRACT_STATUS_ID.USE_EXPLANATION_VIEWED
            ]
          }
        >
          {scoutCompany?.scoutContractStatus?.name}
        </Badge>
      </Card.Section>
      <Card.Section title="利用説明閲覧回数">
        <p>{scoutCompany?.numberOfViewsForUseExplanation}回</p>
      </Card.Section>
      <Card.Section
        title="問い合わせ担当"
        actions={[
          {
            content: '編集',
            onAction: onOpenEditScoutCompanyStaffNameModal,
            accessibilityLabel: '問い合わせ担当編集',
          },
        ]}
      >
        {scoutCompany?.staffName ? (
          <TextStyle>{scoutCompany?.staffName}</TextStyle>
        ) : (
          <TextStyle variation="subdued">未入力</TextStyle>
        )}
      </Card.Section>
      <Card.Section>
        <Stack distribution="trailing">
          <Popover
            active={isActiveScoutContractStatusPopover}
            activator={
              <Button
                primary
                disclosure={isActiveScoutContractStatusPopover ? 'up' : 'down'}
                onClick={onToggleScoutContractStatusPopover}
                accessibilityLabel="利用状況を変更する"
              >
                利用状況を変更する
              </Button>
            }
            fullWidth
            onClose={onCloseScoutContractStatusPopover}
          >
            <ActionList
              items={scoutContractStatuses.map(status => ({
                content: status.name,
                onAction: () => onSelectScoutContractStatus(status),
              }))}
              actionRole="option"
            />
          </Popover>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default ScoutCompanyCard;
