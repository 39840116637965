import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as PROTECTED from 'constants/routes/protected';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { useHistory } from 'react-router-dom';
import { formatBirth, formatName } from 'utils/models/humanResourceMatter';
import { GridArea, Tag } from 'components/atoms';
import { JobHistories } from 'components/molecules';
import { HumanResourceMatterStatus, HumanResourceMatterHead } from '..';

const Card = styled.div`
  display: grid;
  grid-template:
    '...  ..... ...'
    '...  head  ...' auto
    '...  ..... ...' 20px
    '...  body  ...' 1fr
    '...  ..... ...' 20px
    / 0 1fr 0;
  width: 100%;
  min-height: 120px;
  margin: 0 0 16px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background: white;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    background: #f8f8f8;
  }
`;

const Head = styled.div`
  grid-area: head;
`;

const Body = styled.div`
  display: grid;
  grid-area: body;
  grid-template:
    '... .......  ... ....... ... ....... ...'
    '... status   ... profile ... staff   ...' 1fr
    '... .......  ... ....... ... ....... ...'
    / 12px auto 12px 2fr 12px 1fr 0;
`;

const CareerProfile = styled.div`
  display: grid;
  grid-area: profile;
  grid-template:
    '...  ........... ... ........... ...'
    '...  name-label  ... name-value  ...' auto
    '...  ........... ... ........... ...' 12px
    '...  birth-label ... birth-value ...' auto
    '...  ........... ... ........... ...' 12px
    '...  job-label   ... job-value   ...' auto
    '...  ........... ... ........... ...' 12px
    '...  skill-label ... skill-value ...' auto
    '...  ........... ... ........... ...' 12px
    '...  ab-label    ... ab-value    ...' auto
    '...  ........... ... ........... ...' 12px
    '...  po-label    ... po-value    ...' auto
    '...  ........... ... ........... ...'
    / 0 auto 28px 1fr 20px;
`;

const NewGraduateProfile = styled.div`
  display: grid;
  grid-area: profile;
  grid-template:
    '...  ..............  ... ..............  ...'
    '...  name-label      ... name-value      ...' auto
    '...  ...........     ... ..............  ...' 12px
    '...  birth-label     ... birth-value     ...' auto
    '...  ...........     ... ..............  ...' 12px
    '...  ab-label        ... ab-value        ...' auto
    '...  ..............  ... ..............  ...' 12px
    '...  pw-label        ... pw-value        ...' auto
    '...  ..............  ... ..............  ...' 12px
    '...  pb-label        ... pb-value        ...' auto
    '...  ..............  ... ..............  ...' 12px
    '...  po-label        ... po-value        ...' auto
    '...  ..............  ... ..............  ...'
    / 0 auto 28px 1fr 20px;
`;

const StaffInfo = styled.div`
  display: grid;
  grid-area: staff;
  grid-template:
    '...  ........... ........... ........... ...'
    '...  caption     caption     caption     ...' auto
    '...  ........... ........... ........... ...' 12px
    '...  staff-label ........... staff-value ...' auto
    '...  ........... ........... ........... ...' 12px
    '...  memo-label  ........... memo-value  ...' 1fr
    '...  ........... ........... ........... ...'
    / 12px auto 28px 1fr 20px;
  height: 100%;
  border-left: 1px solid #bbb;
`;

const ProfileLabel = styled(GridArea)`
  color: #808080;
`;

const Caption = styled(GridArea)`
  font-weight: bold;
`;

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const Memo = styled(GridArea)`
  display: -webkit-box;
  max-height: 158px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

type HumanResourceMatterCardProps = {
  matter: HumanResourceMatterForConsultant;
  applyLatest: (latest: HumanResourceMatterForConsultant) => void;
};

const HumanResourceMatterCard: FC<HumanResourceMatterCardProps> = ({
  matter,
  applyLatest,
}) => {
  const history = useHistory();
  const { profile } = matter;
  const isCareer = !!profile?.career;

  return (
    <Card
      data-testid={`matter-card`}
      onClick={() =>
        history.push(
          `${PROTECTED.PRIVATE_HUMAN_RESOURCE_MATTERS}/${matter.matterPublishId}`,
        )
      }
    >
      <Head>
        <HumanResourceMatterHead
          matter={matter}
          applyLatest={applyLatest}
          showAdditionInfo
        />
      </Head>
      <Body>
        <GridArea area="status">
          <HumanResourceMatterStatus matter={matter} />
        </GridArea>
        {isCareer ? (
          <CareerProfile data-testid="career-profile">
            <ProfileLabel area="name-label">氏名</ProfileLabel>
            <GridArea area="name-value">{formatName(profile)}</GridArea>
            <ProfileLabel area="birth-label">生年月</ProfileLabel>
            <GridArea area="birth-value">{formatBirth(profile)}</GridArea>
            <ProfileLabel area="job-label">勤務先・職種</ProfileLabel>
            <GridArea area="job-value">
              <JobHistories job={profile?.career?.job ?? []} />
            </GridArea>
            <ProfileLabel area="skill-label">経験スキル</ProfileLabel>
            <GridArea area="skill-value">
              {profile?.career?.skills
                ? profile?.career?.skills.map(s => (
                    <Tag key={s.name}>{s.name}</Tag>
                  ))
                : '-'}
            </GridArea>
            <ProfileLabel area="ab-label">最終学歴</ProfileLabel>
            <GridArea area="ab-value">{`${profile?.academicBackground?.school?.name}${profile?.academicBackground?.faculty}${profile?.academicBackground?.department?.name} ${profile?.academicBackground?.yearOfGraduation}年${profile?.academicBackground?.monthOfGraduation}月 卒業`}</GridArea>
            <ProfileLabel area="po-label">希望職種</ProfileLabel>
            <GridArea area="po-value">
              {profile?.preferredOccupations?.map(o => o.name).join('・')}
            </GridArea>
          </CareerProfile>
        ) : (
          <NewGraduateProfile data-testid="new-graduate-profile">
            <ProfileLabel area="name-label">氏名</ProfileLabel>
            <GridArea area="name-value">{formatName(profile)}</GridArea>
            <ProfileLabel area="birth-label">生年月</ProfileLabel>
            <GridArea area="birth-value">{formatBirth(profile)}</GridArea>
            <ProfileLabel area="ab-label">所属大学</ProfileLabel>
            <GridArea area="ab-value">{`${profile?.academicBackground?.school?.name}${profile?.academicBackground?.faculty}${profile?.academicBackground?.department?.name} ${profile?.academicBackground?.yearOfGraduation}年${profile?.academicBackground?.monthOfGraduation}月 卒業予定`}</GridArea>
            <ProfileLabel area="pw-label">希望する働き方</ProfileLabel>
            <GridArea area="pw-value">
              {profile?.newGraduate?.preferredWorkStyle?.name ?? '-'}
            </GridArea>
            <ProfileLabel area="pb-label">希望業種</ProfileLabel>
            <GridArea area="pb-value">
              {profile?.newGraduate?.preferredBusinessTypes &&
              profile.newGraduate.preferredBusinessTypes.length > 0
                ? profile.newGraduate.preferredBusinessTypes
                    .map(o => o.name)
                    .join('・')
                : '-'}
            </GridArea>
            <ProfileLabel area="po-label">希望職種</ProfileLabel>
            <GridArea area="po-value">
              {profile?.preferredOccupations?.map(o => o.name).join('・')}
            </GridArea>
          </NewGraduateProfile>
        )}
        <StaffInfo>
          <Caption area="caption">担当者情報</Caption>
          <ProfileLabel area="staff-label">担当者</ProfileLabel>
          <GridArea area="staff-value">
            {`${matter.staffEmail || '-'}`}
          </GridArea>
          <ProfileLabel area="memo-label">担当者メモ</ProfileLabel>
          <Memo area="memo-value">{`${matter.memo || '-'}`}</Memo>
        </StaffInfo>
      </Body>
    </Card>
  );
};

export default React.memo(
  HumanResourceMatterCard,
  (prev, next) => prev.matter === next.matter,
);
