import {
  ClearNotificationRequest,
  ClearNotificationResponse,
  PutMatterFavoriteRequest,
  PutMatterFavoriteResponse,
} from 'proto/v1/commonservice/commonservice';
import { CancelToken } from 'axios';
import client from './config/client';
import apiFirebase from './firebase';

export default {
  async clearNotification(
    req: ClearNotificationRequest,
    cancelToken: CancelToken,
  ): Promise<{ data: ClearNotificationResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/notifications/clear`;
    const token = await apiFirebase.currentToken();
    return client(token)
      .post(url, ClearNotificationRequest.toJSON(req), { cancelToken })
      .then(res => {
        return {
          data: ClearNotificationResponse.fromJSON(res.data),
        };
      });
  },

  async putMatterFavorite(
    req: PutMatterFavoriteRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutMatterFavoriteResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/matters/favorite`;
    return client(token)
      .put(url, req, { cancelToken })
      .then(x => {
        return {
          data: PutMatterFavoriteResponse.fromJSON(x.data),
        };
      });
  },
};
