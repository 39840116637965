export const EMPLOYMENT_TYPE_ID = {
  FULL_TIME: 1,
  OUT_SOURCING: 2,
  ADVISOR: 3,
} as const;

export const EMPLOYMENT_TYPE_TAG_TYPE = {
  NEW_GRADUATE: 'NEW_GRADUATE',
  CAREER: 'CAREER',
  OUT_SOURCING: 'OUT_SOURCING',
  ADVISOR: 'ADVISOR',
} as const;
