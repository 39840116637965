import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Label } from 'components/atoms';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { getStatus } from 'utils/models/humanResourceMatter';
import { STATUS_COLORS } from 'constants/humanResourceMatter';

const StyledLabel = styled(Label)`
  min-width: 105px;
`;

type HumanResourceMatterStatusProps = {
  matter: HumanResourceMatterForConsultant;
};

const HumanResourceMatterStatus: FC<HumanResourceMatterStatusProps> = ({
  matter,
}) => {
  const status = getStatus(matter);
  return <StyledLabel {...STATUS_COLORS[status]}>{status}</StyledLabel>;
};

export default HumanResourceMatterStatus;
