import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';
import React, { FC } from 'react';

type DisapproveModalProps = {
  show: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const DisapproveModal: FC<DisapproveModalProps> = props => {
  const { show, onCancel, onOk } = props;
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="disapprove-modal">
        本当に否認にしますか？
      </ModalContent>
      <ModalFooter>
        <ModalButton buttonTheme="cancel" onClick={onCancel}>
          キャンセル
        </ModalButton>
        <ModalButton onClick={onOk}>OK</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default DisapproveModal;
