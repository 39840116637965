import styled from 'styled-components/macro';
import { Tooltip } from 'visits-style';

const StyledTooltip = styled(Tooltip)`
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);
  color: #333;
  font-size: 11px;
  line-height: 1.5;
  text-align: left;
  white-space: pre-wrap;
`;

export default StyledTooltip;
