import { useCallback, useState } from 'react';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import apiCommon from 'api/common';

const usePutFavorite = (
  matter: HumanResourceMatterForConsultant,
  applyLatest: (latest: HumanResourceMatterForConsultant) => void,
) => {
  const [error, setError] = useState<string | null>(null);

  const putFavorite = useCallback(
    async (favorite: boolean) => {
      setError(null);
      try {
        await apiCommon.putMatterFavorite({
          favorite,
          matterPublishId: matter.matterPublishId,
        });
      } catch (e) {
        if (e instanceof Error) setError(e.message);
        return;
      }
      applyLatest({ ...matter, favorite });
    },
    [applyLatest, matter],
  );

  return { error, putFavorite };
};

export default usePutFavorite;
