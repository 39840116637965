import React, { lazy } from 'react';
import '@shopify/polaris/dist/styles.css';
import { ThemeProvider } from 'styled-components/macro';
import { AppProvider, AppProviderProps, LinkProps } from '@shopify/polaris';
import jaTranslations from '@shopify/polaris/locales/ja.json';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
  Link as ReactRouterLink,
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import * as PUBLIC_ROUTES from 'constants/routes/public';
import * as PROTECTED_ROUTES from 'constants/routes/protected';
import { GlobalLayout } from 'components/templates';
import NotFound from 'components/pages/404';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import createStore from './store';

const Login = lazy(
  () => import(/* webpackChunkName: 'login' */ 'components/pages/Login'),
);
const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'resetPassword' */ 'components/pages/Password/Reset/PasswordReset'
    ),
);
const RegisterPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'registerPassword' */ 'components/pages/Password/Register/PasswordRegister'
    ),
);
const Private = lazy(
  () => import(/* webpackChunkName: 'private' */ 'components/pages/Private'),
);

const store = createStore();

// todo strict type
// @ts-ignore
const persistor = persistStore(store);

const polarisTheme = {
  logo: {
    width: 256,
    topBarSource: '/assets/logo.svg',
  },
};

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

const polarisI18n: AppProviderProps['i18n'] = {
  ...jaTranslations,
  Polaris: {
    ResourceList: {
      ...jaTranslations.Polaris.ResourceList,
      showing: '{itemsCount}件表示中',
      selected: '{selectedItemsCount}選択中',
    },
  },
};

export const PolarisLink: React.FC<LinkProps> = ({
  children,
  url = '',
  external,
  ...rest
}) => {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

function RootRouter() {
  return (
    <BrowserRouter>
      <AppProvider
        i18n={polarisI18n}
        theme={polarisTheme}
        linkComponent={
          PolarisLink as React.ComponentProps<
            typeof AppProvider
          >['linkComponent']
        }
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <GlobalLayout>
            <Switch>
              <Route path={PUBLIC_ROUTES.LOGIN} exact component={Login} />
              <Route
                path={PUBLIC_ROUTES.RESET_PASSWORD}
                exact
                component={ResetPassword}
              />
              <Route
                path={PUBLIC_ROUTES.REGISTER_PASSWORD}
                exact
                component={RegisterPassword}
              />
              <Route path={PROTECTED_ROUTES.PRIVATE} component={Private} />
              <Route component={NotFound} />
            </Switch>
          </GlobalLayout>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootRouter />
      </PersistGate>
    </Provider>
  );
}
