import React, { FC } from 'react';
import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';

type Props = {
  show: boolean;
  onClickCancel: () => void;
  onClickOk: () => void;
  text: string;
};

const ApproveModal: FC<Props> = ({ show, onClickCancel, onClickOk, text }) => {
  return (
    <Modal
      show={show}
      closeModal={onClickCancel}
      style={{ overflow: 'hidden' }}
    >
      <ModalContent data-testid="modal-confirm">{text}</ModalContent>
      <ModalFooter>
        <ModalButton buttonTheme="cancel" onClick={onClickCancel}>
          キャンセル
        </ModalButton>
        <ModalButton onClick={onClickOk}>OK</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ApproveModal;
