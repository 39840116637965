import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { RadioButtonSetItem } from 'types/form';

const RadioButtons = styled.div`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 3px;
  white-space: nowrap;
`;

const RadioButtonLabel = styled.label<{ isSelected: boolean }>`
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 20px 8px 20px;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: 1px solid #c4c4c4;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:first-child {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-right: 1px solid #c4c4c4;
    border-radius: 0 3px 3px 0;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #fff;
      background-color: #666;
      border-color: #666;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    `}
`;

const RadioButtonInput = styled.input`
  display: none;
`;

type Props = {
  name: string;
  selected: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  items: RadioButtonSetItem[];
};

const RadioButtonSet: FC<Props> = ({ name, selected, onChange, items }) => (
  <RadioButtons>
    {items.map(item => (
      <RadioButtonLabel isSelected={item.value === selected} key={item.value}>
        <RadioButtonInput
          name={name}
          type="radio"
          aria-label={item.label}
          value={item.value}
          checked={item.value === selected}
          onChange={onChange}
        />
        {item.label}
      </RadioButtonLabel>
    ))}
  </RadioButtons>
);

export default RadioButtonSet;
