import { BadgeProps } from '@shopify/polaris';

export const SCOUT_CONTRACT_STATUS_ID = {
  USE_EXPLANATION_VIEWED: 1,
  RECEIVED_INQUIRY: 2,
  DEALING_WITH_INQUIRY: 3,
  CONTRACTED: 4,
  CANCELED: 5,
} as const;

export const SCOUT_BILLING_STATUS_ID = {
  UNBILLED: 1,
  BILLED: 2,
} as const;

export const SELECTION_STEP_ID = {
  NO_SELECTED: 0,
  DOCUMENT_SCREENING: 1,
  FIRST_SELECTION: 2,
  SECONDARY_SELECTION: 3,
  THIRD_SELECTION: 4,
  FINAL_SELECTION: 5,
  UNOFFICIAL_OFFERED: 6,
  UNOFFICIAL_OFFER_CONSENTED: 7,
  REJECTED_SELECTION: 8,
  REFUSED_OFFER: 9,
} as const;

export const SCOUT_CONTRACT_STATUS_BADGE_STATUS: {
  [key: number]: BadgeProps['status'];
} = {
  [SCOUT_CONTRACT_STATUS_ID.USE_EXPLANATION_VIEWED]: undefined,
  [SCOUT_CONTRACT_STATUS_ID.RECEIVED_INQUIRY]: 'warning',
  [SCOUT_CONTRACT_STATUS_ID.DEALING_WITH_INQUIRY]: 'info',
  [SCOUT_CONTRACT_STATUS_ID.CONTRACTED]: 'success',
  [SCOUT_CONTRACT_STATUS_ID.CANCELED]: undefined,
} as const;

export const SCOUT_BILLING_STATUS_BADGE_STATUS: {
  [key: number]: BadgeProps['status'];
} = {
  [SCOUT_BILLING_STATUS_ID.BILLED]: 'success',
  [SCOUT_BILLING_STATUS_ID.UNBILLED]: 'warning',
} as const;

export const SELECTION_STEP_BADGE_STATUS: {
  [key: number]: BadgeProps['status'];
} = {
  [SELECTION_STEP_ID.NO_SELECTED]: undefined,
  [SELECTION_STEP_ID.DOCUMENT_SCREENING]: undefined,
  [SELECTION_STEP_ID.FIRST_SELECTION]: undefined,
  [SELECTION_STEP_ID.SECONDARY_SELECTION]: undefined,
  [SELECTION_STEP_ID.THIRD_SELECTION]: undefined,
  [SELECTION_STEP_ID.FINAL_SELECTION]: undefined,
  [SELECTION_STEP_ID.UNOFFICIAL_OFFERED]: 'success',
  [SELECTION_STEP_ID.UNOFFICIAL_OFFER_CONSENTED]: 'success',
  [SELECTION_STEP_ID.REJECTED_SELECTION]: undefined,
  [SELECTION_STEP_ID.REFUSED_OFFER]: undefined,
} as const;

export const SCOUTING_HUMAN_RESOURCES_FILTER_TAB_INDEX = {
  ALL: 0,
  UNBILLED: 1,
} as const;

export const EDIT_SCOUT_COMPANY_BILLING_ADDRESS_MAX_LENGTH = {
  LAST_NAME: 20,
  FIRST_NAME: 20,
  OFFICIAL_POSITION: 20,
  EMAIL: 320,
  EMAIL_LOCAL_PART: 64,
  EMAIL_DOMAIN_PART: 255,
  PHONE_NUMBER: 15,
  ADDRESS: 200,
} as const;
