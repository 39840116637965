import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as PUBLIC_ROUTES from 'constants/routes/public';
import * as PROTECTED_ROUTES from 'constants/routes/protected';
import { CLEAR_ACCOUNT } from 'modules/account';
import { getNotification } from 'modules/notification';
import scrollbar from 'styles/scrollbar';
import { Accordion } from 'visits-style';
import { HomeMajor } from '@shopify/polaris-icons';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Building, Mail, User } from 'assets/svg';
import apiFirebase from 'api/firebase';
import { SidebarNavLink } from 'components/atoms';

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${scrollbar}
  width: 220px;
  min-width: 220px;
  overflow-y: auto;
  background: #555;
  color: #fff;
  font-size: 13px;
  line-height: 1.5;
`;

const BottomMenu = styled.ul``;

const BottomMenuItem = styled.li`
  padding: 24px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const UnreadCount = styled.div`
  justify-self: flex-end;
  display: inline-block;
  min-width: 26px;
  height: 20px;
  margin-right: 16px;
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 18px;
  background: rgb(94, 39, 251);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
`;

const AccordionHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 48px;
  padding-left: 20px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
  cursor: pointer;

  &:hover {
    background: #333;
  }
`;

const AccordionIconStyle = css`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

const accordionIconProps = {
  strokeWidth: 2,
  size: 16,
};

const AccordionIconUp = styled(FiChevronUp).attrs(accordionIconProps)`
  ${AccordionIconStyle}
`;

const AccordionIconDown = styled(FiChevronDown).attrs(accordionIconProps)`
  ${AccordionIconStyle}
`;

const AccordionMenuNavLink = styled(SidebarNavLink)`
  width: 100%;
  height: 28px;
  padding-left: 40px;
  font-size: 12px;
  font-weight: normal;
`;

const isAccountPage = (pathname: string) =>
  [
    PROTECTED_ROUTES.PRIVATE_CREATE_CONSULTANT,
    PROTECTED_ROUTES.PRIVATE_DELETE_CONSULTANT,
    PROTECTED_ROUTES.PRIVATE_DELETE_INNOVATOR,
    PROTECTED_ROUTES.PRIVATE_RESEND_VERIFICATION_EMAIL,
  ].includes(pathname);

export default function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isOpenedAccountPageAccordion, setIsOpenedAccountPageAccordion] =
    useState<boolean>(isAccountPage(location.pathname));
  const { messageNotifications } = useSelector(getNotification);

  const unreadMessagesCount = messageNotifications.reduce(
    (prev, current) => prev + current.count,
    0,
  );

  const handleClickAccountPageAccordion = useCallback(() => {
    setIsOpenedAccountPageAccordion(prev => !prev);
  }, []);

  const handleClickLogout = () => {
    apiFirebase.logout().then(() => {
      dispatch({
        type: CLEAR_ACCOUNT,
      });

      history.push(PUBLIC_ROUTES.LOGIN);
    });
  };

  return (
    <Container>
      <ul>
        <li>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_HUMAN_RESOURCE_MATTERS}>
            <Building style={{ marginRight: '8px' }} />
            人材案件一覧
          </SidebarNavLink>
        </li>
        <li>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_SCOUT_COMPANIES}>
            <HomeMajor
              width={14}
              height={14}
              fill="#fff"
              style={{ marginRight: '8px' }}
            />
            スカウト機能利用企業一覧
          </SidebarNavLink>
        </li>
        <li>
          <SidebarNavLink to={PROTECTED_ROUTES.PRIVATE_MESSAGES}>
            <Mail style={{ marginRight: '8px' }} />
            メッセージ
            {unreadMessagesCount > 0 && (
              <UnreadCount>{unreadMessagesCount}</UnreadCount>
            )}
          </SidebarNavLink>
        </li>
        <li>
          <Accordion
            show={isOpenedAccountPageAccordion}
            header={
              <AccordionHeader onClick={handleClickAccountPageAccordion}>
                <User style={{ marginRight: '8px' }} />
                アカウント管理
                {isOpenedAccountPageAccordion ? (
                  <AccordionIconUp />
                ) : (
                  <AccordionIconDown />
                )}
              </AccordionHeader>
            }
          >
            <ul>
              <li>
                <AccordionMenuNavLink
                  to={PROTECTED_ROUTES.PRIVATE_CREATE_CONSULTANT}
                >
                  コンサルタント作成
                </AccordionMenuNavLink>
              </li>
              <li>
                <AccordionMenuNavLink
                  to={PROTECTED_ROUTES.PRIVATE_DELETE_CONSULTANT}
                >
                  コンサルタント削除
                </AccordionMenuNavLink>
              </li>
              <li>
                <AccordionMenuNavLink
                  to={PROTECTED_ROUTES.PRIVATE_DELETE_INNOVATOR}
                >
                  イノベーター削除
                </AccordionMenuNavLink>
              </li>
              <li>
                <AccordionMenuNavLink
                  to={PROTECTED_ROUTES.PRIVATE_RESEND_VERIFICATION_EMAIL}
                >
                  認証メール再送信
                </AccordionMenuNavLink>
              </li>
            </ul>
          </Accordion>
        </li>
      </ul>

      <BottomMenu>
        <BottomMenuItem onClick={handleClickLogout} data-testid="logout">
          ログアウト
        </BottomMenuItem>
      </BottomMenu>
    </Container>
  );
}
