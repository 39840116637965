import React, { ComponentProps, FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 20px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;

  &:hover {
    background: #333;
  }
`;

type SidebarNavLinkProps = ComponentProps<typeof NavLink>;

const SidebarNavLink: FC<SidebarNavLinkProps> = props => {
  return (
    <StyledNavLink
      {...props}
      activeStyle={{
        background: '#333',
      }}
    />
  );
};

export default SidebarNavLink;
