import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import { isPast } from 'date-fns';
import {
  SCOUT_BILLING_STATUS_BADGE_STATUS,
  SELECTION_STEP_BADGE_STATUS,
  SCOUTING_HUMAN_RESOURCES_FILTER_TAB_INDEX,
  SCOUT_BILLING_STATUS_ID,
} from 'constants/scoutCompany';
import { ScoutBillingStatus } from 'proto/v1/apimodel/apimodel';
import { ScoutingHumanResource } from 'types/scoutCompany';
import { formatDate } from 'utils/time';
import SearchGrayIcon from 'assets/svg/emptyStateSearchGray.svg';
import {
  Card,
  Badge,
  Stack,
  Tabs,
  ResourceList,
  ResourceItem,
  EmptyState,
  Pagination,
  TabsProps,
  TextStyle,
  ResourceListSelectedItems,
} from '@shopify/polaris';

const ScoutingHumanResourceRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1.2fr;
  align-items: center;
`;

const ScoutingHumanResourceHeadRow = styled(ScoutingHumanResourceRow)`
  padding-right: 20px;
  padding-left: 58px;
  box-shadow: inset 0 -1px 0 #e1e3e5;
`;

const ScoutingHumanResourceHeadColumn = styled.div`
  padding: 16px 10px 16px 0;
`;

type Props = {
  scoutingHumanResources: ScoutingHumanResource[];
  tabs: TabsProps['tabs'];
  selectedTabIndex: number;
  onSelectTab: (index: number) => void;
  onSelectScoutingHumanResource: (
    selectedItems: ResourceListSelectedItems,
  ) => void;
  selectedScoutIds: ScoutingHumanResource['scoutId'][];
  onActionUpdatingScoutBillingStatus: (
    scoutBillingStatusId: ScoutBillingStatus['id'],
  ) => void;
  onActionShowingAllScoutingHumanResources: () => void;
  onPreviousScoutingHumanResources: () => void;
  onNextScoutingHumanResources: () => void;
  hasPreviousScoutingHumanResources: boolean;
  hasNextScoutingHumanResources: boolean;
};

const ScoutingHumanResourceList: FC<Props> = ({
  scoutingHumanResources,
  tabs,
  selectedTabIndex,
  onSelectTab,
  onSelectScoutingHumanResource,
  selectedScoutIds,
  onActionUpdatingScoutBillingStatus,
  onActionShowingAllScoutingHumanResources,
  onPreviousScoutingHumanResources,
  onNextScoutingHumanResources,
  hasPreviousScoutingHumanResources,
  hasNextScoutingHumanResources,
}) => {
  const renderItem = useCallback(
    (scoutingHumanResource: ScoutingHumanResource) => {
      const isJoinCompanySchedulePast = isPast(
        scoutingHumanResource.joinCompanyScheduleAt,
      );
      const isUnbilled =
        scoutingHumanResource?.scoutBillingStatus?.id ===
        SCOUT_BILLING_STATUS_ID.UNBILLED;
      return (
        <ResourceItem id={scoutingHumanResource.scoutId} onClick={() => null}>
          <ScoutingHumanResourceRow data-testid="scouting-human-resource">
            <div>{`${scoutingHumanResource.lastName} ${scoutingHumanResource.firstName}`}</div>
            <TextStyle
              variation={
                isJoinCompanySchedulePast && isUnbilled ? 'negative' : undefined
              }
            >
              {scoutingHumanResource.joinCompanyScheduleAt
                ? formatDate(
                    new Date(scoutingHumanResource.joinCompanyScheduleAt),
                    'yyyy/MM/dd',
                  )
                : '-'}
            </TextStyle>
            <div>
              {scoutingHumanResource?.scoutBillingStatus && (
                <Badge
                  status={
                    isJoinCompanySchedulePast && isUnbilled
                      ? 'critical'
                      : SCOUT_BILLING_STATUS_BADGE_STATUS[
                          scoutingHumanResource.scoutBillingStatus.id
                        ]
                  }
                >
                  {scoutingHumanResource.scoutBillingStatus.name}
                </Badge>
              )}
            </div>
            <div>
              {scoutingHumanResource?.selectionStep && (
                <Badge
                  status={
                    SELECTION_STEP_BADGE_STATUS[
                      scoutingHumanResource.selectionStep.id
                    ]
                  }
                >
                  {scoutingHumanResource.selectionStep.name}
                </Badge>
              )}
            </div>
          </ScoutingHumanResourceRow>
        </ResourceItem>
      );
    },
    [],
  );

  return (
    <Card>
      {scoutingHumanResources.length > 0 ||
      (scoutingHumanResources.length === 0 &&
        selectedTabIndex ===
          SCOUTING_HUMAN_RESOURCES_FILTER_TAB_INDEX.UNBILLED) ? (
        <>
          <Card.Section>
            <Tabs
              tabs={tabs}
              selected={selectedTabIndex}
              onSelect={onSelectTab}
            />
            <ScoutingHumanResourceHeadRow>
              <ScoutingHumanResourceHeadColumn>
                名前
              </ScoutingHumanResourceHeadColumn>
              <ScoutingHumanResourceHeadColumn>
                入社予定日
              </ScoutingHumanResourceHeadColumn>
              <ScoutingHumanResourceHeadColumn>
                請求
              </ScoutingHumanResourceHeadColumn>
              <ScoutingHumanResourceHeadColumn>
                選考ステップ
              </ScoutingHumanResourceHeadColumn>
            </ScoutingHumanResourceHeadRow>
            <ResourceList
              items={scoutingHumanResources}
              renderItem={renderItem}
              selectedItems={selectedScoutIds}
              onSelectionChange={onSelectScoutingHumanResource}
              promotedBulkActions={[
                {
                  content: '請求済みに変更',
                  onAction: () =>
                    onActionUpdatingScoutBillingStatus(
                      SCOUT_BILLING_STATUS_ID.BILLED,
                    ),
                },
                {
                  content: '未請求に変更',
                  onAction: () =>
                    onActionUpdatingScoutBillingStatus(
                      SCOUT_BILLING_STATUS_ID.UNBILLED,
                    ),
                },
              ]}
              emptyState={
                <EmptyState
                  image={SearchGrayIcon}
                  heading="スカウトはありません"
                  action={{
                    content: '全てのスカウトを見る',
                    onAction: onActionShowingAllScoutingHumanResources,
                  }}
                >
                  <p>フィルターを変えてみてください</p>
                </EmptyState>
              }
              idForItem={item => item.scoutId}
            />
          </Card.Section>
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                hasPrevious={hasPreviousScoutingHumanResources}
                onPrevious={onPreviousScoutingHumanResources}
                hasNext={hasNextScoutingHumanResources}
                onNext={onNextScoutingHumanResources}
              />
            </Stack>
          </Card.Section>
        </>
      ) : (
        <EmptyState image="" heading="まだスカウト機能を利用していません。">
          <p>
            スカウト機能を利用すると、ここにスカウト相手がリスト表示されます。
          </p>
        </EmptyState>
      )}
    </Card>
  );
};

export default ScoutingHumanResourceList;
