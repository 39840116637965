import React, { Suspense, FC, useEffect } from 'react';
import * as H from 'history';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Container } from 'visits-style';
import useNotification from 'hooks/useNotification';
import { Loading } from 'components/atoms';
import { Sidebar } from 'components/organisms';
import { headerHeight } from 'styles/constants';
import apiFirebase from 'api/firebase';

const StyledContainer = styled(Container)`
  &&& {
    display: flex;
    max-width: 100%;
    height: calc(100vh - ${headerHeight}px);
    ${({ backgroundColor }: { backgroundColor?: string }) =>
      backgroundColor && `background: ${backgroundColor};`}
  }
`;

const Contents = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Spacer = styled.section`
  padding: 30px;
`;

const PrivateRouteLayout: FC<{ location: H.Location }> = ({ children }) => {
  const { subscribeNotification } = useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    const unSubscribeMessageNotification = subscribeNotification();

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (!user) {
          unSubscribeMessageNotification && unSubscribeMessageNotification();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      unSubscribeMessageNotification && unSubscribeMessageNotification();
    };
  }, [dispatch, subscribeNotification]);

  return (
    <StyledContainer>
      <Sidebar />
      <Contents>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Contents>
    </StyledContainer>
  );
};

export default PrivateRouteLayout;
