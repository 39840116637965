import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';
import React, { FC } from 'react';

type ErrorModalProps = {
  error: string | null;
};

const ErrorModal: FC<ErrorModalProps> = props => {
  const { error } = props;

  return (
    <Modal
      show={!!error}
      hideCloseButton
      closeModal={() => {
        throw new Error('non-reachable');
      }}
    >
      <ModalContent>{error}</ModalContent>
      <ModalFooter>
        <ModalButton onClick={() => window.location.reload()}>
          リロード
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
