import React, { FC } from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components/macro';

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const LinkText: FC = ({ children }) => {
  const componentDecorator = (href: string, text: string, key: number) => (
    <StyledLink href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </StyledLink>
  );
  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
};

export default LinkText;
