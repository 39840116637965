import format from 'date-fns/format';
import axios from 'axios';
// eslint-disable-next-line camelcase
import { ConsultantChatUser_AcademicBackground as AcademicBackground } from 'proto/v1/apimodel/apimodel';

export const formatChatTimestamp = (timestamp: number) =>
  format(new Date(timestamp), 'yyyy/MM/dd HH:mm');

export const formatFileSize = (size: number) => {
  // use Megabytes unit size（1MB = 1000000Byte, not 1048576Byte)
  if (size / 1000 < 1) return '1KB';
  if (size / 1000 < 1000) return `${Math.round(size / 1000)}KB`;

  return `${Math.round(size / 1000000)}MB`;
};

export const downloadChatFile = async (
  url: string,
): Promise<{ data: Blob }> => {
  const res = await axios.get(url, { responseType: 'blob' });
  return { data: res.data };
};

export const formatYearMonthOfGraduation = (
  academicBackground?: AcademicBackground,
) => {
  if (!academicBackground) {
    return;
  }

  const { yearOfGraduation, monthOfGraduation } = academicBackground;
  if (!(yearOfGraduation && monthOfGraduation)) {
    return;
  }

  return ` (${yearOfGraduation}年${monthOfGraduation}月卒業予定)`;
};
