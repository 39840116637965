import React, { FC } from 'react';
import { ConsultantChatRoom } from 'proto/v1/apimodel/apimodel';
import styled, { css } from 'styled-components/macro';
import {
  formatChatTimestamp,
  formatYearMonthOfGraduation,
} from 'utils/models/chat';
import {
  MatterStatusId,
  STATUS_DISPLAY_NAME,
} from 'constants/humanResourceMatter';

const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 78px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;

  &:hover {
    background: rgba(221, 221, 221, 0.3);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: rgba(221, 221, 221, 0.3);
    `}
`;

const ChatRoomHandled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
`;

const ChatRoomInfo = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  padding: 12px 60px 12px 0;
`;

const ChatRoomInfoProfile = styled.p`
  color: #000;
  font-size: 15px;
  line-height: 20px;
`;

const ChatRoomInfoLatestMessage = styled.p`
  overflow: hidden;
  color: #666;
  font-size: 12px;
  line-height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChatRoomLastSentAt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 310px;
  padding: 12px 0;
  color: #000;
  font-size: 14px;
  line-height: 20px;
`;

const ChatRoomStaffName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
  color: #000;
  font-size: 14px;
  line-height: 20px;
`;

const UnhandledTag = styled.div`
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 16px;
  border: 1px solid #dc2e2e;
  border-radius: 10px;
  background-color: #fff;
  color: #dc2e2e;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  white-space: nowrap;
`;

const StaffNameInput = styled.input`
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #c4c4c4;
  border-left: none;
  outline: none;
  background: #f6f6f6;
  color: #000;
  font-size: 14px;
  line-height: 20px;
`;

const StaffNameInputLabel = styled.div`
  color: #666;
  font-size: 9px;
  line-height: 13px;
`;

const UnreadCount = styled.div`
  display: inline-block;
  position: absolute;
  right: 24px;
  min-width: 26px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 18px;
  background: rgb(94, 39, 251);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
`;

type Props = {
  chatRoom: ConsultantChatRoom;
  onSelect: (chatRoomId: ConsultantChatRoom['chatRoomId']) => void;
  handled: boolean;
  isSelected: boolean;
  onChangeStaffName: (
    chatRoomId: ConsultantChatRoom['chatRoomId'],
    staffName: ConsultantChatRoom['staffName'],
  ) => Promise<void> | undefined;
};

const ChatRoom: FC<Props> = ({
  chatRoom,
  handled,
  isSelected,
  onSelect,
  onChangeStaffName,
}) => {
  return (
    <Wrapper
      data-testid="chat-room"
      onClick={() => {
        onSelect(chatRoom.chatRoomId);
      }}
      isSelected={isSelected}
    >
      <ChatRoomHandled>
        {!handled && <UnhandledTag>未対応</UnhandledTag>}
      </ChatRoomHandled>
      <ChatRoomInfo>
        {chatRoom.chatUser?.career && (
          <ChatRoomInfoProfile>
            {chatRoom.chatUser?.career.name} /{' '}
            {chatRoom.chatUser?.career.companyName} / 中途 /{' '}
            {chatRoom.chatUser.status?.id
              ? STATUS_DISPLAY_NAME[
                  chatRoom.chatUser.status.id as MatterStatusId
                ]
              : '-'}
          </ChatRoomInfoProfile>
        )}
        {chatRoom.chatUser?.newGraduate && (
          <ChatRoomInfoProfile>
            {chatRoom.chatUser?.newGraduate.name} /{' '}
            {chatRoom.chatUser?.newGraduate.academicBackground?.school?.name}
            {chatRoom.chatUser?.newGraduate.academicBackground?.faculty} / 新卒
            {formatYearMonthOfGraduation(
              chatRoom.chatUser?.newGraduate.academicBackground,
            )}{' '}
            /{' '}
            {chatRoom.chatUser.status?.id
              ? STATUS_DISPLAY_NAME[
                  chatRoom.chatUser.status.id as MatterStatusId
                ]
              : '-'}
          </ChatRoomInfoProfile>
        )}
        <ChatRoomInfoLatestMessage>
          {chatRoom.latestMessage}
        </ChatRoomInfoLatestMessage>
        {chatRoom.unreadCount > 0 && (
          <UnreadCount>{chatRoom.unreadCount}</UnreadCount>
        )}
      </ChatRoomInfo>
      <ChatRoomLastSentAt>
        {formatChatTimestamp(chatRoom.updatedAt)}
      </ChatRoomLastSentAt>
      <ChatRoomStaffName>
        <StaffNameInputLabel>氏名</StaffNameInputLabel>
        <StaffNameInput
          type="text"
          name={`staffName[${chatRoom.chatRoomId}]`}
          defaultValue={chatRoom.staffName}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={e => {
            onChangeStaffName(chatRoom.chatRoomId, e.target.value);
          }}
          aria-label="担当者名"
        />
      </ChatRoomStaffName>
    </Wrapper>
  );
};

export default ChatRoom;
