import React, { FC } from 'react';
import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';

type Props = {
  text: string;
  show: boolean;
  onClickOk: () => void;
};

const ModalAlert: FC<Props> = props => {
  const { show, onClickOk, text } = props;
  return (
    <Modal show={show} closeModal={onClickOk}>
      <ModalContent data-testid="modal-alert">{text}</ModalContent>
      <ModalFooter>
        <ModalButton onClick={onClickOk}>OK</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAlert;
