import { Method, AxiosError, Cancel } from 'axios';
import ErrorTypes from 'constants/errorTypes';
import { ApiError } from 'types/error';
import {
  isAxiosError,
  isTimeout,
  isNetworkError,
  isCanceledError,
  isClientError,
  isServerError,
} from 'utils/error';
import * as MESSAGES from 'constants/messages';

export default class RequestError extends Error {
  readonly status?: number;

  readonly method?: Method;

  readonly errorType?: ErrorTypes;

  readonly errorCode?: string;

  readonly locale?: string;

  constructor(error: AxiosError | Cancel) {
    super(RequestError._getErrorMessage(error));

    Object.setPrototypeOf(this, new.target.prototype);

    this.errorType = RequestError._getErrorType(error);

    if (!isAxiosError(error)) return;

    this.status = error?.response?.status;
    this.method = error?.config?.method;
    this.errorCode = error?.response?.data?.error?.errorCode;
    this.locale = error?.response?.data?.error?.locale;
  }

  private static _getErrorType(error: AxiosError | Cancel) {
    if (!isAxiosError(error)) {
      return isCanceledError(error)
        ? ErrorTypes.CANCELED_ERROR
        : ErrorTypes.UNEXPECTED_ERROR;
    }
    if (isTimeout(error)) return ErrorTypes.TIMEOUT;
    if (isNetworkError(error)) return ErrorTypes.NETWORK_ERROR;
    if (isClientError(error)) return ErrorTypes.CLIENT_ERROR;
    if (isServerError(error)) return ErrorTypes.SERVER_ERROR;
    if (isCanceledError(error)) return ErrorTypes.CANCELED_ERROR;

    return ErrorTypes.UNEXPECTED_ERROR;
  }

  private static _getErrorMessage(error: AxiosError | Cancel) {
    if (!isAxiosError(error)) return MESSAGES.UNKNOWN_ERROR;
    if (isTimeout(error)) return MESSAGES.TIMEOUT_ERROR;
    if (isNetworkError(error)) return MESSAGES.NETWORK_ERROR;
    if (isClientError(error)) {
      return error?.response?.data?.error?.message || MESSAGES.UNKNOWN_ERROR;
    }
    if (isServerError(error)) return MESSAGES.UNKNOWN_ERROR;

    return MESSAGES.UNKNOWN_ERROR;
  }

  public toObject(): ApiError {
    return {
      ...this,
      message: this.message,
    };
  }

  public isServerError() {
    return this.errorType === ErrorTypes.SERVER_ERROR;
  }

  public isClientError() {
    return this.errorType === ErrorTypes.CLIENT_ERROR;
  }

  public isNetworkError() {
    return this.errorType === ErrorTypes.NETWORK_ERROR;
  }

  public isUnexpectedError() {
    return this.errorType === ErrorTypes.UNEXPECTED_ERROR;
  }

  public isCanceled() {
    return this.errorType === ErrorTypes.CANCELED_ERROR;
  }

  public isTimeout() {
    return this.errorType === ErrorTypes.TIMEOUT;
  }
}
