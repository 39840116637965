import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { ConsultantChatMessage } from 'proto/v1/apimodel/apimodel';
import { formatChatTimestamp, formatFileSize } from 'utils/models/chat';
import { MessageDocument } from 'assets/svg';
import { LinkText } from 'components/atoms';
import OwnChatMessageMenu from './OwnChatMessageMenu';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-left: auto;
`;

const Message = styled.div`
  box-sizing: border-box;
  max-width: 448px;
  margin-bottom: 8px;
  padding: 16px 12px;
  border: 0.5px solid #c4c4c4;
  border-radius: 15px 0 15px 15px;
  background: #ebebeb;
  color: #000;
  font-size: 13px;
  line-height: 1.8;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const FileIcon = styled(MessageDocument)`
  margin-right: 12px;
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div``;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileName} {
      text-decoration: underline;
    }
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileSize} {
      text-decoration: underline;
    }
  }
`;

const DeletedMessage = styled.div`
  width: fit-content;
  margin-left: auto;
  padding: 3px 16px;
  border-radius: 12px;
  background-color: #dedede;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
`;

const SentAt = styled.div`
  color: #666;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
`;

type Props = {
  chatMessage: ConsultantChatMessage;
  onDownloadFile: (chatMessage: ConsultantChatMessage) => Promise<void>;
  onClickDeleteMenu: (messageId: ConsultantChatMessage['messageId']) => void;
};

type RenderMessagePayload = Props & {
  showMenuIcon: boolean;
  showMenu: boolean;
  onOpenMenu: () => void;
};

const renderMessage = ({
  chatMessage,
  onDownloadFile,
  onOpenMenu,
  showMenu,
  showMenuIcon,
  onClickDeleteMenu,
}: RenderMessagePayload) => {
  if (chatMessage.deleted) {
    return <DeletedMessage>このメッセージは削除されました</DeletedMessage>;
  }

  if (chatMessage.file) {
    return (
      <>
        <OwnChatMessageMenu
          showMenuIcon={showMenuIcon}
          showMenu={showMenu}
          onOpenMenu={onOpenMenu}
          onClickDeleteMenu={() => onClickDeleteMenu(chatMessage.messageId)}
        />
        <div>
          <FileMessage onClick={() => onDownloadFile(chatMessage)}>
            <FileIcon />
            <div>
              <FileName>{chatMessage.message}</FileName>
              <FileSize>
                ファイルサイズ: {formatFileSize(chatMessage.file.size)}
              </FileSize>
            </div>
          </FileMessage>
          <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
        </div>
      </>
    );
  }

  return (
    <>
      <OwnChatMessageMenu
        showMenuIcon={showMenuIcon}
        showMenu={showMenu}
        onOpenMenu={onOpenMenu}
        onClickDeleteMenu={() => onClickDeleteMenu(chatMessage.messageId)}
      />
      <div>
        <Message>
          <LinkText>{chatMessage.message}</LinkText>
        </Message>
        <SentAt>{formatChatTimestamp(chatMessage.sentAt)}</SentAt>
      </div>
    </>
  );
};

const OwnChatMessage: FC<Props> = props => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showMenuIcon, setShowMenuIcon] = useState<boolean>(false);
  const handleMouseEnter = useCallback(() => {
    setShowMenuIcon(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowMenuIcon(false);
    setShowMenu(false);
  }, []);

  const handleOpenMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  return (
    <Wrapper
      data-testid="own-chat-message"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {renderMessage({
        ...props,
        showMenu,
        showMenuIcon,
        onOpenMenu: handleOpenMenu,
      })}
    </Wrapper>
  );
};

export default OwnChatMessage;
