import axios from 'axios';
import {
  HumanResourceMatterStatus,
  MatterStatusId,
} from 'constants/humanResourceMatter';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { formatDate, calculateAge } from 'utils/time';

export const isWaitingForApproval = (
  request: HumanResourceMatterForConsultant,
) => request.status?.id === MatterStatusId.WaitingForApproval;

export const isApproved = (request: HumanResourceMatterForConsultant) =>
  request.status?.id === MatterStatusId.Approved;

export const isDisapproved = (request: HumanResourceMatterForConsultant) =>
  request.status?.id === MatterStatusId.Disapproved;

export const isSendBack = (request: HumanResourceMatterForConsultant) =>
  request.status?.id === MatterStatusId.SendBack;

export const isUnpublished = (request: HumanResourceMatterForConsultant) =>
  request.status?.id === MatterStatusId.Unpublished;

export const isClosed = (request: HumanResourceMatterForConsultant) =>
  request.status?.id === MatterStatusId.Closed;

export const getStatus = (request: HumanResourceMatterForConsultant) => {
  if (isClosed(request)) return HumanResourceMatterStatus.Closed;
  if (isUnpublished(request)) return HumanResourceMatterStatus.Unpublished;
  if (isSendBack(request)) return HumanResourceMatterStatus.SendBack;
  if (isDisapproved(request)) return HumanResourceMatterStatus.Disapproved;
  if (isApproved(request)) return HumanResourceMatterStatus.Approved;
  if (isWaitingForApproval(request))
    return HumanResourceMatterStatus.WaitingForApproval;
  throw Error('Unknown status type');
};

export const getAdditionalStatusInfo = (
  request: HumanResourceMatterForConsultant,
) => {
  const status = getStatus(request);
  if (status === HumanResourceMatterStatus.Closed)
    return [
      `クローズ理由: ${request.reasonForClosing?.name}`,
      `日時: ${formatDate(new Date(request.closedAt), 'yyyy/MM/dd HH:mm')}`,
    ];

  if (request.publishedAt) {
    return [
      `公開日時: ${formatDate(
        new Date(request.publishedAt),
        'yyyy/MM/dd HH:mm',
      )}`,
    ];
  }
  return [];
};

export const formatPeriods = (period: {
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}) => {
  const { startYear, startMonth, endYear, endMonth } = period;
  if (!startYear || !startMonth || !endYear || !endMonth) {
    return '-';
  }
  return `${startYear}年${startMonth}月 〜 ${endYear}年${endMonth}月`;
};

export const formatBirth = (profile?: Innovator) => {
  if (!profile) return undefined;
  return `${profile.yearOfBirth}年 ${profile.monthOfBirth}月 (${calculateAge(
    profile.yearOfBirth,
    profile.monthOfBirth,
  )}歳)`;
};

export const formatName = (profile?: Innovator) => {
  if (
    !profile?.lastName ||
    !profile?.firstName ||
    !profile?.lastNameKana ||
    !profile?.firstNameKana
  ) {
    return '-';
  }

  return `${profile.lastName} ${profile.firstName} (${profile.lastNameKana} ${profile.firstNameKana})`;
};

export const formatPhoneNumber = (profile?: Innovator) => {
  if (!profile) return undefined;
  return `${profile.phoneCountryCode} ${profile.phoneNumber}`;
};

export const formatAddress = (profile?: Innovator) => {
  if (!profile) return undefined;
  return profile.japanPrefecture?.name ?? '海外';
};

export const formatWorkLocations = (profile?: Innovator) => {
  if (!profile) return undefined;
  return profile.preferredWorkLocations
    .map(l => l.japanPrefecture?.name ?? '海外')
    .join('・');
};

export const formatPreferredOccupations = (profile?: Innovator) => {
  if (!profile) return undefined;
  return profile.preferredOccupations?.map(o => o.name).join(' / ');
};

export const formatCertifications = (profile?: Innovator) => {
  if (!profile) return undefined;
  return profile.certifications.join('、');
};

export const formatAcademicBackground = (profile?: Innovator) => {
  if (!profile?.academicBackground) return undefined;
  const { school, faculty, department, yearOfGraduation, monthOfGraduation } =
    profile.academicBackground;

  const isNewGraduate = !!profile.newGraduate;

  return `${school?.name} ${faculty} ${
    department?.name
  } ${yearOfGraduation}年${monthOfGraduation}月 ${
    isNewGraduate ? '卒業予定' : '卒業'
  }`;
};

export const formatNewGraduatePreferredBusinessTypes = (
  profile?: Innovator,
) => {
  if (!profile?.newGraduate?.preferredBusinessTypes) return undefined;
  return profile.newGraduate.preferredBusinessTypes
    .map(o => o.name)
    .join(' / ');
};

export const formatNewGraduatePastExperienceAchievements = (
  profile?: Innovator,
) => {
  if (!profile?.newGraduate?.pastExperienceAchievements) return undefined;
  return profile.newGraduate.pastExperienceAchievements
    .map(o => o.name)
    .join(' / ');
};

export const formatDtt = (profile?: Innovator) => {
  if (!profile?.dttScore || !profile?.dttRank) return undefined;
  return `${profile.dttScore} / ${profile.dttRank?.name}`;
};

export const downloadFile = async (url: string): Promise<{ data: Blob }> => {
  const res = await axios.get(url, { responseType: 'blob' });
  return { data: res.data };
};
