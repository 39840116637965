import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledModalFooter = styled.div`
  width: 100%;
  text-align: center;

  > button {
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const ModalFooter: FC<{}> = props => {
  return <StyledModalFooter>{props.children}</StyledModalFooter>;
};

export default ModalFooter;
