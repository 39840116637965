import { ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import { Button } from 'components/atoms';

type ModalButtonProps = ComponentProps<typeof Button> & {
  buttonTheme?: 'default' | 'cancel';
};

const ModalButton = styled(Button)<ModalButtonProps>`
  &&& {
    box-sizing: border-box;
    width: 200px;
    padding: 10px 16px 9px 16px;
    box-shadow: none;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    ${({ buttonTheme = 'default' }: ModalButtonProps) =>
      buttonTheme === 'cancel' &&
      css`
        color: ${theme.black};
        background-color: ${theme.white};
        border: 1px solid #dcdcdc;
      `}
  }
`;

// TODO: Remove ModalButton when Button is refactored
export default ModalButton;
