export const formatDate = (date: Date, fmt: string) => {
  let format = fmt;
  format = format.replace(/yyyy/g, String(date.getFullYear()));
  format = format.replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2));
  format = format.replace(/dd/g, `0${date.getDate()}`.slice(-2));
  format = format.replace(/HH/g, `0${date.getHours()}`.slice(-2));
  format = format.replace(/mm/g, `0${date.getMinutes()}`.slice(-2));
  format = format.replace(/ss/g, `0${date.getSeconds()}`.slice(-2));
  format = format.replace(/SSS/g, `00${date.getMilliseconds()}`.slice(-3));
  return format;
};

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  if (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  ) {
    return formatDate(date, 'HH:mm');
  }
  if (now.getFullYear() === date.getFullYear()) {
    return formatDate(date, 'MM/dd');
  }
  return formatDate(date, 'yyyy/MM/dd');
};

export const calculateAge = (year: number, month: number, day?: number) => {
  const today = new Date();
  const todayY = today.getFullYear().toString().padStart(4, '0');
  const todayM = (today.getMonth() + 1).toString().padStart(2, '0');
  const todayD = today.getDate().toString().padStart(2, '0');

  const birthDate = new Date(year, month - 1, day ?? 1); // If there is no day, the day is rounded up.
  const birthY = birthDate.getFullYear().toString().padStart(4, '0');
  const birthM = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const birthD = birthDate.getDate().toString().padStart(2, '0');

  const age = Math.floor(
    (Number(todayY + todayM + todayD) - Number(birthY + birthM + birthD)) /
      10000,
  );

  return age;
};
