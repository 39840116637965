import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'visits-style';
import { FiX } from 'react-icons/fi';
import theme from 'styles/theme';

const StyledModal = styled(Modal)<AppModalProps>`
  ${({ size = 'normal' }) =>
    size === 'full' &&
    `
      padding: 0;
    `}
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ModalBody = styled.div<AppModalProps>`
  position: relative;
  width: 700px;
  height: 400px;
  ${({ size = 'normal' }) =>
    size === 'full' &&
    `
      width: 100vw;
      height: 100vh;
    `}
  padding: 24px;
  overflow-y: auto;
  border-radius: 3px;
  background: ${theme.white};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
`;

type AppModalProps = ComponentProps<typeof Modal> & {
  size?: 'normal' | 'full';
  type?: string;
  hideCloseButton?: boolean;
};

const AppModal: FC<AppModalProps> = props => {
  props.show
    ? document.body.setAttribute('style', 'overflow: hidden;')
    : document.body.setAttribute('style', '');

  return (
    <StyledModal {...props}>
      <ModalBody {...props} className={props.type}>
        {!props.hideCloseButton && (
          <StyledIconClose onClick={props.closeModal} />
        )}
        {props.children}
      </ModalBody>
    </StyledModal>
  );
};

export default AppModal;
