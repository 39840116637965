import { CancelToken } from 'axios';
import {
  CreateConsultantRequest,
  CreateConsultantResponse,
  DeleteConsultantRequest,
  DeleteConsultantResponse,
  DeleteInnovatorRequest,
  DeleteInnovatorResponse,
  SearchHumanResourceMattersResponse,
  GetHumanResourceMatterRequest,
  GetHumanResourceMatterResponse,
  PutHumanResourceMatterMemoRequest,
  PutHumanResourceMatterMemoResponse,
  PutHumanResourceMatterStatusRequest,
  PutHumanResourceMatterStatusResponse,
  GetReasonsForClosingHumanResourceMatterResponse,
  GetMatterContactedAccountsResponse,
  GetConsultantChatRoomsRequest,
  GetConsultantChatRoomsResponse,
  PutConsultantChatRoomStaffNameRequest,
  PutConsultantChatRoomStaffNameResponse,
  PutConsultantChatRoomHandledRequest,
  PutConsultantChatRoomHandledResponse,
  GetConsultantChatMessagesRequest,
  GetConsultantChatMessagesResponse,
  PostConsultantChatMessageRequest,
  PostConsultantChatMessageResponse,
  PostConsultantChatFileRequest,
  PostConsultantChatFileResponse,
  DeleteConsultantChatMessageRequest,
  DeleteConsultantChatMessageResponse,
  GetConsultantChatRoomsRefreshRequest,
  GetConsultantChatRoomsRefreshResponse,
  FlushConsultantChatUnreadCountRequest,
  FlushConsultantChatUnreadCountResponse,
  GetScoutCompanyRequest,
  GetScoutCompanyResponse,
  PutScoutContractStatusRequest,
  PutScoutContractStatusResponse,
  GetScoutCompaniesResponse,
  GetScoutCompaniesRequest,
  PutScoutCompanyStaffNameRequest,
  PutScoutCompanyStaffNameResponse,
  PostScoutCompanyBillingAddressRequest,
  PostScoutCompanyBillingAddressResponse,
  GetScoutingHumanResourcesRequest,
  GetScoutingHumanResourcesResponse,
  PutScoutBillingStatusesRequest,
  ResendVerificationEmailRequest,
  ResendVerificationEmailResponse,
  GetHumanResourceMattersTotalCountRequest,
  GetHumanResourceMattersTotalCountResponse,
} from 'proto/v1/consultantservice/consultantservice';
import {
  SearchHumanResourceMatterRequestQuery,
  GetMatterContactedAccountsRequestQuery,
} from 'types/humanResourceMatter';
import client from './config/client';
import apiFirebase from './firebase';

export default {
  async createConsultant(
    req: CreateConsultantRequest,
  ): Promise<{ data: CreateConsultantResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultants`;
    return client(token)
      .post(url, CreateConsultantRequest.toJSON(req))
      .then(res => {
        return {
          data: CreateConsultantResponse.fromJSON(res.data),
        };
      });
  },

  async deleteConsultant(
    req: DeleteConsultantRequest,
  ): Promise<{ data: DeleteConsultantResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultants/delete`;
    return client(token)
      .post(url, DeleteConsultantRequest.toJSON(req))
      .then(res => {
        return {
          data: DeleteConsultantResponse.fromJSON(res.data),
        };
      });
  },

  async deleteInnovator(
    req: DeleteInnovatorRequest,
  ): Promise<{ data: DeleteInnovatorResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/delete_innovator`;
    return client(token)
      .post(url, DeleteInnovatorRequest.toJSON(req))
      .then(res => {
        return {
          data: DeleteInnovatorResponse.fromJSON(res.data),
        };
      });
  },

  async resendVerificationEmail(
    req: ResendVerificationEmailRequest,
  ): Promise<{ data: ResendVerificationEmailResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/resend_verification_email`;
    return client(token)
      .post(url, ResendVerificationEmailRequest.toJSON(req))
      .then(res => {
        return {
          data: ResendVerificationEmailResponse.fromJSON(res.data),
        };
      });
  },

  async searchHumanResourceMatters(
    params: SearchHumanResourceMatterRequestQuery,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: SearchHumanResourceMattersResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matters`;
    return client(token)
      .get(url, { params, cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: SearchHumanResourceMattersResponse.fromJSON(x.data),
        };
      });
  },

  async getHumanResourceMattersTotalCount(
    params: GetHumanResourceMattersTotalCountRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetHumanResourceMattersTotalCountResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matters_total_count`;
    return client(token)
      .get(url, { params, cancelToken })
      .then(x => {
        return {
          data: GetHumanResourceMattersTotalCountResponse.fromJSON(x.data),
        };
      });
  },

  async getHumanResourceMatter(
    req: GetHumanResourceMatterRequest,
    requestCancelToken?: CancelToken,
  ): Promise<{ data: GetHumanResourceMatterResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matters/${req.matterPublishId}`;
    return client(token)
      .get(url, { cancelToken: requestCancelToken })
      .then(x => {
        return {
          data: GetHumanResourceMatterResponse.fromJSON(x.data),
        };
      });
  },

  async putHumanResourceMatterMemo(
    params: PutHumanResourceMatterMemoRequest,
  ): Promise<{ data: PutHumanResourceMatterMemoResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matters/memo`;
    return client(token)
      .put(url, PutHumanResourceMatterMemoRequest.toJSON(params))
      .then(x => {
        return {
          data: PutHumanResourceMatterMemoResponse.fromJSON(x.data),
        };
      });
  },

  async putHumanResourceMatterStatus(
    params: PutHumanResourceMatterStatusRequest,
  ): Promise<{ data: PutHumanResourceMatterStatusResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matters/status`;
    return client(token)
      .put(url, PutHumanResourceMatterStatusRequest.toJSON(params))
      .then(x => {
        return {
          data: PutHumanResourceMatterStatusResponse.fromJSON(x.data),
        };
      });
  },

  async getReasonsForClosing(): Promise<{
    data: GetReasonsForClosingHumanResourceMatterResponse;
  }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/human_resource_matter_reasons_for_closings`;
    return client(token)
      .get(url)
      .then(x => {
        return {
          data: GetReasonsForClosingHumanResourceMatterResponse.fromJSON(
            x.data,
          ),
        };
      });
  },

  async getMatterContactedAccounts(
    req: GetMatterContactedAccountsRequestQuery,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMatterContactedAccountsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/matter_contacted_accounts/${req.matterId}`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(x => {
        return {
          data: GetMatterContactedAccountsResponse.fromJSON(x.data),
        };
      });
  },

  async getChatRooms(
    req: GetConsultantChatRoomsRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetConsultantChatRoomsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetConsultantChatRoomsResponse.fromJSON(res.data),
        };
      });
  },

  async putConsultantChatRoomStaffName(
    req: PutConsultantChatRoomStaffNameRequest,
  ): Promise<{ data: PutConsultantChatRoomStaffNameResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/staff_name`;
    return client(token)
      .put(url, PutConsultantChatRoomStaffNameRequest.toJSON(req))
      .then(x => {
        return {
          data: PutConsultantChatRoomStaffNameResponse.fromJSON(x.data),
        };
      });
  },

  async putConsultantChatRoomHandled(
    req: PutConsultantChatRoomHandledRequest,
  ): Promise<{ data: PutConsultantChatRoomHandledResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/handled`;
    return client(token)
      .put(url, PutConsultantChatRoomHandledRequest.toJSON(req))
      .then(x => {
        return {
          data: PutConsultantChatRoomHandledResponse.fromJSON(x.data),
        };
      });
  },

  async getConsultantChatMessages(
    req: GetConsultantChatMessagesRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetConsultantChatMessagesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/messages`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetConsultantChatMessagesResponse.fromJSON(res.data),
        };
      });
  },

  async postConsultantChatMessage(
    req: PostConsultantChatMessageRequest,
  ): Promise<{ data: PostConsultantChatMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/messages`;
    return client(token)
      .post(url, PostConsultantChatMessageRequest.toJSON(req))
      .then(res => {
        return {
          data: PostConsultantChatMessageResponse.fromJSON(res.data),
        };
      });
  },

  async postConsultantChatFile(
    req: PostConsultantChatFileRequest,
  ): Promise<{ data: PostConsultantChatFileResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/files`;
    return client(token)
      .post(url, PostConsultantChatFileRequest.toJSON(req))
      .then(res => {
        return {
          data: PostConsultantChatFileResponse.fromJSON(res.data),
        };
      });
  },

  async deleteConsultantChatMessage(
    req: DeleteConsultantChatMessageRequest,
  ): Promise<{ data: DeleteConsultantChatMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${req.chatRoomId}/messages/${req.messageId}`;
    return client(token)
      .delete(url)
      .then(x => {
        return {
          data: DeleteConsultantChatMessageResponse.fromJSON(x.data),
        };
      });
  },

  async getConsultantChatRoomsUpdates(
    req: GetConsultantChatRoomsRefreshRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetConsultantChatRoomsRefreshResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/refresh`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(x => {
        return {
          data: GetConsultantChatRoomsRefreshResponse.fromJSON(x.data),
        };
      });
  },

  async flushUnreadCount(
    data: FlushConsultantChatUnreadCountRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: FlushConsultantChatUnreadCountResponse }> {
    const { chatRoomId } = data;
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/chatrooms/${chatRoomId}/flush`;
    return client(token)
      .put(url, FlushConsultantChatUnreadCountRequest.toJSON({ chatRoomId }), {
        cancelToken,
      })
      .then(x => {
        return {
          data: FlushConsultantChatUnreadCountResponse.fromJSON(x.data),
        };
      });
  },

  async getScoutCompany(
    req: GetScoutCompanyRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetScoutCompanyResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_companies/${req.missionId}`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetScoutCompanyResponse.fromJSON(res.data),
        };
      });
  },

  async getScoutCompanies(
    params: GetScoutCompaniesRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetScoutCompaniesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_companies`;
    return client(token)
      .get(url, { params, cancelToken })
      .then(res => {
        return {
          data: GetScoutCompaniesResponse.fromJSON(res.data),
        };
      });
  },

  async putScoutContractStatus(
    req: PutScoutContractStatusRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutScoutContractStatusResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_contract_statuses/${req.missionId}`;
    return client(token)
      .put(url, PutScoutContractStatusRequest.toJSON(req), {
        cancelToken,
      })
      .then(x => {
        return {
          data: PutScoutContractStatusResponse.fromJSON(x.data),
        };
      });
  },

  async putScoutCompanyStaffName(
    req: PutScoutCompanyStaffNameRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PutScoutCompanyStaffNameResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_company_staff_names/${req.missionId}`;
    return client(token)
      .put(url, PutScoutCompanyStaffNameRequest.toJSON(req), {
        cancelToken,
      })
      .then(x => {
        return {
          data: PutScoutCompanyStaffNameResponse.fromJSON(x.data),
        };
      });
  },

  async postScoutCompanyBillingAddress(
    req: PostScoutCompanyBillingAddressRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: PostScoutCompanyBillingAddressResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_company_billing_addresses/${req.missionId}`;
    return client(token)
      .post(url, PostScoutCompanyBillingAddressRequest.toJSON(req), {
        cancelToken,
      })
      .then(x => {
        return {
          data: PostScoutCompanyBillingAddressResponse.fromJSON(x.data),
        };
      });
  },

  async getScoutingHumanResources(
    req: GetScoutingHumanResourcesRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetScoutingHumanResourcesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_companies/${req.missionId}/scouting_human_resources`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetScoutingHumanResourcesResponse.fromJSON(res.data),
        };
      });
  },

  async putScoutBillingStatuses(
    req: PutScoutBillingStatusesRequest,
  ): Promise<{ data: PutScoutContractStatusResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/consultant/scout_billing_statuses`;
    return client(token)
      .put(url, PutScoutBillingStatusesRequest.toJSON(req))
      .then(x => {
        return {
          data: PutScoutContractStatusResponse.fromJSON(x.data),
        };
      });
  },
};
