import { useCallback, useEffect, useState } from 'react';
import api from 'api/consultant';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { MatterStatusId } from 'constants/humanResourceMatter';
import { ReasonForClosingHumanResourcesMatter } from 'proto/v1/apimodel/apimodel';

const FAILED_TO_CHANGE_STATUS = 'ステータスの更新に失敗しました。';
const FAILED_TO_GET_LATEST_STATUS = '最新の状態の取得に失敗しました。';
const FAILED_TO_GETTING_ANY = '読み込みに失敗しました。';

const useChangeStatus = (
  matter: HumanResourceMatterForConsultant,
  applyLatest: (target: HumanResourceMatterForConsultant) => void,
) => {
  const [error, setError] = useState<string | null>(null);
  const [reasonsForClosing, setReasonsForClosing] = useState<
    ReasonForClosingHumanResourcesMatter[]
  >([]);

  const updateState = useCallback(async () => {
    let data;
    try {
      ({ data } = await api.getHumanResourceMatter({
        matterPublishId: matter.matterPublishId,
      }));
    } catch (e) {
      setError(FAILED_TO_GET_LATEST_STATUS);
      return;
    }
    if (!data.matter) {
      setError(FAILED_TO_GET_LATEST_STATUS);
      return;
    }
    applyLatest(data.matter);
  }, [applyLatest, matter.matterPublishId]);

  const approve = useCallback(async () => {
    setError(null);
    try {
      await api.putHumanResourceMatterStatus({
        matterId: matter.matterId,
        statusId: MatterStatusId.Approved,
        sendBackReason: undefined,
        reasonForClosingId: undefined,
      });
    } catch (e) {
      setError(FAILED_TO_CHANGE_STATUS);
      return;
    }
    await updateState();
  }, [matter.matterId, updateState]);

  const disapprove = useCallback(async () => {
    setError(null);
    try {
      await api.putHumanResourceMatterStatus({
        matterId: matter.matterId,
        statusId: MatterStatusId.Disapproved,
        sendBackReason: undefined,
        reasonForClosingId: undefined,
      });
    } catch (e) {
      setError(FAILED_TO_CHANGE_STATUS);
      return;
    }
    await updateState();
  }, [matter.matterId, updateState]);

  const sendback = useCallback(
    async (reason: string) => {
      setError(null);
      try {
        await api.putHumanResourceMatterStatus({
          matterId: matter.matterId,
          statusId: MatterStatusId.SendBack,
          sendBackReason: reason,
          reasonForClosingId: undefined,
        });
      } catch (e) {
        setError(FAILED_TO_CHANGE_STATUS);
        return;
      }
      await updateState();
    },
    [matter.matterId, updateState],
  );

  const close = useCallback(
    async (reasonId: number) => {
      setError(null);
      try {
        await api.putHumanResourceMatterStatus({
          matterId: matter.matterId,
          statusId: MatterStatusId.Closed,
          reasonForClosingId: reasonId,
          sendBackReason: undefined,
        });
      } catch (e) {
        setError(FAILED_TO_CHANGE_STATUS);
        return;
      }
      await updateState();
    },
    [matter.matterId, updateState],
  );

  useEffect(() => {
    const getReasonsForClosing = async () => {
      setError(null);
      let data;
      try {
        ({ data } = await api.getReasonsForClosing());
      } catch (e) {
        setError(FAILED_TO_GETTING_ANY);
        return;
      }
      setReasonsForClosing(data.reasons);
    };
    getReasonsForClosing();
  }, []);

  return {
    error,
    close,
    approve,
    sendback,
    disapprove,
    reasonsForClosing,
  };
};

export default useChangeStatus;
