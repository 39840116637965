import axios, { AxiosInstance } from 'axios';
import handleError from './handleError';

const client = (token?: string, locale?: string): AxiosInstance => {
  const axiosClient = axios.create();
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    axiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  }
  if (locale) {
    axiosClient.defaults.headers['X-Locale'] = locale;
  }

  axiosClient.interceptors.response.use(response => response, handleError);
  return axiosClient;
};

export default client;
