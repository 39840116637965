import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'visits-style';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { getStatus } from 'utils/models/humanResourceMatter';
import {
  HumanResourceMatterStatus as EnumStatus,
  STATUS_COLORS,
} from 'constants/humanResourceMatter';
import { ButtonContainer } from 'components/atoms';
import useChangeStatus from './useChangeStatus';
import {
  ApproveModal,
  DisapproveModal,
  SendBackModal,
  CloseModal,
} from './Modals';
import { ErrorModal } from '../Modals';

const Wrapper = styled(ButtonContainer)`
  padding: 16px 0;
`;

type StyledButtonProps = {
  color: string;
  background: string;
  borderColor?: string;
};

const StyledButton = styled(Button)<StyledButtonProps>`
  width: 160px;
  padding: 8px 24px;
  ${({ color, background, borderColor }) => css`
    color: ${color};
    background: ${background};
    ${borderColor &&
    css`
      border: 1px solid ${borderColor};
    `}

    &:hover {
      color: ${color};
      background: ${background};
      opacity: 0.8;
    }
  `}

  &:not(:first-child) {
    margin-left: 32px;
  }
`;

const Message = styled.div`
  color: darkgray;
  font-size: 0.9rem;
`;

type HumanResourceMatterButtonAreaProps = {
  matter: HumanResourceMatterForConsultant;
  applyLatest: (latest: HumanResourceMatterForConsultant) => void;
};

const HumanResourceMatterButtonArea: FC<HumanResourceMatterButtonAreaProps> = ({
  matter,
  applyLatest,
}) => {
  const { error, close, approve, sendback, disapprove, reasonsForClosing } =
    useChangeStatus(matter, applyLatest);
  const status = getStatus(matter);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);
  const [showSendBackModal, setShowSendBackModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);

  const renderApprovedButton = () => (
    <StyledButton
      {...STATUS_COLORS[EnumStatus.Approved]}
      onClick={() => setShowApproveModal(true)}
      data-testid="approve-button"
    >
      承認
    </StyledButton>
  );

  const renderDisapproveButton = () => (
    <StyledButton
      {...STATUS_COLORS[EnumStatus.Disapproved]}
      onClick={() => setShowDisapproveModal(true)}
      data-testid="disapprove-button"
    >
      非承認
    </StyledButton>
  );

  const renderSendBackButton = () => (
    <StyledButton
      {...STATUS_COLORS[EnumStatus.SendBack]}
      onClick={() => setShowSendBackModal(true)}
      data-testid="sendback-button"
    >
      差し戻し
    </StyledButton>
  );

  const renderCloseButton = () => (
    <StyledButton
      {...STATUS_COLORS[EnumStatus.Closed]}
      onClick={() => setShowCloseModal(true)}
      data-testid="close-button"
    >
      クローズ
    </StyledButton>
  );

  return (
    <>
      <Wrapper data-testid="status-buttons">
        {status === EnumStatus.WaitingForApproval && (
          <>
            {renderApprovedButton()}
            {renderDisapproveButton()}
            {renderSendBackButton()}
          </>
        )}
        {status === EnumStatus.Approved && <>{renderCloseButton()}</>}
        {status === EnumStatus.Unpublished && (
          <Message>{'この案件は会員が非公開に設定しました。'}</Message>
        )}
      </Wrapper>

      <ApproveModal
        show={showApproveModal}
        onCancel={() => setShowApproveModal(false)}
        onOk={() => {
          approve();
          setShowApproveModal(false);
        }}
      />

      <DisapproveModal
        show={showDisapproveModal}
        onCancel={() => setShowDisapproveModal(false)}
        onOk={() => {
          disapprove();
          setShowDisapproveModal(false);
        }}
      />

      <SendBackModal
        show={showSendBackModal}
        onCancel={() => setShowSendBackModal(false)}
        onOk={(reason: string) => {
          sendback(reason);
          setShowSendBackModal(false);
        }}
      />

      <CloseModal
        show={showCloseModal}
        onCancel={() => setShowCloseModal(false)}
        onOk={(reasonId: number) => {
          close(reasonId);
          setShowCloseModal(false);
        }}
        reasons={reasonsForClosing}
      />

      <ErrorModal error={error} />
    </>
  );
};

export default HumanResourceMatterButtonArea;
