import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isAccountLoggedIn, getEmail } from 'modules/account';
import { TopBar } from '@shopify/polaris';

export function Header() {
  const isLoggedIn = useSelector(isAccountLoggedIn);
  const email = useSelector(getEmail);
  const [connected, setConnected] = useState(false);
  const toggleConnection = useCallback(() => {
    setConnected(!connected);
  }, [connected]);
  const name = email || '';
  const userMenu = (
    <TopBar.UserMenu
      actions={[]}
      name={name}
      initials={name.charAt(0)}
      avatar={undefined}
      open={false}
      onToggle={toggleConnection}
    />
  );

  return isLoggedIn ? <TopBar userMenu={userMenu} /> : <TopBar />;
}

export default withRouter(Header);
