import { createGlobalStyle } from 'styled-components/macro';
import { normalizeStyle } from 'visits-style';
import colors from './colors';

export default createGlobalStyle`
  ${normalizeStyle}

  body {
    position: relative;
    background-color: ${colors.bodyColor};
    font-family: "Roboto",
      YakuHanJP,
      "Noto Sans JP",
      Helvetica,
      Arial,
      "Hiragino sans",
      "ヒラギノ角ゴシック",
      "Hiragino Kaku Gothic ProN",
      "Hiragino Kaku Gothic Pro",
      "游ゴシック体",
      "YuGothic",
      "游ゴシック",
      "Yu Gothic",
      "メイリオ",
      sans-serif;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
  }

  a {
    color: ${colors.primary};
  }

  * {
    font-size: 14px;
  }
`;
