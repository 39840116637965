import styled from 'styled-components/macro';
import { Textarea } from 'visits-style';

const StyledTextarea = styled(Textarea)`
  textarea {
    min-height: 152px;
    border: 1px solid #dcdcdc;
    font-size: 14px;
    line-height: 1.5;

    &:hover,
    &:focus,
    &:active {
      border-color: #dcdcdc;
      box-shadow: none;
    }

    &::placeholder {
      color: #dcdcdc;
    }
  }

  &:hover {
    textarea:not(:disabled):not(:focus) {
      border-color: #dcdcdc;
    }
  }
`;

export default StyledTextarea;
