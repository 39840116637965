import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { BsThreeDots } from 'react-icons/bs';

const MenuContainer = styled.div`
  position: relative;
  top: -12px;
  bottom: 0;
`;

const MenuIcon = styled.div`
  display: inline-block;
  margin-right: 8px;
  padding: 4px;
  border-radius: 50%;
  background: #ddd;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Menu = styled.div`
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 48px;
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
`;

const MenuItem = styled.div`
  font-size: 12px;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Props = {
  showMenuIcon: boolean;
  showMenu: boolean;
  onOpenMenu: () => void;
  onClickDeleteMenu: () => void;
};

const OwnChatMessageMenu: FC<Props> = ({
  showMenu,
  showMenuIcon,
  onOpenMenu,
  onClickDeleteMenu,
}) => {
  return (
    <MenuContainer>
      {showMenu && (
        <Menu data-testid="own-chat-message-menu">
          <MenuItem onClick={onClickDeleteMenu}>削除する</MenuItem>
        </Menu>
      )}
      {showMenuIcon && (
        <MenuIcon onClick={onOpenMenu} data-testid="own-chat-message-menu-icon">
          <BsThreeDots size={24} />
        </MenuIcon>
      )}
    </MenuContainer>
  );
};

export default OwnChatMessageMenu;
