import { useState, useCallback } from 'react';

const useModalAlert = () => {
  const [modalAlertState, setModalState] = useState<{
    show: boolean;
    text: string;
  }>({
    show: false,
    text: '',
  });

  const openModalAlert = useCallback(
    (text: string) => {
      setModalState({
        text,
        show: true,
      });
    },
    [setModalState],
  );

  const closeModalAlert = useCallback(() => {
    setModalState({ show: false, text: '' });
  }, [setModalState]);

  return {
    modalAlertState,
    openModalAlert,
    closeModalAlert,
  };
};

export default useModalAlert;
