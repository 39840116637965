import React, { HTMLAttributes } from 'react';
import { LinkText } from 'components/atoms';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: grid;
  grid-template:
    '... ...... ... ......  ...' 0
    '... label  ... value   ...' auto
    '... ...... ... ......  ...' 0
    / 0 120px 20px 1fr 0;
  width: 100%;
  height: fit-content;
  padding: 8px 14px;
  background: white;
  line-height: 1.5;

  &:nth-child(even) {
    background: #f3f4f7;
  }
`;

const Label = styled.div`
  grid-area: label;
  margin: auto 0;
  color: #9698a4;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const Value = styled.div`
  grid-area: value;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

type HumanResourceMatterProfileItemProps<T> = {
  label: string;
  value?: T;
  renderItem?: (value: T) => JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

type HumanResourceMatterProfileItemType = <T>(
  props: HumanResourceMatterProfileItemProps<T>,
) => React.ReactElement<HumanResourceMatterProfileItemProps<T>>;

const HumanResourceMatterProfileItem: HumanResourceMatterProfileItemType =
  props => {
    const { label, value, renderItem, ...rest } = props;
    const render = (v: typeof value) => (v && renderItem ? renderItem(v) : v);
    return (
      <Layout {...rest}>
        <Label>{label}</Label>
        <LinkText>
          <Value>{render(value)}</Value>
        </LinkText>
      </Layout>
    );
  };

export default HumanResourceMatterProfileItem;
