import styled from 'styled-components/macro';
import { Field as OrgField } from 'visits-style';

const Field = styled(OrgField)`
  width: 100%;
  max-width: 400px;
  min-height: 88px;
  margin-right: auto;
  margin-left: auto;

  h5 {
    font-size: 14px;
  }
`;

export default Field;
