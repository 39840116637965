import { useCallback } from 'react';
import { saveAs } from 'file-saver';
import { downloadFile } from 'utils/models/humanResourceMatter';
import { ResumeFile } from 'proto/v1/apimodel/apimodel';

const ERROR_MESSAGE_FAILED_TO_DOWNLOAD_FILE = `ダウンロードに失敗しました`;

type Payload = {
  openModalAlert: (text: string) => void;
};

const useResumeFile = ({ openModalAlert }: Payload) => {
  const handleDownloadFile = useCallback(
    async (resumeFile: ResumeFile) => {
      const { url, name } = resumeFile;
      if (!url) return;
      let data: Blob | undefined;
      try {
        ({ data } = await downloadFile(url));
      } catch (error) {
        openModalAlert(ERROR_MESSAGE_FAILED_TO_DOWNLOAD_FILE);
        return;
      }

      if (data) {
        saveAs(data, name);
      } else {
        openModalAlert(ERROR_MESSAGE_FAILED_TO_DOWNLOAD_FILE);
      }
    },
    [openModalAlert],
  );

  return {
    handleDownloadFile,
  };
};

export default useResumeFile;
