import React, { FC } from 'react';
import styled from 'styled-components/macro';

type LabelProps = {
  color: string;
  background: string;
  borderColor?: string;
};

const StyledLabel = styled.div<LabelProps>`
  display: inline-block;
  box-sizing: border-box;
  width: fit-content;
  min-width: 80px;
  padding: 8px;
  border: ${({ borderColor, background }) =>
    `1px solid ${borderColor ?? background}`};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: 0.8rem;
  text-align: center;
`;

const Label: FC<LabelProps> = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

export default Label;
