import { ReasonForClosingHumanResourcesMatter } from 'proto/v1/apimodel/apimodel';
import {
  Modal,
  ModalButton,
  ModalContent,
  ModalFooter,
} from 'components/molecules';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Field, Selectbox } from 'components/atoms';
import { useFormik } from 'formik';
import { number, object } from 'yup';

const StyledSelectbox = styled(Selectbox)`
  width: 100%;
`;

type CloseModalProps = {
  show: boolean;
  onCancel: () => void;
  onOk: (reasonId: number) => void;
  reasons: ReasonForClosingHumanResourcesMatter[];
};

const CloseModal: FC<CloseModalProps> = props => {
  const { show, onCancel, onOk, reasons } = props;
  const { values, setFieldValue, handleBlur, dirty, isValid } = useFormik<{
    reasonId: number;
  }>({
    initialValues: { reasonId: -1 },
    validationSchema: object({ reasonId: number().notOneOf([-1]) }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });
  return (
    <Modal show={show} closeModal={onCancel}>
      <ModalContent data-testid="close-modal">
        <Field label="クローズ理由を選択してください。">
          <StyledSelectbox
            options={[{ id: -1, name: '---' }, ...reasons]}
            idName={'id'}
            textName={'name'}
            onChange={event =>
              setFieldValue('reasonId', parseInt(event.target.value, 10))
            }
            onBlur={handleBlur}
            aria-label="クローズ理由"
          />
        </Field>
      </ModalContent>
      <ModalFooter>
        <ModalButton buttonTheme="cancel" onClick={onCancel}>
          キャンセル
        </ModalButton>
        <ModalButton
          disabled={!dirty || !isValid}
          onClick={() => onOk(values.reasonId)}
        >
          OK
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default CloseModal;
