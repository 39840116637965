import React, { FC, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import { Switch } from 'visits-style';

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  > label {
    margin-right: 8px;
    color: ${({ disabled }) => (disabled ? '#9698A4' : '#666666')};
    font-size: 12px;
    line-height: 17px;
  }

  > span {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;
      `}
  }

  > span > label {
    min-width: 44px;
    height: 24px;
    border-width: 0;
    border-radius: 12px;
    background: #c7c7c7;
    box-shadow: none !important;

    ::after {
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-width: 0;
      box-shadow: none;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        cursor: default;
      `}
  }

  input:checked ~ label {
    background: ${({ disabled }) => (disabled ? '#c7c7c7' : '#F25C5C')};

    ::after {
      left: calc(100% - 22px);
    }
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  disabled?: boolean;
};

const StyledSwitch: FC<Props> = props => {
  const { label, disabled = false, ...attrs } = props;
  return (
    <Wrapper disabled={disabled}>
      {label && <label>{label}</label>}
      <Switch {...attrs} disabled={disabled} />
    </Wrapper>
  );
};

export default StyledSwitch;
