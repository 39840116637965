import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { BlockCompany } from 'proto/v1/apimodel/apimodel';
import scrollbar from 'styles/scrollbar';

const Layout = styled.div`
  display: grid;
  grid-template:
    '...  ....  ...'
    '...  head  ...' auto
    '...  ....  ...'
    '...  body  ...' 1fr
    '...  ....  ...'
    / 0 1fr 0;
  height: 100%;
`;

const Head = styled.div`
  grid-area: head;
  padding: 10px;
  background: midnightblue;
  color: white;
  font-size: 0.8rem;
`;

const Body = styled.div`
  ${scrollbar}
  display: flex;
  grid-area: body;
  flex-direction: column;
  padding: 12px;
  overflow-y: scroll;
`;

const ListItem = styled.div`
  line-height: 1.5;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

type HumanResourceMatterBlockListProps = {
  blockCompanies: BlockCompany[];
};

const HumanResourceMatterBlockList: FC<HumanResourceMatterBlockListProps> = ({
  blockCompanies,
}) => {
  return (
    <Layout>
      <Head>ブロック企業</Head>
      <Body>
        {blockCompanies.length ? (
          blockCompanies.map((company, i) => (
            <ListItem key={i.toString()}>{company.nameLike}</ListItem>
          ))
        ) : (
          <ListItem>-</ListItem>
        )}
      </Body>
    </Layout>
  );
};

export default HumanResourceMatterBlockList;
