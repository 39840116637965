import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Card,
  TextStyle,
  Link,
  Stack,
  Icon,
  Toast,
  Tooltip,
} from '@shopify/polaris';
import { ClipboardMinor } from '@shopify/polaris-icons';
import { ScoutCompanyBillingAddress } from 'proto/v1/apimodel/apimodel';

const IconWrapper = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Props = {
  scoutCompanyBillingAddress: ScoutCompanyBillingAddress | undefined;
  onOpenEditScoutCompanyBillingAddressModal: () => void;
};

const ScoutCompanyBillingAddressCard: FC<Props> = ({
  scoutCompanyBillingAddress,
  onOpenEditScoutCompanyBillingAddressModal,
}) => {
  const [showCopiedToast, setShowCopiedToast] = useState<boolean>(false);

  return (
    <>
      <Card
        title="請求先"
        actions={[
          {
            content: '編集',
            accessibilityLabel: '請求先編集',
            onAction: onOpenEditScoutCompanyBillingAddressModal,
          },
        ]}
      >
        <Card.Section title="担当者">
          {scoutCompanyBillingAddress?.lastName &&
          scoutCompanyBillingAddress?.firstName ? (
            <TextStyle>{`${scoutCompanyBillingAddress?.lastName} ${scoutCompanyBillingAddress?.firstName}`}</TextStyle>
          ) : (
            <TextStyle variation="subdued">未入力</TextStyle>
          )}
        </Card.Section>
        <Card.Section title="役職">
          {scoutCompanyBillingAddress?.officialPosition ? (
            <TextStyle>
              {scoutCompanyBillingAddress?.officialPosition}
            </TextStyle>
          ) : (
            <TextStyle variation="subdued">未入力</TextStyle>
          )}
        </Card.Section>
        <Card.Section title="Email">
          <Stack distribution="equalSpacing">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              url={`mailto:${scoutCompanyBillingAddress?.email}`}
              removeUnderline
            >
              {scoutCompanyBillingAddress?.email}
            </Link>
            <Tooltip content="Copy email">
              <CopyToClipboard
                text={scoutCompanyBillingAddress?.email ?? ''}
                onCopy={() => {
                  setShowCopiedToast(true);
                }}
              >
                <IconWrapper>
                  <Icon source={ClipboardMinor} color="base" />
                </IconWrapper>
              </CopyToClipboard>
            </Tooltip>
          </Stack>
        </Card.Section>
        <Card.Section title="電話番号">
          {scoutCompanyBillingAddress?.phoneNumber ? (
            <TextStyle>{scoutCompanyBillingAddress?.phoneNumber}</TextStyle>
          ) : (
            <TextStyle variation="subdued">未入力</TextStyle>
          )}
        </Card.Section>
        <Card.Section title="住所">
          {scoutCompanyBillingAddress?.address ? (
            <TextStyle>{scoutCompanyBillingAddress?.address}</TextStyle>
          ) : (
            <TextStyle variation="subdued">未入力</TextStyle>
          )}
        </Card.Section>
      </Card>
      {showCopiedToast && (
        <Toast
          content="コピーしました"
          onDismiss={() => setShowCopiedToast(false)}
        />
      )}
    </>
  );
};

export default ScoutCompanyBillingAddressCard;
