enum ErrorTypes {
  SERVER_ERROR = 'SERVER_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  CANCELED_ERROR = 'CANCELED_ERROR',
  TIMEOUT = 'TIMEOUT',
}

export default ErrorTypes;
