import styled from 'styled-components/macro';
import { TextInput as OrgTextInput } from 'visits-style';

const TextInput = styled(OrgTextInput)`
  height: 40px;

  input {
    font-size: 14px;
  }
`;

export default TextInput;
