import { ReasonForClosingHumanResourcesMatter, Innovator, MatterPublishStatus, BlockCompany, MissionCategory, CorporateAttribute, Fund, Location, MemberRange, Mission, ConsultantChatRoom, ConsultantChatMessage, ScoutCompany, ScoutCompanyBillingAddress, ScoutContractStatus, ScoutBillingStatus, SelectionStep } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { Int32Value, BoolValue, StringValue } from '../../../proto/google/protobuf/wrappers';


export interface CreateConsultantRequest {
  email: string;
  languageId: number;
}

export interface CreateConsultantResponse {
  userId: string;
  accountId: string;
  consultantId: string;
  oobCode: string;
}

export interface DeleteConsultantRequest {
  email: string;
}

export interface DeleteConsultantResponse {
  accountId: string;
  consultantId: string;
}

export interface ResendVerificationEmailRequest {
  email: string;
}

export interface ResendVerificationEmailResponse {
}

export interface GetHumanResourceMatterRequest {
  matterPublishId: number;
}

export interface GetHumanResourceMatterResponse {
  matter: HumanResourceMatterForConsultant | undefined;
}

export interface SearchHumanResourceMattersRequest {
  statusId: number | undefined;
  favorite: boolean | undefined;
  limit: number;
  q: string;
  career: boolean | undefined;
  newGraduate: boolean | undefined;
  outSourcing: boolean | undefined;
  deleted: boolean | undefined;
}

export interface SearchHumanResourceMattersResponse {
  matters: HumanResourceMatterForConsultant[];
  nextQ: string;
}

export interface GetHumanResourceMattersTotalCountRequest {
  statusId: number | undefined;
  favorite: boolean | undefined;
  career: boolean | undefined;
  newGraduate: boolean | undefined;
  outSourcing: boolean | undefined;
  deleted: boolean | undefined;
}

export interface GetHumanResourceMattersTotalCountResponse {
  count: number;
}

export interface HumanResourceMatterForConsultant {
  matterId: string;
  publishedAt: number;
  closedAt: number;
  reasonForClosing: ReasonForClosingHumanResourcesMatter | undefined;
  profile: Innovator | undefined;
  status: MatterPublishStatus | undefined;
  memo: string;
  staffId: string;
  staffEmail: string;
  blockCompanies: BlockCompany[];
  favorite: boolean;
  innovatorEmail: string;
  matterPublishId: number;
  categories: MissionCategory[];
  corporateAttributes: CorporateAttribute[];
  funds: Fund[];
  locations: Location[];
  memberRanges: MemberRange[];
  accountId: string;
}

export interface PutHumanResourceMatterStatusRequest {
  matterId: string;
  statusId: number;
  sendBackReason: string | undefined;
  reasonForClosingId: number | undefined;
}

export interface PutHumanResourceMatterStatusResponse {
}

export interface GetReasonsForClosingHumanResourceMatterRequest {
}

export interface GetReasonsForClosingHumanResourceMatterResponse {
  reasons: ReasonForClosingHumanResourcesMatter[];
}

export interface PutHumanResourceMatterMemoRequest {
  matterId: string;
  memo: string;
}

export interface PutHumanResourceMatterMemoResponse {
}

export interface GetMatterContactedAccountsRequest {
  matterId: string;
  limit: number;
  q: string;
}

export interface GetMatterContactedAccountsResponse {
  contactedAccounts: GetMatterContactedAccountsResponse_ContactedAccount[];
  paging: Paging | undefined;
}

export interface GetMatterContactedAccountsResponse_ContactedAccount {
  mission: Mission | undefined;
  contactedAt: number;
}

export interface Paging {
  nextQ: string;
}

export interface GetConsultantChatRoomsRequest {
  limit: number;
  chatRoomIdBefore: string;
  updatedAtBefore: number;
  handled: boolean | undefined;
  career: boolean | undefined;
  newGraduate: boolean | undefined;
  innovatorName: string | undefined;
  statusId: number | undefined;
}

export interface GetConsultantChatRoomsResponse {
  chatRooms: ConsultantChatRoom[];
}

export interface GetConsultantChatRoomsRefreshRequest {
  limit: number;
  chatRoomIdAfter: string;
  updatedAtAfter: number;
  handled: boolean | undefined;
  career: boolean | undefined;
  newGraduate: boolean | undefined;
  innovatorName: string | undefined;
  statusId: number | undefined;
}

export interface GetConsultantChatRoomsRefreshResponse {
  chatRooms: ConsultantChatRoom[];
}

export interface GetConsultantChatMessagesRequest {
  chatRoomId: string;
  limit: number;
  messageIdBefore: number | undefined;
  messageIdAfter: number | undefined;
}

export interface GetConsultantChatMessagesResponse {
  messages: ConsultantChatMessage[];
  prevQuery: string;
  nextQuery: string;
}

export interface PostConsultantChatMessageRequest {
  chatRoomId: string;
  message: string;
}

export interface PostConsultantChatMessageResponse {
}

export interface PostConsultantChatFileRequest {
  chatRoomId: string;
  data: string;
  fileName: string;
}

export interface PostConsultantChatFileResponse {
}

export interface DeleteConsultantChatMessageRequest {
  chatRoomId: string;
  messageId: number;
}

export interface DeleteConsultantChatMessageResponse {
}

export interface FlushConsultantChatUnreadCountRequest {
  chatRoomId: string;
}

export interface FlushConsultantChatUnreadCountResponse {
}

export interface PutConsultantChatRoomStaffNameRequest {
  chatRoomId: string;
  staffName: string;
}

export interface PutConsultantChatRoomStaffNameResponse {
}

export interface PutConsultantChatRoomHandledRequest {
  chatRoomId: string;
  handled: boolean;
}

export interface PutConsultantChatRoomHandledResponse {
}

export interface GetScoutCompaniesRequest {
  limit: number;
  q: string;
  scoutContractStatusFilterId: string | undefined;
}

export interface GetScoutCompaniesResponse {
  scoutCompanies: ScoutCompany[];
  paging: Paging | undefined;
}

export interface GetScoutCompanyRequest {
  missionId: string;
}

export interface GetScoutCompanyResponse {
  form: GetScoutCompanyResponse_Form | undefined;
  scoutCompany: ScoutCompany | undefined;
  scoutCompanyBillingAddress: ScoutCompanyBillingAddress | undefined;
}

export interface GetScoutCompanyResponse_Form {
  scoutContractStatuses: ScoutContractStatus[];
}

export interface GetScoutingHumanResourcesRequest {
  missionId: string;
  scoutBillingStatus: number | undefined;
  limit: number;
  offset: number;
}

export interface GetScoutingHumanResourcesResponse {
  scoutingHumanResources: GetScoutingHumanResourcesResponse_ScoutingHumanResource[];
  total: number;
}

export interface GetScoutingHumanResourcesResponse_ScoutingHumanResource {
  scoutId: string;
  lastName: string;
  firstName: string;
  joinCompanyScheduleAt: number;
  scoutBillingStatus: ScoutBillingStatus | undefined;
  selectionStep: SelectionStep | undefined;
}

export interface PutScoutContractStatusRequest {
  missionId: string;
  scoutContractStatus: number;
}

export interface PutScoutContractStatusResponse {
}

export interface PutScoutCompanyStaffNameRequest {
  missionId: string;
  staffName: string;
}

export interface PutScoutCompanyStaffNameResponse {
}

export interface PostScoutCompanyBillingAddressRequest {
  missionId: string;
  lastName: string;
  firstName: string;
  officialPosition: string;
  email: string;
  phoneNumber: string;
  address: string;
}

export interface PostScoutCompanyBillingAddressResponse {
}

export interface PutScoutBillingStatusesRequest {
  scoutIds: string[];
  scoutBillingStatus: number;
}

export interface PutScoutBillingStatusesResponse {
}

export interface DeleteInnovatorRequest {
  email: string;
}

export interface DeleteInnovatorResponse {
  accountId: string;
  innovatorId: string;
}

const baseCreateConsultantRequest: object = {
  email: "",
  languageId: 0,
};

const baseCreateConsultantResponse: object = {
  userId: "",
  accountId: "",
  consultantId: "",
  oobCode: "",
};

const baseDeleteConsultantRequest: object = {
  email: "",
};

const baseDeleteConsultantResponse: object = {
  accountId: "",
  consultantId: "",
};

const baseResendVerificationEmailRequest: object = {
  email: "",
};

const baseResendVerificationEmailResponse: object = {
};

const baseGetHumanResourceMatterRequest: object = {
  matterPublishId: 0,
};

const baseGetHumanResourceMatterResponse: object = {
  matter: undefined,
};

const baseSearchHumanResourceMattersRequest: object = {
  statusId: undefined,
  favorite: undefined,
  limit: 0,
  q: "",
  career: undefined,
  newGraduate: undefined,
  outSourcing: undefined,
  deleted: undefined,
};

const baseSearchHumanResourceMattersResponse: object = {
  matters: undefined,
  nextQ: "",
};

const baseGetHumanResourceMattersTotalCountRequest: object = {
  statusId: undefined,
  favorite: undefined,
  career: undefined,
  newGraduate: undefined,
  outSourcing: undefined,
  deleted: undefined,
};

const baseGetHumanResourceMattersTotalCountResponse: object = {
  count: 0,
};

const baseHumanResourceMatterForConsultant: object = {
  matterId: "",
  publishedAt: 0,
  closedAt: 0,
  reasonForClosing: undefined,
  profile: undefined,
  status: undefined,
  memo: "",
  staffId: "",
  staffEmail: "",
  blockCompanies: undefined,
  favorite: false,
  innovatorEmail: "",
  matterPublishId: 0,
  categories: undefined,
  corporateAttributes: undefined,
  funds: undefined,
  locations: undefined,
  memberRanges: undefined,
  accountId: "",
};

const basePutHumanResourceMatterStatusRequest: object = {
  matterId: "",
  statusId: 0,
  sendBackReason: undefined,
  reasonForClosingId: undefined,
};

const basePutHumanResourceMatterStatusResponse: object = {
};

const baseGetReasonsForClosingHumanResourceMatterRequest: object = {
};

const baseGetReasonsForClosingHumanResourceMatterResponse: object = {
  reasons: undefined,
};

const basePutHumanResourceMatterMemoRequest: object = {
  matterId: "",
  memo: "",
};

const basePutHumanResourceMatterMemoResponse: object = {
};

const baseGetMatterContactedAccountsRequest: object = {
  matterId: "",
  limit: 0,
  q: "",
};

const baseGetMatterContactedAccountsResponse: object = {
  contactedAccounts: undefined,
  paging: undefined,
};

const baseGetMatterContactedAccountsResponse_ContactedAccount: object = {
  mission: undefined,
  contactedAt: 0,
};

const basePaging: object = {
  nextQ: "",
};

const baseGetConsultantChatRoomsRequest: object = {
  limit: 0,
  chatRoomIdBefore: "",
  updatedAtBefore: 0,
  handled: undefined,
  career: undefined,
  newGraduate: undefined,
  innovatorName: undefined,
  statusId: undefined,
};

const baseGetConsultantChatRoomsResponse: object = {
  chatRooms: undefined,
};

const baseGetConsultantChatRoomsRefreshRequest: object = {
  limit: 0,
  chatRoomIdAfter: "",
  updatedAtAfter: 0,
  handled: undefined,
  career: undefined,
  newGraduate: undefined,
  innovatorName: undefined,
  statusId: undefined,
};

const baseGetConsultantChatRoomsRefreshResponse: object = {
  chatRooms: undefined,
};

const baseGetConsultantChatMessagesRequest: object = {
  chatRoomId: "",
  limit: 0,
  messageIdBefore: undefined,
  messageIdAfter: undefined,
};

const baseGetConsultantChatMessagesResponse: object = {
  messages: undefined,
  prevQuery: "",
  nextQuery: "",
};

const basePostConsultantChatMessageRequest: object = {
  chatRoomId: "",
  message: "",
};

const basePostConsultantChatMessageResponse: object = {
};

const basePostConsultantChatFileRequest: object = {
  chatRoomId: "",
  data: "",
  fileName: "",
};

const basePostConsultantChatFileResponse: object = {
};

const baseDeleteConsultantChatMessageRequest: object = {
  chatRoomId: "",
  messageId: 0,
};

const baseDeleteConsultantChatMessageResponse: object = {
};

const baseFlushConsultantChatUnreadCountRequest: object = {
  chatRoomId: "",
};

const baseFlushConsultantChatUnreadCountResponse: object = {
};

const basePutConsultantChatRoomStaffNameRequest: object = {
  chatRoomId: "",
  staffName: "",
};

const basePutConsultantChatRoomStaffNameResponse: object = {
};

const basePutConsultantChatRoomHandledRequest: object = {
  chatRoomId: "",
  handled: false,
};

const basePutConsultantChatRoomHandledResponse: object = {
};

const baseGetScoutCompaniesRequest: object = {
  limit: 0,
  q: "",
  scoutContractStatusFilterId: undefined,
};

const baseGetScoutCompaniesResponse: object = {
  scoutCompanies: undefined,
  paging: undefined,
};

const baseGetScoutCompanyRequest: object = {
  missionId: "",
};

const baseGetScoutCompanyResponse: object = {
  form: undefined,
  scoutCompany: undefined,
  scoutCompanyBillingAddress: undefined,
};

const baseGetScoutCompanyResponse_Form: object = {
  scoutContractStatuses: undefined,
};

const baseGetScoutingHumanResourcesRequest: object = {
  missionId: "",
  scoutBillingStatus: undefined,
  limit: 0,
  offset: 0,
};

const baseGetScoutingHumanResourcesResponse: object = {
  scoutingHumanResources: undefined,
  total: 0,
};

const baseGetScoutingHumanResourcesResponse_ScoutingHumanResource: object = {
  scoutId: "",
  lastName: "",
  firstName: "",
  joinCompanyScheduleAt: 0,
  scoutBillingStatus: undefined,
  selectionStep: undefined,
};

const basePutScoutContractStatusRequest: object = {
  missionId: "",
  scoutContractStatus: 0,
};

const basePutScoutContractStatusResponse: object = {
};

const basePutScoutCompanyStaffNameRequest: object = {
  missionId: "",
  staffName: "",
};

const basePutScoutCompanyStaffNameResponse: object = {
};

const basePostScoutCompanyBillingAddressRequest: object = {
  missionId: "",
  lastName: "",
  firstName: "",
  officialPosition: "",
  email: "",
  phoneNumber: "",
  address: "",
};

const basePostScoutCompanyBillingAddressResponse: object = {
};

const basePutScoutBillingStatusesRequest: object = {
  scoutIds: "",
  scoutBillingStatus: 0,
};

const basePutScoutBillingStatusesResponse: object = {
};

const baseDeleteInnovatorRequest: object = {
  email: "",
};

const baseDeleteInnovatorResponse: object = {
  accountId: "",
  innovatorId: "",
};

export interface ConsultantService {

  CreateConsultant(request: CreateConsultantRequest): Promise<CreateConsultantResponse>;

  DeleteConsultant(request: DeleteConsultantRequest): Promise<DeleteConsultantResponse>;

  ResendVerificationEmail(request: ResendVerificationEmailRequest): Promise<ResendVerificationEmailResponse>;

  GetHumanResourceMatter(request: GetHumanResourceMatterRequest): Promise<GetHumanResourceMatterResponse>;

  SearchHumanResourceMatters(request: SearchHumanResourceMattersRequest): Promise<SearchHumanResourceMattersResponse>;

  GetHumanResourceMattersTotalCount(request: GetHumanResourceMattersTotalCountRequest): Promise<GetHumanResourceMattersTotalCountResponse>;

  PutHumanResourceMatterStatus(request: PutHumanResourceMatterStatusRequest): Promise<PutHumanResourceMatterStatusResponse>;

  GetReasonsForClosingHumanResourceMatter(request: GetReasonsForClosingHumanResourceMatterRequest): Promise<GetReasonsForClosingHumanResourceMatterResponse>;

  PutHumanResourceMatterMemo(request: PutHumanResourceMatterMemoRequest): Promise<PutHumanResourceMatterMemoResponse>;

  GetMatterContactedAccounts(request: GetMatterContactedAccountsRequest): Promise<GetMatterContactedAccountsResponse>;

  GetConsultantChatRooms(request: GetConsultantChatRoomsRequest): Promise<GetConsultantChatRoomsResponse>;

  GetConsultantChatRoomsRefresh(request: GetConsultantChatRoomsRefreshRequest): Promise<GetConsultantChatRoomsRefreshResponse>;

  GetConsultantChatMessages(request: GetConsultantChatMessagesRequest): Promise<GetConsultantChatMessagesResponse>;

  PostConsultantChatMessage(request: PostConsultantChatMessageRequest): Promise<PostConsultantChatMessageResponse>;

  DeleteConsultantChatMessage(request: DeleteConsultantChatMessageRequest): Promise<DeleteConsultantChatMessageResponse>;

  PostConsultantChatFile(request: PostConsultantChatFileRequest): Promise<PostConsultantChatFileResponse>;

  FlushConsultantChatUnreadCount(request: FlushConsultantChatUnreadCountRequest): Promise<FlushConsultantChatUnreadCountResponse>;

  PutConsultantChatRoomStaffName(request: PutConsultantChatRoomStaffNameRequest): Promise<PutConsultantChatRoomStaffNameResponse>;

  PutConsultantChatRoomHandled(request: PutConsultantChatRoomHandledRequest): Promise<PutConsultantChatRoomHandledResponse>;

  GetScoutCompanies(request: GetScoutCompaniesRequest): Promise<GetScoutCompaniesResponse>;

  GetScoutCompany(request: GetScoutCompanyRequest): Promise<GetScoutCompanyResponse>;

  GetScoutingHumanResources(request: GetScoutingHumanResourcesRequest): Promise<GetScoutingHumanResourcesResponse>;

  PutScoutContractStatus(request: PutScoutContractStatusRequest): Promise<PutScoutContractStatusResponse>;

  PutScoutCompanyStaffName(request: PutScoutCompanyStaffNameRequest): Promise<PutScoutCompanyStaffNameResponse>;

  PostScoutCompanyBillingAddress(request: PostScoutCompanyBillingAddressRequest): Promise<PostScoutCompanyBillingAddressResponse>;

  PutScoutBillingStatuses(request: PutScoutBillingStatusesRequest): Promise<PutScoutBillingStatusesResponse>;

  DeleteInnovator(request: DeleteInnovatorRequest): Promise<DeleteInnovatorResponse>;

}

export class ConsultantServiceClientImpl implements ConsultantService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  CreateConsultant(request: CreateConsultantRequest): Promise<CreateConsultantResponse> {
    const data = CreateConsultantRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "CreateConsultant", data);
    return promise.then(data => CreateConsultantResponse.decode(new Reader(data)));
  }

  DeleteConsultant(request: DeleteConsultantRequest): Promise<DeleteConsultantResponse> {
    const data = DeleteConsultantRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "DeleteConsultant", data);
    return promise.then(data => DeleteConsultantResponse.decode(new Reader(data)));
  }

  ResendVerificationEmail(request: ResendVerificationEmailRequest): Promise<ResendVerificationEmailResponse> {
    const data = ResendVerificationEmailRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "ResendVerificationEmail", data);
    return promise.then(data => ResendVerificationEmailResponse.decode(new Reader(data)));
  }

  GetHumanResourceMatter(request: GetHumanResourceMatterRequest): Promise<GetHumanResourceMatterResponse> {
    const data = GetHumanResourceMatterRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetHumanResourceMatter", data);
    return promise.then(data => GetHumanResourceMatterResponse.decode(new Reader(data)));
  }

  SearchHumanResourceMatters(request: SearchHumanResourceMattersRequest): Promise<SearchHumanResourceMattersResponse> {
    const data = SearchHumanResourceMattersRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "SearchHumanResourceMatters", data);
    return promise.then(data => SearchHumanResourceMattersResponse.decode(new Reader(data)));
  }

  GetHumanResourceMattersTotalCount(request: GetHumanResourceMattersTotalCountRequest): Promise<GetHumanResourceMattersTotalCountResponse> {
    const data = GetHumanResourceMattersTotalCountRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetHumanResourceMattersTotalCount", data);
    return promise.then(data => GetHumanResourceMattersTotalCountResponse.decode(new Reader(data)));
  }

  PutHumanResourceMatterStatus(request: PutHumanResourceMatterStatusRequest): Promise<PutHumanResourceMatterStatusResponse> {
    const data = PutHumanResourceMatterStatusRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutHumanResourceMatterStatus", data);
    return promise.then(data => PutHumanResourceMatterStatusResponse.decode(new Reader(data)));
  }

  GetReasonsForClosingHumanResourceMatter(request: GetReasonsForClosingHumanResourceMatterRequest): Promise<GetReasonsForClosingHumanResourceMatterResponse> {
    const data = GetReasonsForClosingHumanResourceMatterRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetReasonsForClosingHumanResourceMatter", data);
    return promise.then(data => GetReasonsForClosingHumanResourceMatterResponse.decode(new Reader(data)));
  }

  PutHumanResourceMatterMemo(request: PutHumanResourceMatterMemoRequest): Promise<PutHumanResourceMatterMemoResponse> {
    const data = PutHumanResourceMatterMemoRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutHumanResourceMatterMemo", data);
    return promise.then(data => PutHumanResourceMatterMemoResponse.decode(new Reader(data)));
  }

  GetMatterContactedAccounts(request: GetMatterContactedAccountsRequest): Promise<GetMatterContactedAccountsResponse> {
    const data = GetMatterContactedAccountsRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetMatterContactedAccounts", data);
    return promise.then(data => GetMatterContactedAccountsResponse.decode(new Reader(data)));
  }

  GetConsultantChatRooms(request: GetConsultantChatRoomsRequest): Promise<GetConsultantChatRoomsResponse> {
    const data = GetConsultantChatRoomsRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetConsultantChatRooms", data);
    return promise.then(data => GetConsultantChatRoomsResponse.decode(new Reader(data)));
  }

  GetConsultantChatRoomsRefresh(request: GetConsultantChatRoomsRefreshRequest): Promise<GetConsultantChatRoomsRefreshResponse> {
    const data = GetConsultantChatRoomsRefreshRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetConsultantChatRoomsRefresh", data);
    return promise.then(data => GetConsultantChatRoomsRefreshResponse.decode(new Reader(data)));
  }

  GetConsultantChatMessages(request: GetConsultantChatMessagesRequest): Promise<GetConsultantChatMessagesResponse> {
    const data = GetConsultantChatMessagesRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetConsultantChatMessages", data);
    return promise.then(data => GetConsultantChatMessagesResponse.decode(new Reader(data)));
  }

  PostConsultantChatMessage(request: PostConsultantChatMessageRequest): Promise<PostConsultantChatMessageResponse> {
    const data = PostConsultantChatMessageRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PostConsultantChatMessage", data);
    return promise.then(data => PostConsultantChatMessageResponse.decode(new Reader(data)));
  }

  DeleteConsultantChatMessage(request: DeleteConsultantChatMessageRequest): Promise<DeleteConsultantChatMessageResponse> {
    const data = DeleteConsultantChatMessageRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "DeleteConsultantChatMessage", data);
    return promise.then(data => DeleteConsultantChatMessageResponse.decode(new Reader(data)));
  }

  PostConsultantChatFile(request: PostConsultantChatFileRequest): Promise<PostConsultantChatFileResponse> {
    const data = PostConsultantChatFileRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PostConsultantChatFile", data);
    return promise.then(data => PostConsultantChatFileResponse.decode(new Reader(data)));
  }

  FlushConsultantChatUnreadCount(request: FlushConsultantChatUnreadCountRequest): Promise<FlushConsultantChatUnreadCountResponse> {
    const data = FlushConsultantChatUnreadCountRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "FlushConsultantChatUnreadCount", data);
    return promise.then(data => FlushConsultantChatUnreadCountResponse.decode(new Reader(data)));
  }

  PutConsultantChatRoomStaffName(request: PutConsultantChatRoomStaffNameRequest): Promise<PutConsultantChatRoomStaffNameResponse> {
    const data = PutConsultantChatRoomStaffNameRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutConsultantChatRoomStaffName", data);
    return promise.then(data => PutConsultantChatRoomStaffNameResponse.decode(new Reader(data)));
  }

  PutConsultantChatRoomHandled(request: PutConsultantChatRoomHandledRequest): Promise<PutConsultantChatRoomHandledResponse> {
    const data = PutConsultantChatRoomHandledRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutConsultantChatRoomHandled", data);
    return promise.then(data => PutConsultantChatRoomHandledResponse.decode(new Reader(data)));
  }

  GetScoutCompanies(request: GetScoutCompaniesRequest): Promise<GetScoutCompaniesResponse> {
    const data = GetScoutCompaniesRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetScoutCompanies", data);
    return promise.then(data => GetScoutCompaniesResponse.decode(new Reader(data)));
  }

  GetScoutCompany(request: GetScoutCompanyRequest): Promise<GetScoutCompanyResponse> {
    const data = GetScoutCompanyRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetScoutCompany", data);
    return promise.then(data => GetScoutCompanyResponse.decode(new Reader(data)));
  }

  GetScoutingHumanResources(request: GetScoutingHumanResourcesRequest): Promise<GetScoutingHumanResourcesResponse> {
    const data = GetScoutingHumanResourcesRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "GetScoutingHumanResources", data);
    return promise.then(data => GetScoutingHumanResourcesResponse.decode(new Reader(data)));
  }

  PutScoutContractStatus(request: PutScoutContractStatusRequest): Promise<PutScoutContractStatusResponse> {
    const data = PutScoutContractStatusRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutScoutContractStatus", data);
    return promise.then(data => PutScoutContractStatusResponse.decode(new Reader(data)));
  }

  PutScoutCompanyStaffName(request: PutScoutCompanyStaffNameRequest): Promise<PutScoutCompanyStaffNameResponse> {
    const data = PutScoutCompanyStaffNameRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutScoutCompanyStaffName", data);
    return promise.then(data => PutScoutCompanyStaffNameResponse.decode(new Reader(data)));
  }

  PostScoutCompanyBillingAddress(request: PostScoutCompanyBillingAddressRequest): Promise<PostScoutCompanyBillingAddressResponse> {
    const data = PostScoutCompanyBillingAddressRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PostScoutCompanyBillingAddress", data);
    return promise.then(data => PostScoutCompanyBillingAddressResponse.decode(new Reader(data)));
  }

  PutScoutBillingStatuses(request: PutScoutBillingStatusesRequest): Promise<PutScoutBillingStatusesResponse> {
    const data = PutScoutBillingStatusesRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "PutScoutBillingStatuses", data);
    return promise.then(data => PutScoutBillingStatusesResponse.decode(new Reader(data)));
  }

  DeleteInnovator(request: DeleteInnovatorRequest): Promise<DeleteInnovatorResponse> {
    const data = DeleteInnovatorRequest.encode(request).finish();
    const promise = this.rpc.request("consultantservice.ConsultantService", "DeleteInnovator", data);
    return promise.then(data => DeleteInnovatorResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const CreateConsultantRequest = {
  encode(message: CreateConsultantRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(16).uint32(message.languageId);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateConsultantRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateConsultantRequest) as CreateConsultantRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.languageId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateConsultantRequest {
    const message = Object.create(baseCreateConsultantRequest) as CreateConsultantRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.languageId) {
      message.languageId = Number(object.languageId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateConsultantRequest>): CreateConsultantRequest {
    const message = Object.create(baseCreateConsultantRequest) as CreateConsultantRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.languageId) {
      message.languageId = object.languageId;
    }
    return message;
  },
  toJSON(message: CreateConsultantRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.languageId = message.languageId || 0;
    return obj;
  },
};

export const CreateConsultantResponse = {
  encode(message: CreateConsultantResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.userId);
    writer.uint32(18).string(message.accountId);
    writer.uint32(26).string(message.consultantId);
    writer.uint32(34).string(message.oobCode);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateConsultantResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateConsultantResponse) as CreateConsultantResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.accountId = reader.string();
          break;
        case 3:
          message.consultantId = reader.string();
          break;
        case 4:
          message.oobCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateConsultantResponse {
    const message = Object.create(baseCreateConsultantResponse) as CreateConsultantResponse;
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.consultantId) {
      message.consultantId = String(object.consultantId);
    }
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateConsultantResponse>): CreateConsultantResponse {
    const message = Object.create(baseCreateConsultantResponse) as CreateConsultantResponse;
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.consultantId) {
      message.consultantId = object.consultantId;
    }
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    return message;
  },
  toJSON(message: CreateConsultantResponse): unknown {
    const obj: any = {};
    obj.userId = message.userId || "";
    obj.accountId = message.accountId || "";
    obj.consultantId = message.consultantId || "";
    obj.oobCode = message.oobCode || "";
    return obj;
  },
};

export const DeleteConsultantRequest = {
  encode(message: DeleteConsultantRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteConsultantRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteConsultantRequest) as DeleteConsultantRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteConsultantRequest {
    const message = Object.create(baseDeleteConsultantRequest) as DeleteConsultantRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteConsultantRequest>): DeleteConsultantRequest {
    const message = Object.create(baseDeleteConsultantRequest) as DeleteConsultantRequest;
    if (object.email) {
      message.email = object.email;
    }
    return message;
  },
  toJSON(message: DeleteConsultantRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    return obj;
  },
};

export const DeleteConsultantResponse = {
  encode(message: DeleteConsultantResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.consultantId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteConsultantResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteConsultantResponse) as DeleteConsultantResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.consultantId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteConsultantResponse {
    const message = Object.create(baseDeleteConsultantResponse) as DeleteConsultantResponse;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.consultantId) {
      message.consultantId = String(object.consultantId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteConsultantResponse>): DeleteConsultantResponse {
    const message = Object.create(baseDeleteConsultantResponse) as DeleteConsultantResponse;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.consultantId) {
      message.consultantId = object.consultantId;
    }
    return message;
  },
  toJSON(message: DeleteConsultantResponse): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.consultantId = message.consultantId || "";
    return obj;
  },
};

export const ResendVerificationEmailRequest = {
  encode(message: ResendVerificationEmailRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    return writer;
  },
  decode(reader: Reader, length?: number): ResendVerificationEmailRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResendVerificationEmailRequest) as ResendVerificationEmailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResendVerificationEmailRequest {
    const message = Object.create(baseResendVerificationEmailRequest) as ResendVerificationEmailRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ResendVerificationEmailRequest>): ResendVerificationEmailRequest {
    const message = Object.create(baseResendVerificationEmailRequest) as ResendVerificationEmailRequest;
    if (object.email) {
      message.email = object.email;
    }
    return message;
  },
  toJSON(message: ResendVerificationEmailRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    return obj;
  },
};

export const ResendVerificationEmailResponse = {
  encode(message: ResendVerificationEmailResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ResendVerificationEmailResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResendVerificationEmailResponse) as ResendVerificationEmailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResendVerificationEmailResponse {
    const message = Object.create(baseResendVerificationEmailResponse) as ResendVerificationEmailResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ResendVerificationEmailResponse>): ResendVerificationEmailResponse {
    const message = Object.create(baseResendVerificationEmailResponse) as ResendVerificationEmailResponse;
    return message;
  },
  toJSON(message: ResendVerificationEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetHumanResourceMatterRequest = {
  encode(message: GetHumanResourceMatterRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.matterPublishId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetHumanResourceMatterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetHumanResourceMatterRequest) as GetHumanResourceMatterRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetHumanResourceMatterRequest {
    const message = Object.create(baseGetHumanResourceMatterRequest) as GetHumanResourceMatterRequest;
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetHumanResourceMatterRequest>): GetHumanResourceMatterRequest {
    const message = Object.create(baseGetHumanResourceMatterRequest) as GetHumanResourceMatterRequest;
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    return message;
  },
  toJSON(message: GetHumanResourceMatterRequest): unknown {
    const obj: any = {};
    obj.matterPublishId = message.matterPublishId || 0;
    return obj;
  },
};

export const GetHumanResourceMatterResponse = {
  encode(message: GetHumanResourceMatterResponse, writer: Writer = Writer.create()): Writer {
    if (message.matter !== undefined && message.matter !== undefined) {
      HumanResourceMatterForConsultant.encode(message.matter, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetHumanResourceMatterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetHumanResourceMatterResponse) as GetHumanResourceMatterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matter = HumanResourceMatterForConsultant.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetHumanResourceMatterResponse {
    const message = Object.create(baseGetHumanResourceMatterResponse) as GetHumanResourceMatterResponse;
    if (object.matter) {
      message.matter = HumanResourceMatterForConsultant.fromJSON(object.matter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetHumanResourceMatterResponse>): GetHumanResourceMatterResponse {
    const message = Object.create(baseGetHumanResourceMatterResponse) as GetHumanResourceMatterResponse;
    if (object.matter) {
      message.matter = HumanResourceMatterForConsultant.fromPartial(object.matter);
    }
    return message;
  },
  toJSON(message: GetHumanResourceMatterResponse): unknown {
    const obj: any = {};
    obj.matter = message.matter ? HumanResourceMatterForConsultant.toJSON(message.matter) : undefined;
    return obj;
  },
};

export const SearchHumanResourceMattersRequest = {
  encode(message: SearchHumanResourceMattersRequest, writer: Writer = Writer.create()): Writer {
    if (message.statusId !== undefined && message.statusId !== undefined) {
      Int32Value.encode({ value: message.statusId! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.favorite !== undefined && message.favorite !== undefined) {
      BoolValue.encode({ value: message.favorite! }, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).uint32(message.limit);
    writer.uint32(34).string(message.q);
    if (message.career !== undefined && message.career !== undefined) {
      BoolValue.encode({ value: message.career! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      BoolValue.encode({ value: message.newGraduate! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.outSourcing !== undefined && message.outSourcing !== undefined) {
      BoolValue.encode({ value: message.outSourcing! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.deleted !== undefined && message.deleted !== undefined) {
      BoolValue.encode({ value: message.deleted! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): SearchHumanResourceMattersRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSearchHumanResourceMattersRequest) as SearchHumanResourceMattersRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.favorite = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.limit = reader.uint32();
          break;
        case 4:
          message.q = reader.string();
          break;
        case 5:
          message.career = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.newGraduate = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.outSourcing = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.deleted = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SearchHumanResourceMattersRequest {
    const message = Object.create(baseSearchHumanResourceMattersRequest) as SearchHumanResourceMattersRequest;
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.career) {
      message.career = Boolean(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = Boolean(object.newGraduate);
    }
    if (object.outSourcing) {
      message.outSourcing = Boolean(object.outSourcing);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SearchHumanResourceMattersRequest>): SearchHumanResourceMattersRequest {
    const message = Object.create(baseSearchHumanResourceMattersRequest) as SearchHumanResourceMattersRequest;
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.newGraduate) {
      message.newGraduate = object.newGraduate;
    }
    if (object.outSourcing) {
      message.outSourcing = object.outSourcing;
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: SearchHumanResourceMattersRequest): unknown {
    const obj: any = {};
    obj.statusId = message.statusId || undefined;
    obj.favorite = message.favorite || undefined;
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    obj.career = message.career || undefined;
    obj.newGraduate = message.newGraduate || undefined;
    obj.outSourcing = message.outSourcing || undefined;
    obj.deleted = message.deleted || undefined;
    return obj;
  },
};

export const SearchHumanResourceMattersResponse = {
  encode(message: SearchHumanResourceMattersResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.matters) {
      HumanResourceMatterForConsultant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.nextQ);
    return writer;
  },
  decode(reader: Reader, length?: number): SearchHumanResourceMattersResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSearchHumanResourceMattersResponse) as SearchHumanResourceMattersResponse;
    message.matters = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matters.push(HumanResourceMatterForConsultant.decode(reader, reader.uint32()));
          break;
        case 2:
          message.nextQ = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SearchHumanResourceMattersResponse {
    const message = Object.create(baseSearchHumanResourceMattersResponse) as SearchHumanResourceMattersResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(HumanResourceMatterForConsultant.fromJSON(e));
      }
    }
    if (object.nextQ) {
      message.nextQ = String(object.nextQ);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SearchHumanResourceMattersResponse>): SearchHumanResourceMattersResponse {
    const message = Object.create(baseSearchHumanResourceMattersResponse) as SearchHumanResourceMattersResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(HumanResourceMatterForConsultant.fromPartial(e));
      }
    }
    if (object.nextQ) {
      message.nextQ = object.nextQ;
    }
    return message;
  },
  toJSON(message: SearchHumanResourceMattersResponse): unknown {
    const obj: any = {};
    if (message.matters) {
      obj.matters = message.matters.map(e => e ? HumanResourceMatterForConsultant.toJSON(e) : undefined);
    } else {
      obj.matters = [];
    }
    obj.nextQ = message.nextQ || "";
    return obj;
  },
};

export const GetHumanResourceMattersTotalCountRequest = {
  encode(message: GetHumanResourceMattersTotalCountRequest, writer: Writer = Writer.create()): Writer {
    if (message.statusId !== undefined && message.statusId !== undefined) {
      Int32Value.encode({ value: message.statusId! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.favorite !== undefined && message.favorite !== undefined) {
      BoolValue.encode({ value: message.favorite! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.career !== undefined && message.career !== undefined) {
      BoolValue.encode({ value: message.career! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      BoolValue.encode({ value: message.newGraduate! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.outSourcing !== undefined && message.outSourcing !== undefined) {
      BoolValue.encode({ value: message.outSourcing! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.deleted !== undefined && message.deleted !== undefined) {
      BoolValue.encode({ value: message.deleted! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetHumanResourceMattersTotalCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetHumanResourceMattersTotalCountRequest) as GetHumanResourceMattersTotalCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.favorite = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.career = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.newGraduate = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.outSourcing = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.deleted = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetHumanResourceMattersTotalCountRequest {
    const message = Object.create(baseGetHumanResourceMattersTotalCountRequest) as GetHumanResourceMattersTotalCountRequest;
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    if (object.career) {
      message.career = Boolean(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = Boolean(object.newGraduate);
    }
    if (object.outSourcing) {
      message.outSourcing = Boolean(object.outSourcing);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetHumanResourceMattersTotalCountRequest>): GetHumanResourceMattersTotalCountRequest {
    const message = Object.create(baseGetHumanResourceMattersTotalCountRequest) as GetHumanResourceMattersTotalCountRequest;
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.newGraduate) {
      message.newGraduate = object.newGraduate;
    }
    if (object.outSourcing) {
      message.outSourcing = object.outSourcing;
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: GetHumanResourceMattersTotalCountRequest): unknown {
    const obj: any = {};
    obj.statusId = message.statusId || undefined;
    obj.favorite = message.favorite || undefined;
    obj.career = message.career || undefined;
    obj.newGraduate = message.newGraduate || undefined;
    obj.outSourcing = message.outSourcing || undefined;
    obj.deleted = message.deleted || undefined;
    return obj;
  },
};

export const GetHumanResourceMattersTotalCountResponse = {
  encode(message: GetHumanResourceMattersTotalCountResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.count);
    return writer;
  },
  decode(reader: Reader, length?: number): GetHumanResourceMattersTotalCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetHumanResourceMattersTotalCountResponse) as GetHumanResourceMattersTotalCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.count = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetHumanResourceMattersTotalCountResponse {
    const message = Object.create(baseGetHumanResourceMattersTotalCountResponse) as GetHumanResourceMattersTotalCountResponse;
    if (object.count) {
      message.count = Number(object.count);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetHumanResourceMattersTotalCountResponse>): GetHumanResourceMattersTotalCountResponse {
    const message = Object.create(baseGetHumanResourceMattersTotalCountResponse) as GetHumanResourceMattersTotalCountResponse;
    if (object.count) {
      message.count = object.count;
    }
    return message;
  },
  toJSON(message: GetHumanResourceMattersTotalCountResponse): unknown {
    const obj: any = {};
    obj.count = message.count || 0;
    return obj;
  },
};

export const HumanResourceMatterForConsultant = {
  encode(message: HumanResourceMatterForConsultant, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(16).int64(message.publishedAt);
    writer.uint32(24).int64(message.closedAt);
    if (message.reasonForClosing !== undefined && message.reasonForClosing !== undefined) {
      ReasonForClosingHumanResourcesMatter.encode(message.reasonForClosing, writer.uint32(34).fork()).ldelim();
    }
    if (message.profile !== undefined && message.profile !== undefined) {
      Innovator.encode(message.profile, writer.uint32(42).fork()).ldelim();
    }
    if (message.status !== undefined && message.status !== undefined) {
      MatterPublishStatus.encode(message.status, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).string(message.memo);
    writer.uint32(66).string(message.staffId);
    writer.uint32(74).string(message.staffEmail);
    for (const v of message.blockCompanies) {
      BlockCompany.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    writer.uint32(88).bool(message.favorite);
    writer.uint32(98).string(message.innovatorEmail);
    writer.uint32(104).int64(message.matterPublishId);
    for (const v of message.categories) {
      MissionCategory.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.funds) {
      Fund.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.locations) {
      Location.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.memberRanges) {
      MemberRange.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    writer.uint32(154).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): HumanResourceMatterForConsultant {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseHumanResourceMatterForConsultant) as HumanResourceMatterForConsultant;
    message.blockCompanies = [];
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.publishedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.closedAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.reasonForClosing = ReasonForClosingHumanResourcesMatter.decode(reader, reader.uint32());
          break;
        case 5:
          message.profile = Innovator.decode(reader, reader.uint32());
          break;
        case 6:
          message.status = MatterPublishStatus.decode(reader, reader.uint32());
          break;
        case 7:
          message.memo = reader.string();
          break;
        case 8:
          message.staffId = reader.string();
          break;
        case 9:
          message.staffEmail = reader.string();
          break;
        case 10:
          message.blockCompanies.push(BlockCompany.decode(reader, reader.uint32()));
          break;
        case 11:
          message.favorite = reader.bool();
          break;
        case 12:
          message.innovatorEmail = reader.string();
          break;
        case 13:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        case 14:
          message.categories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 15:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 16:
          message.funds.push(Fund.decode(reader, reader.uint32()));
          break;
        case 17:
          message.locations.push(Location.decode(reader, reader.uint32()));
          break;
        case 18:
          message.memberRanges.push(MemberRange.decode(reader, reader.uint32()));
          break;
        case 19:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): HumanResourceMatterForConsultant {
    const message = Object.create(baseHumanResourceMatterForConsultant) as HumanResourceMatterForConsultant;
    message.blockCompanies = [];
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.publishedAt) {
      message.publishedAt = Number(object.publishedAt);
    }
    if (object.closedAt) {
      message.closedAt = Number(object.closedAt);
    }
    if (object.reasonForClosing) {
      message.reasonForClosing = ReasonForClosingHumanResourcesMatter.fromJSON(object.reasonForClosing);
    }
    if (object.profile) {
      message.profile = Innovator.fromJSON(object.profile);
    }
    if (object.status) {
      message.status = MatterPublishStatus.fromJSON(object.status);
    }
    if (object.memo) {
      message.memo = String(object.memo);
    }
    if (object.staffId) {
      message.staffId = String(object.staffId);
    }
    if (object.staffEmail) {
      message.staffEmail = String(object.staffEmail);
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(BlockCompany.fromJSON(e));
      }
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    if (object.innovatorEmail) {
      message.innovatorEmail = String(object.innovatorEmail);
    }
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromJSON(e));
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(Location.fromJSON(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromJSON(e));
      }
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<HumanResourceMatterForConsultant>): HumanResourceMatterForConsultant {
    const message = Object.create(baseHumanResourceMatterForConsultant) as HumanResourceMatterForConsultant;
    message.blockCompanies = [];
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.publishedAt) {
      message.publishedAt = object.publishedAt;
    }
    if (object.closedAt) {
      message.closedAt = object.closedAt;
    }
    if (object.reasonForClosing) {
      message.reasonForClosing = ReasonForClosingHumanResourcesMatter.fromPartial(object.reasonForClosing);
    }
    if (object.profile) {
      message.profile = Innovator.fromPartial(object.profile);
    }
    if (object.status) {
      message.status = MatterPublishStatus.fromPartial(object.status);
    }
    if (object.memo) {
      message.memo = object.memo;
    }
    if (object.staffId) {
      message.staffId = object.staffId;
    }
    if (object.staffEmail) {
      message.staffEmail = object.staffEmail;
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(BlockCompany.fromPartial(e));
      }
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    if (object.innovatorEmail) {
      message.innovatorEmail = object.innovatorEmail;
    }
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromPartial(e));
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(Location.fromPartial(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromPartial(e));
      }
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: HumanResourceMatterForConsultant): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.publishedAt = message.publishedAt || 0;
    obj.closedAt = message.closedAt || 0;
    obj.reasonForClosing = message.reasonForClosing ? ReasonForClosingHumanResourcesMatter.toJSON(message.reasonForClosing) : undefined;
    obj.profile = message.profile ? Innovator.toJSON(message.profile) : undefined;
    obj.status = message.status ? MatterPublishStatus.toJSON(message.status) : undefined;
    obj.memo = message.memo || "";
    obj.staffId = message.staffId || "";
    obj.staffEmail = message.staffEmail || "";
    if (message.blockCompanies) {
      obj.blockCompanies = message.blockCompanies.map(e => e ? BlockCompany.toJSON(e) : undefined);
    } else {
      obj.blockCompanies = [];
    }
    obj.favorite = message.favorite || false;
    obj.innovatorEmail = message.innovatorEmail || "";
    obj.matterPublishId = message.matterPublishId || 0;
    if (message.categories) {
      obj.categories = message.categories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    if (message.funds) {
      obj.funds = message.funds.map(e => e ? Fund.toJSON(e) : undefined);
    } else {
      obj.funds = [];
    }
    if (message.locations) {
      obj.locations = message.locations.map(e => e ? Location.toJSON(e) : undefined);
    } else {
      obj.locations = [];
    }
    if (message.memberRanges) {
      obj.memberRanges = message.memberRanges.map(e => e ? MemberRange.toJSON(e) : undefined);
    } else {
      obj.memberRanges = [];
    }
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const PutHumanResourceMatterStatusRequest = {
  encode(message: PutHumanResourceMatterStatusRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(16).uint32(message.statusId);
    if (message.sendBackReason !== undefined && message.sendBackReason !== undefined) {
      StringValue.encode({ value: message.sendBackReason! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.reasonForClosingId !== undefined && message.reasonForClosingId !== undefined) {
      Int32Value.encode({ value: message.reasonForClosingId! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): PutHumanResourceMatterStatusRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutHumanResourceMatterStatusRequest) as PutHumanResourceMatterStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.statusId = reader.uint32();
          break;
        case 3:
          message.sendBackReason = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.reasonForClosingId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutHumanResourceMatterStatusRequest {
    const message = Object.create(basePutHumanResourceMatterStatusRequest) as PutHumanResourceMatterStatusRequest;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    if (object.sendBackReason) {
      message.sendBackReason = String(object.sendBackReason);
    }
    if (object.reasonForClosingId) {
      message.reasonForClosingId = Number(object.reasonForClosingId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutHumanResourceMatterStatusRequest>): PutHumanResourceMatterStatusRequest {
    const message = Object.create(basePutHumanResourceMatterStatusRequest) as PutHumanResourceMatterStatusRequest;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    if (object.sendBackReason) {
      message.sendBackReason = object.sendBackReason;
    }
    if (object.reasonForClosingId) {
      message.reasonForClosingId = object.reasonForClosingId;
    }
    return message;
  },
  toJSON(message: PutHumanResourceMatterStatusRequest): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.statusId = message.statusId || 0;
    obj.sendBackReason = message.sendBackReason || undefined;
    obj.reasonForClosingId = message.reasonForClosingId || undefined;
    return obj;
  },
};

export const PutHumanResourceMatterStatusResponse = {
  encode(message: PutHumanResourceMatterStatusResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutHumanResourceMatterStatusResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutHumanResourceMatterStatusResponse) as PutHumanResourceMatterStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutHumanResourceMatterStatusResponse {
    const message = Object.create(basePutHumanResourceMatterStatusResponse) as PutHumanResourceMatterStatusResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutHumanResourceMatterStatusResponse>): PutHumanResourceMatterStatusResponse {
    const message = Object.create(basePutHumanResourceMatterStatusResponse) as PutHumanResourceMatterStatusResponse;
    return message;
  },
  toJSON(message: PutHumanResourceMatterStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetReasonsForClosingHumanResourceMatterRequest = {
  encode(message: GetReasonsForClosingHumanResourceMatterRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetReasonsForClosingHumanResourceMatterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterRequest) as GetReasonsForClosingHumanResourceMatterRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetReasonsForClosingHumanResourceMatterRequest {
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterRequest) as GetReasonsForClosingHumanResourceMatterRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetReasonsForClosingHumanResourceMatterRequest>): GetReasonsForClosingHumanResourceMatterRequest {
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterRequest) as GetReasonsForClosingHumanResourceMatterRequest;
    return message;
  },
  toJSON(message: GetReasonsForClosingHumanResourceMatterRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetReasonsForClosingHumanResourceMatterResponse = {
  encode(message: GetReasonsForClosingHumanResourceMatterResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.reasons) {
      ReasonForClosingHumanResourcesMatter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetReasonsForClosingHumanResourceMatterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterResponse) as GetReasonsForClosingHumanResourceMatterResponse;
    message.reasons = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.reasons.push(ReasonForClosingHumanResourcesMatter.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetReasonsForClosingHumanResourceMatterResponse {
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterResponse) as GetReasonsForClosingHumanResourceMatterResponse;
    message.reasons = [];
    if (object.reasons) {
      for (const e of object.reasons) {
        message.reasons.push(ReasonForClosingHumanResourcesMatter.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetReasonsForClosingHumanResourceMatterResponse>): GetReasonsForClosingHumanResourceMatterResponse {
    const message = Object.create(baseGetReasonsForClosingHumanResourceMatterResponse) as GetReasonsForClosingHumanResourceMatterResponse;
    message.reasons = [];
    if (object.reasons) {
      for (const e of object.reasons) {
        message.reasons.push(ReasonForClosingHumanResourcesMatter.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetReasonsForClosingHumanResourceMatterResponse): unknown {
    const obj: any = {};
    if (message.reasons) {
      obj.reasons = message.reasons.map(e => e ? ReasonForClosingHumanResourcesMatter.toJSON(e) : undefined);
    } else {
      obj.reasons = [];
    }
    return obj;
  },
};

export const PutHumanResourceMatterMemoRequest = {
  encode(message: PutHumanResourceMatterMemoRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(18).string(message.memo);
    return writer;
  },
  decode(reader: Reader, length?: number): PutHumanResourceMatterMemoRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutHumanResourceMatterMemoRequest) as PutHumanResourceMatterMemoRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.memo = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutHumanResourceMatterMemoRequest {
    const message = Object.create(basePutHumanResourceMatterMemoRequest) as PutHumanResourceMatterMemoRequest;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.memo) {
      message.memo = String(object.memo);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutHumanResourceMatterMemoRequest>): PutHumanResourceMatterMemoRequest {
    const message = Object.create(basePutHumanResourceMatterMemoRequest) as PutHumanResourceMatterMemoRequest;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.memo) {
      message.memo = object.memo;
    }
    return message;
  },
  toJSON(message: PutHumanResourceMatterMemoRequest): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.memo = message.memo || "";
    return obj;
  },
};

export const PutHumanResourceMatterMemoResponse = {
  encode(message: PutHumanResourceMatterMemoResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutHumanResourceMatterMemoResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutHumanResourceMatterMemoResponse) as PutHumanResourceMatterMemoResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutHumanResourceMatterMemoResponse {
    const message = Object.create(basePutHumanResourceMatterMemoResponse) as PutHumanResourceMatterMemoResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutHumanResourceMatterMemoResponse>): PutHumanResourceMatterMemoResponse {
    const message = Object.create(basePutHumanResourceMatterMemoResponse) as PutHumanResourceMatterMemoResponse;
    return message;
  },
  toJSON(message: PutHumanResourceMatterMemoResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMatterContactedAccountsRequest = {
  encode(message: GetMatterContactedAccountsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(16).uint32(message.limit);
    writer.uint32(26).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterContactedAccountsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterContactedAccountsRequest) as GetMatterContactedAccountsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.limit = reader.uint32();
          break;
        case 3:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterContactedAccountsRequest {
    const message = Object.create(baseGetMatterContactedAccountsRequest) as GetMatterContactedAccountsRequest;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterContactedAccountsRequest>): GetMatterContactedAccountsRequest {
    const message = Object.create(baseGetMatterContactedAccountsRequest) as GetMatterContactedAccountsRequest;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetMatterContactedAccountsRequest): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetMatterContactedAccountsResponse = {
  encode(message: GetMatterContactedAccountsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.contactedAccounts) {
      GetMatterContactedAccountsResponse_ContactedAccount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterContactedAccountsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterContactedAccountsResponse) as GetMatterContactedAccountsResponse;
    message.contactedAccounts = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contactedAccounts.push(GetMatterContactedAccountsResponse_ContactedAccount.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterContactedAccountsResponse {
    const message = Object.create(baseGetMatterContactedAccountsResponse) as GetMatterContactedAccountsResponse;
    message.contactedAccounts = [];
    if (object.contactedAccounts) {
      for (const e of object.contactedAccounts) {
        message.contactedAccounts.push(GetMatterContactedAccountsResponse_ContactedAccount.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterContactedAccountsResponse>): GetMatterContactedAccountsResponse {
    const message = Object.create(baseGetMatterContactedAccountsResponse) as GetMatterContactedAccountsResponse;
    message.contactedAccounts = [];
    if (object.contactedAccounts) {
      for (const e of object.contactedAccounts) {
        message.contactedAccounts.push(GetMatterContactedAccountsResponse_ContactedAccount.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetMatterContactedAccountsResponse): unknown {
    const obj: any = {};
    if (message.contactedAccounts) {
      obj.contactedAccounts = message.contactedAccounts.map(e => e ? GetMatterContactedAccountsResponse_ContactedAccount.toJSON(e) : undefined);
    } else {
      obj.contactedAccounts = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetMatterContactedAccountsResponse_ContactedAccount = {
  encode(message: GetMatterContactedAccountsResponse_ContactedAccount, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(16).int64(message.contactedAt);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterContactedAccountsResponse_ContactedAccount {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterContactedAccountsResponse_ContactedAccount) as GetMatterContactedAccountsResponse_ContactedAccount;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 2:
          message.contactedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterContactedAccountsResponse_ContactedAccount {
    const message = Object.create(baseGetMatterContactedAccountsResponse_ContactedAccount) as GetMatterContactedAccountsResponse_ContactedAccount;
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.contactedAt) {
      message.contactedAt = Number(object.contactedAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterContactedAccountsResponse_ContactedAccount>): GetMatterContactedAccountsResponse_ContactedAccount {
    const message = Object.create(baseGetMatterContactedAccountsResponse_ContactedAccount) as GetMatterContactedAccountsResponse_ContactedAccount;
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.contactedAt) {
      message.contactedAt = object.contactedAt;
    }
    return message;
  },
  toJSON(message: GetMatterContactedAccountsResponse_ContactedAccount): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.contactedAt = message.contactedAt || 0;
    return obj;
  },
};

export const Paging = {
  encode(message: Paging, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nextQ);
    return writer;
  },
  decode(reader: Reader, length?: number): Paging {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePaging) as Paging;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nextQ = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = String(object.nextQ);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Paging>): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = object.nextQ;
    }
    return message;
  },
  toJSON(message: Paging): unknown {
    const obj: any = {};
    obj.nextQ = message.nextQ || "";
    return obj;
  },
};

export const GetConsultantChatRoomsRequest = {
  encode(message: GetConsultantChatRoomsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdBefore);
    writer.uint32(24).int64(message.updatedAtBefore);
    if (message.handled !== undefined && message.handled !== undefined) {
      BoolValue.encode({ value: message.handled! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.career !== undefined && message.career !== undefined) {
      BoolValue.encode({ value: message.career! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      BoolValue.encode({ value: message.newGraduate! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.innovatorName !== undefined && message.innovatorName !== undefined) {
      StringValue.encode({ value: message.innovatorName! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.statusId !== undefined && message.statusId !== undefined) {
      Int32Value.encode({ value: message.statusId! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatRoomsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatRoomsRequest) as GetConsultantChatRoomsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdBefore = reader.string();
          break;
        case 3:
          message.updatedAtBefore = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.handled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.career = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.newGraduate = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.innovatorName = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.statusId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatRoomsRequest {
    const message = Object.create(baseGetConsultantChatRoomsRequest) as GetConsultantChatRoomsRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = String(object.chatRoomIdBefore);
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = Number(object.updatedAtBefore);
    }
    if (object.handled) {
      message.handled = Boolean(object.handled);
    }
    if (object.career) {
      message.career = Boolean(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = Boolean(object.newGraduate);
    }
    if (object.innovatorName) {
      message.innovatorName = String(object.innovatorName);
    }
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatRoomsRequest>): GetConsultantChatRoomsRequest {
    const message = Object.create(baseGetConsultantChatRoomsRequest) as GetConsultantChatRoomsRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = object.chatRoomIdBefore;
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = object.updatedAtBefore;
    }
    if (object.handled) {
      message.handled = object.handled;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.newGraduate) {
      message.newGraduate = object.newGraduate;
    }
    if (object.innovatorName) {
      message.innovatorName = object.innovatorName;
    }
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    return message;
  },
  toJSON(message: GetConsultantChatRoomsRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdBefore = message.chatRoomIdBefore || "";
    obj.updatedAtBefore = message.updatedAtBefore || 0;
    obj.handled = message.handled || undefined;
    obj.career = message.career || undefined;
    obj.newGraduate = message.newGraduate || undefined;
    obj.innovatorName = message.innovatorName || undefined;
    obj.statusId = message.statusId || undefined;
    return obj;
  },
};

export const GetConsultantChatRoomsResponse = {
  encode(message: GetConsultantChatRoomsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      ConsultantChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatRoomsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatRoomsResponse) as GetConsultantChatRoomsResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(ConsultantChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatRoomsResponse {
    const message = Object.create(baseGetConsultantChatRoomsResponse) as GetConsultantChatRoomsResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ConsultantChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatRoomsResponse>): GetConsultantChatRoomsResponse {
    const message = Object.create(baseGetConsultantChatRoomsResponse) as GetConsultantChatRoomsResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ConsultantChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetConsultantChatRoomsResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? ConsultantChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetConsultantChatRoomsRefreshRequest = {
  encode(message: GetConsultantChatRoomsRefreshRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdAfter);
    writer.uint32(24).int64(message.updatedAtAfter);
    if (message.handled !== undefined && message.handled !== undefined) {
      BoolValue.encode({ value: message.handled! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.career !== undefined && message.career !== undefined) {
      BoolValue.encode({ value: message.career! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      BoolValue.encode({ value: message.newGraduate! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.innovatorName !== undefined && message.innovatorName !== undefined) {
      StringValue.encode({ value: message.innovatorName! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.statusId !== undefined && message.statusId !== undefined) {
      Int32Value.encode({ value: message.statusId! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatRoomsRefreshRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatRoomsRefreshRequest) as GetConsultantChatRoomsRefreshRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdAfter = reader.string();
          break;
        case 3:
          message.updatedAtAfter = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.handled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.career = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.newGraduate = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.innovatorName = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.statusId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatRoomsRefreshRequest {
    const message = Object.create(baseGetConsultantChatRoomsRefreshRequest) as GetConsultantChatRoomsRefreshRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = String(object.chatRoomIdAfter);
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = Number(object.updatedAtAfter);
    }
    if (object.handled) {
      message.handled = Boolean(object.handled);
    }
    if (object.career) {
      message.career = Boolean(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = Boolean(object.newGraduate);
    }
    if (object.innovatorName) {
      message.innovatorName = String(object.innovatorName);
    }
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatRoomsRefreshRequest>): GetConsultantChatRoomsRefreshRequest {
    const message = Object.create(baseGetConsultantChatRoomsRefreshRequest) as GetConsultantChatRoomsRefreshRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = object.chatRoomIdAfter;
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = object.updatedAtAfter;
    }
    if (object.handled) {
      message.handled = object.handled;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.newGraduate) {
      message.newGraduate = object.newGraduate;
    }
    if (object.innovatorName) {
      message.innovatorName = object.innovatorName;
    }
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    return message;
  },
  toJSON(message: GetConsultantChatRoomsRefreshRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdAfter = message.chatRoomIdAfter || "";
    obj.updatedAtAfter = message.updatedAtAfter || 0;
    obj.handled = message.handled || undefined;
    obj.career = message.career || undefined;
    obj.newGraduate = message.newGraduate || undefined;
    obj.innovatorName = message.innovatorName || undefined;
    obj.statusId = message.statusId || undefined;
    return obj;
  },
};

export const GetConsultantChatRoomsRefreshResponse = {
  encode(message: GetConsultantChatRoomsRefreshResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      ConsultantChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatRoomsRefreshResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatRoomsRefreshResponse) as GetConsultantChatRoomsRefreshResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(ConsultantChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatRoomsRefreshResponse {
    const message = Object.create(baseGetConsultantChatRoomsRefreshResponse) as GetConsultantChatRoomsRefreshResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ConsultantChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatRoomsRefreshResponse>): GetConsultantChatRoomsRefreshResponse {
    const message = Object.create(baseGetConsultantChatRoomsRefreshResponse) as GetConsultantChatRoomsRefreshResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(ConsultantChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetConsultantChatRoomsRefreshResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? ConsultantChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetConsultantChatMessagesRequest = {
  encode(message: GetConsultantChatMessagesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.limit);
    if (message.messageIdBefore !== undefined && message.messageIdBefore !== undefined) {
      Int32Value.encode({ value: message.messageIdBefore! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.messageIdAfter !== undefined && message.messageIdAfter !== undefined) {
      Int32Value.encode({ value: message.messageIdAfter! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatMessagesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatMessagesRequest) as GetConsultantChatMessagesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.messageIdBefore = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.messageIdAfter = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatMessagesRequest {
    const message = Object.create(baseGetConsultantChatMessagesRequest) as GetConsultantChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = Number(object.messageIdBefore);
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = Number(object.messageIdAfter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatMessagesRequest>): GetConsultantChatMessagesRequest {
    const message = Object.create(baseGetConsultantChatMessagesRequest) as GetConsultantChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = object.messageIdBefore;
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = object.messageIdAfter;
    }
    return message;
  },
  toJSON(message: GetConsultantChatMessagesRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.limit = message.limit || 0;
    obj.messageIdBefore = message.messageIdBefore || undefined;
    obj.messageIdAfter = message.messageIdAfter || undefined;
    return obj;
  },
};

export const GetConsultantChatMessagesResponse = {
  encode(message: GetConsultantChatMessagesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.messages) {
      ConsultantChatMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.prevQuery);
    writer.uint32(26).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetConsultantChatMessagesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConsultantChatMessagesResponse) as GetConsultantChatMessagesResponse;
    message.messages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messages.push(ConsultantChatMessage.decode(reader, reader.uint32()));
          break;
        case 2:
          message.prevQuery = reader.string();
          break;
        case 3:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConsultantChatMessagesResponse {
    const message = Object.create(baseGetConsultantChatMessagesResponse) as GetConsultantChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(ConsultantChatMessage.fromJSON(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = String(object.prevQuery);
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConsultantChatMessagesResponse>): GetConsultantChatMessagesResponse {
    const message = Object.create(baseGetConsultantChatMessagesResponse) as GetConsultantChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(ConsultantChatMessage.fromPartial(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = object.prevQuery;
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetConsultantChatMessagesResponse): unknown {
    const obj: any = {};
    if (message.messages) {
      obj.messages = message.messages.map(e => e ? ConsultantChatMessage.toJSON(e) : undefined);
    } else {
      obj.messages = [];
    }
    obj.prevQuery = message.prevQuery || "";
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const PostConsultantChatMessageRequest = {
  encode(message: PostConsultantChatMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): PostConsultantChatMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConsultantChatMessageRequest) as PostConsultantChatMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConsultantChatMessageRequest {
    const message = Object.create(basePostConsultantChatMessageRequest) as PostConsultantChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostConsultantChatMessageRequest>): PostConsultantChatMessageRequest {
    const message = Object.create(basePostConsultantChatMessageRequest) as PostConsultantChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: PostConsultantChatMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.message = message.message || "";
    return obj;
  },
};

export const PostConsultantChatMessageResponse = {
  encode(message: PostConsultantChatMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostConsultantChatMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConsultantChatMessageResponse) as PostConsultantChatMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConsultantChatMessageResponse {
    const message = Object.create(basePostConsultantChatMessageResponse) as PostConsultantChatMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostConsultantChatMessageResponse>): PostConsultantChatMessageResponse {
    const message = Object.create(basePostConsultantChatMessageResponse) as PostConsultantChatMessageResponse;
    return message;
  },
  toJSON(message: PostConsultantChatMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PostConsultantChatFileRequest = {
  encode(message: PostConsultantChatFileRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.data);
    writer.uint32(26).string(message.fileName);
    return writer;
  },
  decode(reader: Reader, length?: number): PostConsultantChatFileRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConsultantChatFileRequest) as PostConsultantChatFileRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.data = reader.string();
          break;
        case 3:
          message.fileName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConsultantChatFileRequest {
    const message = Object.create(basePostConsultantChatFileRequest) as PostConsultantChatFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.data) {
      message.data = String(object.data);
    }
    if (object.fileName) {
      message.fileName = String(object.fileName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostConsultantChatFileRequest>): PostConsultantChatFileRequest {
    const message = Object.create(basePostConsultantChatFileRequest) as PostConsultantChatFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.data) {
      message.data = object.data;
    }
    if (object.fileName) {
      message.fileName = object.fileName;
    }
    return message;
  },
  toJSON(message: PostConsultantChatFileRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.data = message.data || "";
    obj.fileName = message.fileName || "";
    return obj;
  },
};

export const PostConsultantChatFileResponse = {
  encode(message: PostConsultantChatFileResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostConsultantChatFileResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConsultantChatFileResponse) as PostConsultantChatFileResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConsultantChatFileResponse {
    const message = Object.create(basePostConsultantChatFileResponse) as PostConsultantChatFileResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostConsultantChatFileResponse>): PostConsultantChatFileResponse {
    const message = Object.create(basePostConsultantChatFileResponse) as PostConsultantChatFileResponse;
    return message;
  },
  toJSON(message: PostConsultantChatFileResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const DeleteConsultantChatMessageRequest = {
  encode(message: DeleteConsultantChatMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.messageId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteConsultantChatMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteConsultantChatMessageRequest) as DeleteConsultantChatMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteConsultantChatMessageRequest {
    const message = Object.create(baseDeleteConsultantChatMessageRequest) as DeleteConsultantChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteConsultantChatMessageRequest>): DeleteConsultantChatMessageRequest {
    const message = Object.create(baseDeleteConsultantChatMessageRequest) as DeleteConsultantChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    return message;
  },
  toJSON(message: DeleteConsultantChatMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.messageId = message.messageId || 0;
    return obj;
  },
};

export const DeleteConsultantChatMessageResponse = {
  encode(message: DeleteConsultantChatMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteConsultantChatMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteConsultantChatMessageResponse) as DeleteConsultantChatMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteConsultantChatMessageResponse {
    const message = Object.create(baseDeleteConsultantChatMessageResponse) as DeleteConsultantChatMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<DeleteConsultantChatMessageResponse>): DeleteConsultantChatMessageResponse {
    const message = Object.create(baseDeleteConsultantChatMessageResponse) as DeleteConsultantChatMessageResponse;
    return message;
  },
  toJSON(message: DeleteConsultantChatMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const FlushConsultantChatUnreadCountRequest = {
  encode(message: FlushConsultantChatUnreadCountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): FlushConsultantChatUnreadCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushConsultantChatUnreadCountRequest) as FlushConsultantChatUnreadCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushConsultantChatUnreadCountRequest {
    const message = Object.create(baseFlushConsultantChatUnreadCountRequest) as FlushConsultantChatUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<FlushConsultantChatUnreadCountRequest>): FlushConsultantChatUnreadCountRequest {
    const message = Object.create(baseFlushConsultantChatUnreadCountRequest) as FlushConsultantChatUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: FlushConsultantChatUnreadCountRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const FlushConsultantChatUnreadCountResponse = {
  encode(message: FlushConsultantChatUnreadCountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): FlushConsultantChatUnreadCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushConsultantChatUnreadCountResponse) as FlushConsultantChatUnreadCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushConsultantChatUnreadCountResponse {
    const message = Object.create(baseFlushConsultantChatUnreadCountResponse) as FlushConsultantChatUnreadCountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<FlushConsultantChatUnreadCountResponse>): FlushConsultantChatUnreadCountResponse {
    const message = Object.create(baseFlushConsultantChatUnreadCountResponse) as FlushConsultantChatUnreadCountResponse;
    return message;
  },
  toJSON(message: FlushConsultantChatUnreadCountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutConsultantChatRoomStaffNameRequest = {
  encode(message: PutConsultantChatRoomStaffNameRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.staffName);
    return writer;
  },
  decode(reader: Reader, length?: number): PutConsultantChatRoomStaffNameRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutConsultantChatRoomStaffNameRequest) as PutConsultantChatRoomStaffNameRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.staffName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutConsultantChatRoomStaffNameRequest {
    const message = Object.create(basePutConsultantChatRoomStaffNameRequest) as PutConsultantChatRoomStaffNameRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.staffName) {
      message.staffName = String(object.staffName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutConsultantChatRoomStaffNameRequest>): PutConsultantChatRoomStaffNameRequest {
    const message = Object.create(basePutConsultantChatRoomStaffNameRequest) as PutConsultantChatRoomStaffNameRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.staffName) {
      message.staffName = object.staffName;
    }
    return message;
  },
  toJSON(message: PutConsultantChatRoomStaffNameRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.staffName = message.staffName || "";
    return obj;
  },
};

export const PutConsultantChatRoomStaffNameResponse = {
  encode(message: PutConsultantChatRoomStaffNameResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutConsultantChatRoomStaffNameResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutConsultantChatRoomStaffNameResponse) as PutConsultantChatRoomStaffNameResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutConsultantChatRoomStaffNameResponse {
    const message = Object.create(basePutConsultantChatRoomStaffNameResponse) as PutConsultantChatRoomStaffNameResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutConsultantChatRoomStaffNameResponse>): PutConsultantChatRoomStaffNameResponse {
    const message = Object.create(basePutConsultantChatRoomStaffNameResponse) as PutConsultantChatRoomStaffNameResponse;
    return message;
  },
  toJSON(message: PutConsultantChatRoomStaffNameResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutConsultantChatRoomHandledRequest = {
  encode(message: PutConsultantChatRoomHandledRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).bool(message.handled);
    return writer;
  },
  decode(reader: Reader, length?: number): PutConsultantChatRoomHandledRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutConsultantChatRoomHandledRequest) as PutConsultantChatRoomHandledRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.handled = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutConsultantChatRoomHandledRequest {
    const message = Object.create(basePutConsultantChatRoomHandledRequest) as PutConsultantChatRoomHandledRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.handled) {
      message.handled = Boolean(object.handled);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutConsultantChatRoomHandledRequest>): PutConsultantChatRoomHandledRequest {
    const message = Object.create(basePutConsultantChatRoomHandledRequest) as PutConsultantChatRoomHandledRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.handled) {
      message.handled = object.handled;
    }
    return message;
  },
  toJSON(message: PutConsultantChatRoomHandledRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.handled = message.handled || false;
    return obj;
  },
};

export const PutConsultantChatRoomHandledResponse = {
  encode(message: PutConsultantChatRoomHandledResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutConsultantChatRoomHandledResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutConsultantChatRoomHandledResponse) as PutConsultantChatRoomHandledResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutConsultantChatRoomHandledResponse {
    const message = Object.create(basePutConsultantChatRoomHandledResponse) as PutConsultantChatRoomHandledResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutConsultantChatRoomHandledResponse>): PutConsultantChatRoomHandledResponse {
    const message = Object.create(basePutConsultantChatRoomHandledResponse) as PutConsultantChatRoomHandledResponse;
    return message;
  },
  toJSON(message: PutConsultantChatRoomHandledResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetScoutCompaniesRequest = {
  encode(message: GetScoutCompaniesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    writer.uint32(18).string(message.q);
    if (message.scoutContractStatusFilterId !== undefined && message.scoutContractStatusFilterId !== undefined) {
      StringValue.encode({ value: message.scoutContractStatusFilterId! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutCompaniesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutCompaniesRequest) as GetScoutCompaniesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 2:
          message.q = reader.string();
          break;
        case 3:
          message.scoutContractStatusFilterId = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutCompaniesRequest {
    const message = Object.create(baseGetScoutCompaniesRequest) as GetScoutCompaniesRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.scoutContractStatusFilterId) {
      message.scoutContractStatusFilterId = String(object.scoutContractStatusFilterId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutCompaniesRequest>): GetScoutCompaniesRequest {
    const message = Object.create(baseGetScoutCompaniesRequest) as GetScoutCompaniesRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.scoutContractStatusFilterId) {
      message.scoutContractStatusFilterId = object.scoutContractStatusFilterId;
    }
    return message;
  },
  toJSON(message: GetScoutCompaniesRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    obj.scoutContractStatusFilterId = message.scoutContractStatusFilterId || undefined;
    return obj;
  },
};

export const GetScoutCompaniesResponse = {
  encode(message: GetScoutCompaniesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.scoutCompanies) {
      ScoutCompany.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutCompaniesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutCompaniesResponse) as GetScoutCompaniesResponse;
    message.scoutCompanies = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutCompanies.push(ScoutCompany.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutCompaniesResponse {
    const message = Object.create(baseGetScoutCompaniesResponse) as GetScoutCompaniesResponse;
    message.scoutCompanies = [];
    if (object.scoutCompanies) {
      for (const e of object.scoutCompanies) {
        message.scoutCompanies.push(ScoutCompany.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutCompaniesResponse>): GetScoutCompaniesResponse {
    const message = Object.create(baseGetScoutCompaniesResponse) as GetScoutCompaniesResponse;
    message.scoutCompanies = [];
    if (object.scoutCompanies) {
      for (const e of object.scoutCompanies) {
        message.scoutCompanies.push(ScoutCompany.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetScoutCompaniesResponse): unknown {
    const obj: any = {};
    if (message.scoutCompanies) {
      obj.scoutCompanies = message.scoutCompanies.map(e => e ? ScoutCompany.toJSON(e) : undefined);
    } else {
      obj.scoutCompanies = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetScoutCompanyRequest = {
  encode(message: GetScoutCompanyRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutCompanyRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutCompanyRequest) as GetScoutCompanyRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutCompanyRequest {
    const message = Object.create(baseGetScoutCompanyRequest) as GetScoutCompanyRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutCompanyRequest>): GetScoutCompanyRequest {
    const message = Object.create(baseGetScoutCompanyRequest) as GetScoutCompanyRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: GetScoutCompanyRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const GetScoutCompanyResponse = {
  encode(message: GetScoutCompanyResponse, writer: Writer = Writer.create()): Writer {
    if (message.form !== undefined && message.form !== undefined) {
      GetScoutCompanyResponse_Form.encode(message.form, writer.uint32(10).fork()).ldelim();
    }
    if (message.scoutCompany !== undefined && message.scoutCompany !== undefined) {
      ScoutCompany.encode(message.scoutCompany, writer.uint32(18).fork()).ldelim();
    }
    if (message.scoutCompanyBillingAddress !== undefined && message.scoutCompanyBillingAddress !== undefined) {
      ScoutCompanyBillingAddress.encode(message.scoutCompanyBillingAddress, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutCompanyResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutCompanyResponse) as GetScoutCompanyResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.form = GetScoutCompanyResponse_Form.decode(reader, reader.uint32());
          break;
        case 2:
          message.scoutCompany = ScoutCompany.decode(reader, reader.uint32());
          break;
        case 3:
          message.scoutCompanyBillingAddress = ScoutCompanyBillingAddress.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutCompanyResponse {
    const message = Object.create(baseGetScoutCompanyResponse) as GetScoutCompanyResponse;
    if (object.form) {
      message.form = GetScoutCompanyResponse_Form.fromJSON(object.form);
    }
    if (object.scoutCompany) {
      message.scoutCompany = ScoutCompany.fromJSON(object.scoutCompany);
    }
    if (object.scoutCompanyBillingAddress) {
      message.scoutCompanyBillingAddress = ScoutCompanyBillingAddress.fromJSON(object.scoutCompanyBillingAddress);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutCompanyResponse>): GetScoutCompanyResponse {
    const message = Object.create(baseGetScoutCompanyResponse) as GetScoutCompanyResponse;
    if (object.form) {
      message.form = GetScoutCompanyResponse_Form.fromPartial(object.form);
    }
    if (object.scoutCompany) {
      message.scoutCompany = ScoutCompany.fromPartial(object.scoutCompany);
    }
    if (object.scoutCompanyBillingAddress) {
      message.scoutCompanyBillingAddress = ScoutCompanyBillingAddress.fromPartial(object.scoutCompanyBillingAddress);
    }
    return message;
  },
  toJSON(message: GetScoutCompanyResponse): unknown {
    const obj: any = {};
    obj.form = message.form ? GetScoutCompanyResponse_Form.toJSON(message.form) : undefined;
    obj.scoutCompany = message.scoutCompany ? ScoutCompany.toJSON(message.scoutCompany) : undefined;
    obj.scoutCompanyBillingAddress = message.scoutCompanyBillingAddress ? ScoutCompanyBillingAddress.toJSON(message.scoutCompanyBillingAddress) : undefined;
    return obj;
  },
};

export const GetScoutCompanyResponse_Form = {
  encode(message: GetScoutCompanyResponse_Form, writer: Writer = Writer.create()): Writer {
    for (const v of message.scoutContractStatuses) {
      ScoutContractStatus.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutCompanyResponse_Form {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutCompanyResponse_Form) as GetScoutCompanyResponse_Form;
    message.scoutContractStatuses = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutContractStatuses.push(ScoutContractStatus.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutCompanyResponse_Form {
    const message = Object.create(baseGetScoutCompanyResponse_Form) as GetScoutCompanyResponse_Form;
    message.scoutContractStatuses = [];
    if (object.scoutContractStatuses) {
      for (const e of object.scoutContractStatuses) {
        message.scoutContractStatuses.push(ScoutContractStatus.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutCompanyResponse_Form>): GetScoutCompanyResponse_Form {
    const message = Object.create(baseGetScoutCompanyResponse_Form) as GetScoutCompanyResponse_Form;
    message.scoutContractStatuses = [];
    if (object.scoutContractStatuses) {
      for (const e of object.scoutContractStatuses) {
        message.scoutContractStatuses.push(ScoutContractStatus.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetScoutCompanyResponse_Form): unknown {
    const obj: any = {};
    if (message.scoutContractStatuses) {
      obj.scoutContractStatuses = message.scoutContractStatuses.map(e => e ? ScoutContractStatus.toJSON(e) : undefined);
    } else {
      obj.scoutContractStatuses = [];
    }
    return obj;
  },
};

export const GetScoutingHumanResourcesRequest = {
  encode(message: GetScoutingHumanResourcesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    if (message.scoutBillingStatus !== undefined && message.scoutBillingStatus !== undefined) {
      Int32Value.encode({ value: message.scoutBillingStatus! }, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).uint32(message.limit);
    writer.uint32(32).uint32(message.offset);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.scoutBillingStatus = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.limit = reader.uint32();
          break;
        case 4:
          message.offset = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesRequest {
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = Number(object.scoutBillingStatus);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.offset) {
      message.offset = Number(object.offset);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesRequest>): GetScoutingHumanResourcesRequest {
    const message = Object.create(baseGetScoutingHumanResourcesRequest) as GetScoutingHumanResourcesRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = object.scoutBillingStatus;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.offset) {
      message.offset = object.offset;
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.scoutBillingStatus = message.scoutBillingStatus || undefined;
    obj.limit = message.limit || 0;
    obj.offset = message.offset || 0;
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse = {
  encode(message: GetScoutingHumanResourcesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.scoutingHumanResources) {
      GetScoutingHumanResourcesResponse_ScoutingHumanResource.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(16).uint64(message.total);
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.decode(reader, reader.uint32()));
          break;
        case 2:
          message.total = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse {
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    if (object.scoutingHumanResources) {
      for (const e of object.scoutingHumanResources) {
        message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.fromJSON(e));
      }
    }
    if (object.total) {
      message.total = Number(object.total);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse>): GetScoutingHumanResourcesResponse {
    const message = Object.create(baseGetScoutingHumanResourcesResponse) as GetScoutingHumanResourcesResponse;
    message.scoutingHumanResources = [];
    if (object.scoutingHumanResources) {
      for (const e of object.scoutingHumanResources) {
        message.scoutingHumanResources.push(GetScoutingHumanResourcesResponse_ScoutingHumanResource.fromPartial(e));
      }
    }
    if (object.total) {
      message.total = object.total;
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse): unknown {
    const obj: any = {};
    if (message.scoutingHumanResources) {
      obj.scoutingHumanResources = message.scoutingHumanResources.map(e => e ? GetScoutingHumanResourcesResponse_ScoutingHumanResource.toJSON(e) : undefined);
    } else {
      obj.scoutingHumanResources = [];
    }
    obj.total = message.total || 0;
    return obj;
  },
};

export const GetScoutingHumanResourcesResponse_ScoutingHumanResource = {
  encode(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.scoutId);
    writer.uint32(18).string(message.lastName);
    writer.uint32(26).string(message.firstName);
    writer.uint32(32).int64(message.joinCompanyScheduleAt);
    if (message.scoutBillingStatus !== undefined && message.scoutBillingStatus !== undefined) {
      ScoutBillingStatus.encode(message.scoutBillingStatus, writer.uint32(42).fork()).ldelim();
    }
    if (message.selectionStep !== undefined && message.selectionStep !== undefined) {
      SelectionStep.encode(message.selectionStep, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutId = reader.string();
          break;
        case 2:
          message.lastName = reader.string();
          break;
        case 3:
          message.firstName = reader.string();
          break;
        case 4:
          message.joinCompanyScheduleAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.scoutBillingStatus = ScoutBillingStatus.decode(reader, reader.uint32());
          break;
        case 6:
          message.selectionStep = SelectionStep.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    if (object.scoutId) {
      message.scoutId = String(object.scoutId);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = Number(object.joinCompanyScheduleAt);
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = ScoutBillingStatus.fromJSON(object.scoutBillingStatus);
    }
    if (object.selectionStep) {
      message.selectionStep = SelectionStep.fromJSON(object.selectionStep);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetScoutingHumanResourcesResponse_ScoutingHumanResource>): GetScoutingHumanResourcesResponse_ScoutingHumanResource {
    const message = Object.create(baseGetScoutingHumanResourcesResponse_ScoutingHumanResource) as GetScoutingHumanResourcesResponse_ScoutingHumanResource;
    if (object.scoutId) {
      message.scoutId = object.scoutId;
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = object.joinCompanyScheduleAt;
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = ScoutBillingStatus.fromPartial(object.scoutBillingStatus);
    }
    if (object.selectionStep) {
      message.selectionStep = SelectionStep.fromPartial(object.selectionStep);
    }
    return message;
  },
  toJSON(message: GetScoutingHumanResourcesResponse_ScoutingHumanResource): unknown {
    const obj: any = {};
    obj.scoutId = message.scoutId || "";
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.joinCompanyScheduleAt = message.joinCompanyScheduleAt || 0;
    obj.scoutBillingStatus = message.scoutBillingStatus ? ScoutBillingStatus.toJSON(message.scoutBillingStatus) : undefined;
    obj.selectionStep = message.selectionStep ? SelectionStep.toJSON(message.selectionStep) : undefined;
    return obj;
  },
};

export const PutScoutContractStatusRequest = {
  encode(message: PutScoutContractStatusRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(16).uint32(message.scoutContractStatus);
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutContractStatusRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutContractStatusRequest) as PutScoutContractStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.scoutContractStatus = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutContractStatusRequest {
    const message = Object.create(basePutScoutContractStatusRequest) as PutScoutContractStatusRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.scoutContractStatus) {
      message.scoutContractStatus = Number(object.scoutContractStatus);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutContractStatusRequest>): PutScoutContractStatusRequest {
    const message = Object.create(basePutScoutContractStatusRequest) as PutScoutContractStatusRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.scoutContractStatus) {
      message.scoutContractStatus = object.scoutContractStatus;
    }
    return message;
  },
  toJSON(message: PutScoutContractStatusRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.scoutContractStatus = message.scoutContractStatus || 0;
    return obj;
  },
};

export const PutScoutContractStatusResponse = {
  encode(message: PutScoutContractStatusResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutContractStatusResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutContractStatusResponse) as PutScoutContractStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutContractStatusResponse {
    const message = Object.create(basePutScoutContractStatusResponse) as PutScoutContractStatusResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutContractStatusResponse>): PutScoutContractStatusResponse {
    const message = Object.create(basePutScoutContractStatusResponse) as PutScoutContractStatusResponse;
    return message;
  },
  toJSON(message: PutScoutContractStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutScoutCompanyStaffNameRequest = {
  encode(message: PutScoutCompanyStaffNameRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.staffName);
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutCompanyStaffNameRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutCompanyStaffNameRequest) as PutScoutCompanyStaffNameRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.staffName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutCompanyStaffNameRequest {
    const message = Object.create(basePutScoutCompanyStaffNameRequest) as PutScoutCompanyStaffNameRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.staffName) {
      message.staffName = String(object.staffName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutCompanyStaffNameRequest>): PutScoutCompanyStaffNameRequest {
    const message = Object.create(basePutScoutCompanyStaffNameRequest) as PutScoutCompanyStaffNameRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.staffName) {
      message.staffName = object.staffName;
    }
    return message;
  },
  toJSON(message: PutScoutCompanyStaffNameRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.staffName = message.staffName || "";
    return obj;
  },
};

export const PutScoutCompanyStaffNameResponse = {
  encode(message: PutScoutCompanyStaffNameResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutCompanyStaffNameResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutCompanyStaffNameResponse) as PutScoutCompanyStaffNameResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutCompanyStaffNameResponse {
    const message = Object.create(basePutScoutCompanyStaffNameResponse) as PutScoutCompanyStaffNameResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutCompanyStaffNameResponse>): PutScoutCompanyStaffNameResponse {
    const message = Object.create(basePutScoutCompanyStaffNameResponse) as PutScoutCompanyStaffNameResponse;
    return message;
  },
  toJSON(message: PutScoutCompanyStaffNameResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PostScoutCompanyBillingAddressRequest = {
  encode(message: PostScoutCompanyBillingAddressRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.lastName);
    writer.uint32(26).string(message.firstName);
    writer.uint32(34).string(message.officialPosition);
    writer.uint32(42).string(message.email);
    writer.uint32(50).string(message.phoneNumber);
    writer.uint32(58).string(message.address);
    return writer;
  },
  decode(reader: Reader, length?: number): PostScoutCompanyBillingAddressRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostScoutCompanyBillingAddressRequest) as PostScoutCompanyBillingAddressRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.lastName = reader.string();
          break;
        case 3:
          message.firstName = reader.string();
          break;
        case 4:
          message.officialPosition = reader.string();
          break;
        case 5:
          message.email = reader.string();
          break;
        case 6:
          message.phoneNumber = reader.string();
          break;
        case 7:
          message.address = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostScoutCompanyBillingAddressRequest {
    const message = Object.create(basePostScoutCompanyBillingAddressRequest) as PostScoutCompanyBillingAddressRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.officialPosition) {
      message.officialPosition = String(object.officialPosition);
    }
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.phoneNumber) {
      message.phoneNumber = String(object.phoneNumber);
    }
    if (object.address) {
      message.address = String(object.address);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostScoutCompanyBillingAddressRequest>): PostScoutCompanyBillingAddressRequest {
    const message = Object.create(basePostScoutCompanyBillingAddressRequest) as PostScoutCompanyBillingAddressRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.officialPosition) {
      message.officialPosition = object.officialPosition;
    }
    if (object.email) {
      message.email = object.email;
    }
    if (object.phoneNumber) {
      message.phoneNumber = object.phoneNumber;
    }
    if (object.address) {
      message.address = object.address;
    }
    return message;
  },
  toJSON(message: PostScoutCompanyBillingAddressRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.officialPosition = message.officialPosition || "";
    obj.email = message.email || "";
    obj.phoneNumber = message.phoneNumber || "";
    obj.address = message.address || "";
    return obj;
  },
};

export const PostScoutCompanyBillingAddressResponse = {
  encode(message: PostScoutCompanyBillingAddressResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostScoutCompanyBillingAddressResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostScoutCompanyBillingAddressResponse) as PostScoutCompanyBillingAddressResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostScoutCompanyBillingAddressResponse {
    const message = Object.create(basePostScoutCompanyBillingAddressResponse) as PostScoutCompanyBillingAddressResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostScoutCompanyBillingAddressResponse>): PostScoutCompanyBillingAddressResponse {
    const message = Object.create(basePostScoutCompanyBillingAddressResponse) as PostScoutCompanyBillingAddressResponse;
    return message;
  },
  toJSON(message: PostScoutCompanyBillingAddressResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutScoutBillingStatusesRequest = {
  encode(message: PutScoutBillingStatusesRequest, writer: Writer = Writer.create()): Writer {
    for (const v of message.scoutIds) {
      writer.uint32(10).string(v!);
    }
    writer.uint32(16).uint32(message.scoutBillingStatus);
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutBillingStatusesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutBillingStatusesRequest) as PutScoutBillingStatusesRequest;
    message.scoutIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scoutIds.push(reader.string());
          break;
        case 2:
          message.scoutBillingStatus = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutBillingStatusesRequest {
    const message = Object.create(basePutScoutBillingStatusesRequest) as PutScoutBillingStatusesRequest;
    message.scoutIds = [];
    if (object.scoutIds) {
      for (const e of object.scoutIds) {
        message.scoutIds.push(String(e));
      }
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = Number(object.scoutBillingStatus);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutBillingStatusesRequest>): PutScoutBillingStatusesRequest {
    const message = Object.create(basePutScoutBillingStatusesRequest) as PutScoutBillingStatusesRequest;
    message.scoutIds = [];
    if (object.scoutIds) {
      for (const e of object.scoutIds) {
        message.scoutIds.push(e);
      }
    }
    if (object.scoutBillingStatus) {
      message.scoutBillingStatus = object.scoutBillingStatus;
    }
    return message;
  },
  toJSON(message: PutScoutBillingStatusesRequest): unknown {
    const obj: any = {};
    if (message.scoutIds) {
      obj.scoutIds = message.scoutIds.map(e => e || "");
    } else {
      obj.scoutIds = [];
    }
    obj.scoutBillingStatus = message.scoutBillingStatus || 0;
    return obj;
  },
};

export const PutScoutBillingStatusesResponse = {
  encode(message: PutScoutBillingStatusesResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutScoutBillingStatusesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutScoutBillingStatusesResponse) as PutScoutBillingStatusesResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutScoutBillingStatusesResponse {
    const message = Object.create(basePutScoutBillingStatusesResponse) as PutScoutBillingStatusesResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutScoutBillingStatusesResponse>): PutScoutBillingStatusesResponse {
    const message = Object.create(basePutScoutBillingStatusesResponse) as PutScoutBillingStatusesResponse;
    return message;
  },
  toJSON(message: PutScoutBillingStatusesResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const DeleteInnovatorRequest = {
  encode(message: DeleteInnovatorRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteInnovatorRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteInnovatorRequest) as DeleteInnovatorRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteInnovatorRequest {
    const message = Object.create(baseDeleteInnovatorRequest) as DeleteInnovatorRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteInnovatorRequest>): DeleteInnovatorRequest {
    const message = Object.create(baseDeleteInnovatorRequest) as DeleteInnovatorRequest;
    if (object.email) {
      message.email = object.email;
    }
    return message;
  },
  toJSON(message: DeleteInnovatorRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    return obj;
  },
};

export const DeleteInnovatorResponse = {
  encode(message: DeleteInnovatorResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.innovatorId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteInnovatorResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteInnovatorResponse) as DeleteInnovatorResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.innovatorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteInnovatorResponse {
    const message = Object.create(baseDeleteInnovatorResponse) as DeleteInnovatorResponse;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteInnovatorResponse>): DeleteInnovatorResponse {
    const message = Object.create(baseDeleteInnovatorResponse) as DeleteInnovatorResponse;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    return message;
  },
  toJSON(message: DeleteInnovatorResponse): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.innovatorId = message.innovatorId || "";
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};