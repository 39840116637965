import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { Tag } from 'components/atoms';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import useModalAlert from 'hooks/useModalAlert';
import { formatToKiloBytes } from 'utils/file';
import {
  formatBirth,
  formatAcademicBackground,
  formatAddress,
  formatCertifications,
  formatName,
  formatPhoneNumber,
  formatPreferredOccupations,
  formatWorkLocations,
} from 'utils/models/humanResourceMatter';
import { JobHistories, ModalAlert } from 'components/molecules';
import HumanResourceMatterProfileItem from './HumanResourceMatterProfileItem';
import useResumeFile from './useResumeFile';

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
`;

const Outputs = styled.ul``;

const Output = styled.li`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelfIntroduction = styled.p`
  word-break: break-all;
`;

const ResumeFile = styled.a`
  text-decoration: underline;

  &:hover {
    color: ${theme.link};
  }
`;

type HumanResourceMatterProfileProps = {
  matter: HumanResourceMatterForConsultant;
};

const HumanResourceMatterProfileCareer: FC<HumanResourceMatterProfileProps> = ({
  matter: { profile, innovatorEmail },
}) => {
  const { modalAlertState, openModalAlert, closeModalAlert } = useModalAlert();
  const { handleDownloadFile } = useResumeFile({ openModalAlert });

  return (
    <>
      <Profile>
        <HumanResourceMatterProfileItem
          label={'氏名'}
          value={formatName(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'Email'}
          value={innovatorEmail}
        />
        <HumanResourceMatterProfileItem
          label={'生年月'}
          value={formatBirth(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'携帯電話番号'}
          value={formatPhoneNumber(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'最終学歴'}
          value={formatAcademicBackground(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'現住所'}
          value={formatAddress(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'希望勤務地'}
          value={formatWorkLocations(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'直近の年収'}
          value={profile?.career?.annualIncome?.name}
        />
        <HumanResourceMatterProfileItem
          label={'直近の月額単価'}
          value={
            profile?.career?.monthlyUnitPrice
              ? `${profile?.career?.monthlyUnitPrice}万円`
              : '-'
          }
        />
        <HumanResourceMatterProfileItem
          label={'希望月額単価'}
          value={profile?.career?.preferredMonthlyUnitPrice?.name}
        />
        <HumanResourceMatterProfileItem
          label={'経験企業数'}
          value={profile?.career?.numberOfJobChanges?.name}
        />
        <HumanResourceMatterProfileItem
          label={'勤務先 / 就業期間 /\n役職 / 経験職種'}
          value={profile?.career?.job}
          renderItem={job => <JobHistories job={job} />}
        />
        <HumanResourceMatterProfileItem
          label={'希望職種'}
          value={formatPreferredOccupations(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'経験スキル'}
          value={profile?.career?.skills}
          renderItem={skills => (
            <>
              {skills.map(s => (
                <Tag key={s.id}>{s.name}</Tag>
              ))}
            </>
          )}
        />
        <HumanResourceMatterProfileItem
          label={'希望雇用形態'}
          value={profile?.career?.preferredEmploymentType?.name}
        />

        <HumanResourceMatterProfileItem
          label={'英語力'}
          value={profile?.englishSkill?.name}
        />
        <HumanResourceMatterProfileItem
          label={'保有資格'}
          value={formatCertifications(profile)}
        />
        <HumanResourceMatterProfileItem
          label={'表彰・実績'}
          value={profile?.career?.achievement}
        />
        {profile?.innovatorType === 'engineer' && (
          <HumanResourceMatterProfileItem
            label={'アウトプット'}
            value={profile?.outputs}
            style={{ overflow: 'hidden' }}
            renderItem={outputs => (
              <Outputs>
                {outputs.map((output, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Output key={index}>
                    <a href={output} target="_blank" rel="noopener noreferrer">
                      {output}
                    </a>
                  </Output>
                ))}
              </Outputs>
            )}
          />
        )}

        <HumanResourceMatterProfileItem
          label={'希望事項'}
          value={profile?.career?.expectations}
          renderItem={expectations => (
            <>
              {expectations.map(e => (
                <Tag key={e.id}>{e.name}</Tag>
              ))}
            </>
          )}
        />
        <HumanResourceMatterProfileItem
          label={'職務経歴書'}
          value={profile?.career?.resumeFile}
          renderItem={resumeFile => (
            <ResumeFile
              href={resumeFile.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={event => {
                handleDownloadFile(resumeFile);
                event.preventDefault();
              }}
            >
              {resumeFile.name}({formatToKiloBytes(resumeFile.size)})
            </ResumeFile>
          )}
        />
        <HumanResourceMatterProfileItem
          label={'自己PR'}
          value={profile?.selfIntroduction}
          renderItem={selfIntroduction => (
            <SelfIntroduction>{selfIntroduction}</SelfIntroduction>
          )}
        />
      </Profile>
      <ModalAlert
        show={modalAlertState.show}
        onClickOk={closeModalAlert}
        text={modalAlertState.text}
      />
    </>
  );
};

export default HumanResourceMatterProfileCareer;
