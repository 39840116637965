import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { HumanResourceMatterForConsultant } from 'proto/v1/consultantservice/consultantservice';
import { ValueOf } from 'types/common';
import { EMPLOYMENT_TYPE_ID, EMPLOYMENT_TYPE_TAG_TYPE } from 'constants/matter';

const getEmploymentTagType = (
  matter: HumanResourceMatterForConsultant | undefined,
): ValueOf<typeof EMPLOYMENT_TYPE_TAG_TYPE> | undefined => {
  const profile = matter?.profile;

  if (profile?.newGraduate) return EMPLOYMENT_TYPE_TAG_TYPE.NEW_GRADUATE;

  const preferredEmploymentTypeId =
    profile?.career?.preferredEmploymentType?.id;

  switch (preferredEmploymentTypeId) {
    case EMPLOYMENT_TYPE_ID.FULL_TIME:
      return EMPLOYMENT_TYPE_TAG_TYPE.CAREER;
    case EMPLOYMENT_TYPE_ID.OUT_SOURCING:
      return EMPLOYMENT_TYPE_TAG_TYPE.OUT_SOURCING;
    case EMPLOYMENT_TYPE_ID.ADVISOR:
      return EMPLOYMENT_TYPE_TAG_TYPE.ADVISOR;
    default:
      return undefined;
  }
};

const EMPLOYMENT_TYPE_TAG_COLOR = {
  [EMPLOYMENT_TYPE_TAG_TYPE.NEW_GRADUATE]: '#17B3DA',
  [EMPLOYMENT_TYPE_TAG_TYPE.CAREER]: '#5D82E1',
  [EMPLOYMENT_TYPE_TAG_TYPE.OUT_SOURCING]: '#E9900B',
  [EMPLOYMENT_TYPE_TAG_TYPE.ADVISOR]: '#F55660',
};

const EMPLOYMENT_TYPE_TAG_TEXT = {
  [EMPLOYMENT_TYPE_TAG_TYPE.NEW_GRADUATE]: '新卒採用',
  [EMPLOYMENT_TYPE_TAG_TYPE.CAREER]: '中途採用',
  [EMPLOYMENT_TYPE_TAG_TYPE.OUT_SOURCING]: '業務委託',
  [EMPLOYMENT_TYPE_TAG_TYPE.ADVISOR]: '顧問・アドバイザー',
};

const Tag = styled.div<{
  type: ValueOf<typeof EMPLOYMENT_TYPE_TAG_TYPE>;
}>`
  box-sizing: border-box;
  padding: 2px 10px 1px 10px;
  border: 1px solid ${theme.border};
  border-radius: 5px;
  background-color: ${theme.white};
  color: ${({ type }) => EMPLOYMENT_TYPE_TAG_COLOR[type]};
  line-height: 1.5;
`;

type EmploymentTypeTagProps = {
  matter: HumanResourceMatterForConsultant | undefined;
} & HTMLAttributes<HTMLDivElement>;

const EmploymentTypeTag: FC<EmploymentTypeTagProps> = ({ matter, ...rest }) => {
  const employmentTagType = getEmploymentTagType(matter);

  if (!employmentTagType) return null;

  return (
    <Tag type={employmentTagType} {...rest}>
      {EMPLOYMENT_TYPE_TAG_TEXT[employmentTagType]}
    </Tag>
  );
};

export default EmploymentTypeTag;
